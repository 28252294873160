import React, { useEffect } from "react";
import { ButMain, CardAuth, Col, InputsPage, Row } from "../../components";
import { Link } from "react-router-dom";
import { useLogin } from "../../contexts/useLogin";
import { content } from "../../contexts/useLang";

const Create = () => {
  const { formData, handleChange, handleSubmit, loadingPost } = useLogin(
    { name: "", phone: "", email: "", password: "", password_confirmation: "" },
    {
      name: ["required"],
      phone: ["required"],
      email: ["required", "email"],
      password: ["required", "min:8", "equal:password_confirmation"],
      password_confirmation: ["required", "min:8", "equal:password"],
    }
  );
  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     fcm_tokens: "",
  //   });
  // }, []);
  useEffect(() => {
    sessionStorage.setItem("email", formData?.email);
  }, [formData]);
  return (
    <CardAuth title={content["Create Customer Account"]}>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-Main">
          {content["First Step: Provide your basic info"]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/2 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/2 h-[5px] bg-Third/30 rounded-full"></div>
        </Col>

        {[
          {
            xs: 11,
            title: content["Enter your full name"],
            onChange: handleChange,
            inputType: "input",
            name: "name",
            type: "text",
          },
          {
            xs: 11,
            title: content["Enter your mobile number"],
            onChange: handleChange,
            inputType: "input",
            name: "phone",
            type: "text",
          },
          {
            xs: 11,
            title: content["Enter your email address"],
            onChange: handleChange,
            inputType: "input",
            name: "email",
            type: "text",
          },
          {
            xs: 11,
            title: content["Enter your password"],
            onChange: handleChange,
            inputType: "password",
            name: "password",
            type: "password",
          },
          {
            xs: 11,
            title: content["Confirm your password"],
            onChange: handleChange,
            inputType: "password",
            name: "password_confirmation",
            type: "password",
          },
        ].map((e, i) => (
          <InputsPage key={i} data={e} />
        ))}

        <Col xs={11} className=" ">
          <ButMain
            onClick={() =>
              handleSubmit("sign-up", "route", "/auth/verification-code")
            }
            loadingPost={loadingPost}
            name={content["Next"]}
            className="text-center py-3 text-xl"
          />
        </Col>
        <Col xs={11} className="text-center">
          {content["Already have an ccount"]} ?{" "}
          <Link to="/auth/sign-up" className="text-Main">
            {content["Login"]}
          </Link>
        </Col>
      </Row>
    </CardAuth>
  );
};

export default Create;
