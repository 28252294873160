import React from "react";
import { useNavigate } from "react-router-dom";
import { content } from "../../contexts/useLang";

const ButMain = ({ name, link, onClick, className, loadingPost }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (!loadingPost) {
          if (link) {
            navigate(link);
          } else if (onClick) {
            onClick();
          }
        }
      }}
      className={
        "rounded-md text-center bg-Main text-Light hover:bg-Main/90 font-semibold cursor-pointer py-1 px-7 select-none " +
        className
      }
    >
      {!loadingPost ? (
        name
      ) : (
        <div className="flex-center gap-2">
          <div className="spinner-border animate-spin inline-block w-5 h-5 border-4 border-solid border-primary rounded-full border-t-transparent"></div>
          <p className="font-semibold">{content["Loading..."]}</p>
        </div>
      )}
    </div>
  );
};
export default ButMain;
