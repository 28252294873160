import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl, language, token } from "../config";
import { useContextState } from "./ContextState";
import { useContextData } from "./ContextData";

export const useFetch = (url, clickFetch) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    setError,
    refreshData,
    setRefreshData,
    setOpenDelete,
    setMessages,
    setLoadingPost,
  } = useContextState();
  const { countryCode } = useContextData();

  const fetchData = async () => {
    axios
      .get(`${baseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          language: language,
          country: countryCode?.country_name.replace("The", ""),
        },
      })
      .then((response) => {
        if (response) {
          setLoading(false);
          setData(response.data.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status === 401) {
          localStorage.clear();
          window.location.reload(false);
        } else if (e.response.status === 403) {
          navigate("/403");
        } else if (e.response.status === 500) {
          navigate("/500");
        } else {
          for (const [key, value] of Object.entries(
            e?.response?.data?.errors || {}
          ))
            setError((preError) => [...preError, ...value]);
        }
      });
  };
  useEffect(() => {
    setLoading(true);
    if (url && countryCode?.country_name) {
      fetchData();
    }
  }, [url, countryCode?.country_name, refreshData]);

  const deleteItem = async (item, urlDelete) => {
    const deleteSh = async () => {
      setLoadingPost(true);
      try {
        await axios.delete(
          `${baseUrl}${
            urlDelete
              ? urlDelete
              : `${
                  url?.indexOf("?") > 0
                    ? url?.substring(0, url?.indexOf("?"))
                    : url
                }/${item}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              language: language,
            },
          }
        );
        setLoadingPost(false);
        setMessages(["Deleted successfully"]);
        setRefreshData(refreshData + 1);
        setOpenDelete([false]);
      } catch (e) {
        setLoadingPost(false);
        for (const [key, value] of Object.entries(
          e?.response?.data?.errors || {}
        ))
          setError((preError) => [...preError, ...value]);
      }
    };
    setOpenDelete([true, deleteSh]);
  };

  return { data, deleteItem, loading };
};
