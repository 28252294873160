import { useNavigate, useParams } from "react-router-dom";
import {
  FaShoppingCart,
  IoIosStar,
  RiUserFollowFill,
  TbTruckDelivery,
} from "../../assets/icons";
import {
  CardProducts,
  Col,
  Container,
  PageLoading,
  Pagination,
  Row,
  TitlePage,
} from "../../components";
import { storageUrl } from "../../config";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { usePost } from "../../contexts/usePost";
import { useEffect, useState } from "react";
import { RiUserUnfollowFill } from "react-icons/ri";
import { content } from "../../contexts/useLang";
const Seller = () => {
  const navigate = useNavigate();
  const [isFollowing, setIsFollowing] = useState();
  const { username } = useParams();
  const { handleSubmit, successPost } = usePost({}, {});

  const { handleParamsAdd, searchParams, handleParamsRemove, subSearch } =
    useFilter();

  const { data, loading } = useFetch("sellers/" + username);
  const { data: products, loading: loadingProducts } = useFetch(
    data?.id && "products?seller=" + data?.id + subSearch
  );
  useEffect(() => {
    if (successPost) {
      setIsFollowing(!isFollowing);
    }
  }, [successPost]);

  useEffect(() => {
    if (!successPost) {
      setIsFollowing(data?.is_following);
    }
  }, [data]);
  return (
    <div>
      {!loading ? (
        <>
          <Container>
            <TitlePage
              notPage={true}
              title={[content["Home"], content["Sellers"], data?.store_name]}
            />
          </Container>
          <div className="h-[50vh] w-full">
            <img
              src={storageUrl + data?.store_bg}
              alt=""
              className="h-full w-full object-cover"
            />
          </div>
          <div className="w-[110px] h-[110px] md:w-[150px] md:h-[150px]  overflow-hidden rounded-full -mt-[60px] md:-mt-[100px] mx-auto">
            <img src={storageUrl + data?.store_image} alt="" className="" />
          </div>
          <div className="text-center mt-5">
            <div className="text-xl font-semibold">{data?.store_name}</div>
            <div className="">{data?.name}</div>
            <div className="text-sm text-Third">{data?.description}</div>
            <hr className="w-1/4 mt-4 mx-auto" />
            <div>
              <ul className="flex-center gap-5 mt-3">
                <li
                  onClick={() =>
                    handleSubmit(
                      !isFollowing
                        ? "follow-seller?seller_id=" + data?.id
                        : "unfollow-seller?seller_id=" + data?.id,
                      "refresh"
                    )
                  }
                  className="flex-center gap-3 cursor-pointer"
                >
                  <div className="w-7 h-7  bg-green-500 rounded-full flex-center text-Light">
                    {isFollowing ? (
                      <RiUserUnfollowFill />
                    ) : (
                      <RiUserFollowFill className="" />
                    )}
                  </div>
                  <div>
                    {isFollowing ? content["Unfollow"] : content["Follow"]}
                  </div>
                </li>
                <li
                  onClick={() => navigate("/cart/" + data?.username)}
                  className="flex-center gap-3 cursor-pointer"
                >
                  <div className="w-7 h-7  bg-[#1A78F1] rounded-full flex-center text-Light">
                    <FaShoppingCart className="" />
                  </div>
                  <div>{content["Cart"]}</div>
                </li>
                <li className="flex-center gap-3">
                  <div className="w-7 h-7 bg-[#F1CC34] rounded-full flex-center text-Light">
                    <TbTruckDelivery />
                  </div>
                  <div>
                    {data?.delivery_time} {content["Days"]}{" "}
                  </div>
                </li>
                <li className="flex-center gap-3">
                  <div className="w-7 h-7 bg-[#FFA138] rounded-full flex-center text-Light">
                    <IoIosStar />
                  </div>
                  <div>
                    {data?.average_rating || "0"}{" "}
                    <span className="text-xs text-Third">
                      ({data?.reviews_count >= 25 ? "25+" : data?.reviews_count}
                      )
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <PageLoading />
      )}
      {!loadingProducts ? (
        <>
          <div className="bg-Third/30 py-3 mt-5">
            <ul className="overflow-y-hidden scroll overflow-x-scroll  flex items-center justify-around gap-6 px-5 text-lg">
              {[
                { name: content["All"], id: "" },
                ...(data?.sections || []),
              ].map((e) => (
                <li
                  className={`${
                    searchParams.get("section") === e.id
                      ? "text-Main font-semibold"
                      : "hover:text-Main hover:font-semibold"
                  } whitespace-nowrap`}
                  key={e.id}
                  onClick={() => {
                    if (e.id) {
                      handleParamsAdd("section", e.id);
                    } else {
                      handleParamsRemove("section");
                    }
                  }}
                >
                  {e.name}
                </li>
              ))}
            </ul>
          </div>
          <Container>
            <Row className="mt-5">
              {products?.data?.map((e, i) => (
                <Col key={i} md={20}>
                  <CardProducts key={e.id} data={e} />
                </Col>
              ))}
            </Row>
          </Container>
          <Pagination data={products} />
        </>
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default Seller;
