import { AiOutlineClose, AiOutlineCloudUpload } from "../../assets/icons";
import { Col, Row } from "../GridSystem";

const UploadFiles = ({ data }) => {
  return (
    <Col md={data.md || 5} xs={data.xs || 6}>
      <input
        id={data.id || "files"}
        type="file"
        name={data.name}
        multiple
        onChange={data.onChange}
        className="hidden"
        accept={"image/*"}
      />
      <div
        onClick={() => document.getElementById(data.id || "files").click()}
        className="flex-center px-2 bg-[#fff] m-1 rounded-md h-[150px] text-center"
      >
        <div>
          <AiOutlineCloudUpload size={40} className="text-Third/40 mx-auto" />
          <p className="text-Main">{data?.title}</p>
          <div>SVG, PNG, JPG or GIF (max. 800x400px)</div>
        </div>
      </div>
      <Row>
        {data?.viewFiles[data.name]?.map((e, i) => (
          <Col col={4} key={i} className="p-1 h-full">
            <div className="flex-center relative h-[200px]  max-w-[150px] py-5 bg-Light p-1 rounded-xl">
              <span
                onClick={() => {
                  let total = [...data.viewFiles[data.name]];
                  let total2 = [...data.files[data.name]];
                  total.splice(i, 1);
                  total2.splice(i, 1);
                  data.setViewFiles({
                    ...data.viewFiles,
                    [data.name]: total.length === 0 ? [] : total,
                  });
                  data.setFormData({
                    ...data.files,
                    [data.name]: total2.length === 0 ? [] : total2,
                  });
                }}
                className="bg-red-600 w-5 h-5 flex-center text-white cursor-pointer z-10 -top-2 -right-2 rounded-full absolute hover:opacity-80 transition-all"
              >
                <AiOutlineClose />
              </span>
              <img
                src={e}
                alt=""
                className="w-full max-h-[160px] object-contain rounded-2xl"
              />
            </div>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default UploadFiles;
