import Select from "react-select";
import { areEqual } from "../../contexts/functions";
import { useFetch } from "../../contexts/useFetch";
import { useTimeout } from "../../contexts/useTimeout";
import { Col } from "../GridSystem";

const MultiSelectApi = ({ data, value }) => {
  const { handelChangeTimeoutSelect, search } = useTimeout();
  const { data: dataApi, loading } = useFetch(
    data?.api
      ? data?.api +
          (search ? (data?.isParams ? "&search=" : "?search=") + search : "")
      : ""
  );
  return (
    <Col md={data.xs || data.md || 4} xs={data.xs || 6}>
      <div className="text-sm text-Third">{data?.title}</div>
      <Select
        value={
          value?.length
            ? dataApi
                ?.filter((item) => {
                  return value?.find((val) => areEqual(val, item.id));
                })
                .map((e) => {
                  return { value: e.id, label: e.name };
                })
            : null
        }
        isMulti
        menuPlacement="top"
        isLoading={loading}
        onInputChange={handelChangeTimeoutSelect}
        loadingMessage={() => "loading..."}
        className="mt-2"
        onChange={data.onChange}
        name={data.name}
        options={dataApi?.map((e) => {
          return { value: e.id, label: e.name };
        })}
      />
    </Col>
  );
};

export default MultiSelectApi;
