import { useState } from "react";
import { BiHide, BiShow } from "../../assets/icons";
import { content } from "../../contexts/useLang";
import { Col } from "../GridSystem";
import IconHover from "../IconHover/IconHover";

function PasswordInput({ data, value }) {
  const [biShow, setBiShow] = useState(true);
  const Show = biShow ? BiHide : BiShow;
  return (
    <Col
      lg={data.lg || data.md}
      md={data.xs || data.md || 4}
      xs={data.xs || 6}
      className="relative"
    >
      <input
        type={biShow ? "password" : "text"}
        name={data.name}
        value={value || data.value}
        readOnly={data.readOnly || false}
        onChange={data.onChange}
        placeholder={data.placeholder || data?.title}
        className={`${
          data.className ||
          " border bg-[#fff] text-black border-[#D2D2D2] m-1 py-[0.6rem] rounded-[4px]  focus:outline  !outline-Main !outline-[2px]"
        }`}
      />
      <div className="absolute w-fit right-3 top-1/2 -translate-y-1/2 text-gray-500 cursor-pointer">
        <IconHover
          noHover={true}
          id={biShow ? "hidePassword" : "showPassword"}
          content={biShow ? content.hidePassword : content.showPassword}
        >
          <Show onClick={() => setBiShow(!biShow)} size={22} />
        </IconHover>
      </div>
    </Col>
  );
}
export default PasswordInput;
