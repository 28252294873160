import React from "react";
import { IoMdClose } from "../../assets/icons";
import { noAccount } from "../../assets/images";
import { useContextState } from "../../contexts/ContextState";
import ButMain from "../Buttons/ButMain";
import { Col } from "../GridSystem";
import { content } from "../../contexts/useLang";

const PopUpNoAccount = () => {
  const { openPopUpNoAccount, setOpenPopUpNoAccount } = useContextState();

  return (
    <div>
      {openPopUpNoAccount ? (
        <>
          <div
            onClick={() => setOpenPopUpNoAccount(false)}
            className="w-full h-full bg-black/20 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40"
          />
          <Col
            md={4}
            sm={11}
            className="md:h-[84vh]  fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[999] text-center"
          >
            <div className="bg-Light rounded-xl p-3 border h-full">
              <div>
                <div onClick={() => setOpenPopUpNoAccount(false)}>
                  <IoMdClose />
                </div>
              </div>
              <Col md={11} className="mx-auto h-full">
                <div className="flex-center h-3/5">
                  <img src={noAccount} alt="" />
                </div>
                <div className="font-semibold text-xl">
                  {content["You don't have an account"]}
                </div>
                <div className="text-Third">
                  {
                    content[
                      "You cannot order a product without having an account please register to continue purchasing."
                    ]
                  }
                </div>
                <div className="flex-center gap-3 mt-16">
                  <ButMain
                    name={content["Back"]}
                    onClick={() => setOpenPopUpNoAccount(false)}
                    className="border bg-white hover:bg-slate-100 !text-Dark"
                  />
                  <ButMain
                    link="/auth/sign-up"
                    name={content["Sign up"]}
                    className=""
                  />
                </div>
              </Col>
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PopUpNoAccount;
