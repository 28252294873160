import axios from "axios";
import { useState } from "react";
import { baseUrl, token } from "../config";
import { useContextState } from "./ContextState";
import useValidate from "./useValidate";
import { getArrayLength } from "./functions";
import { useNavigate } from "react-router-dom";

export const useLogin = (initialState, validate) => {
  const [formData, setFormData] = useState(initialState);
  const [loadingPost, setLoadingPost] = useState(false);
  const { setError } = useContextState();
  const navigate = useNavigate();

  const { error } = useValidate(formData, validate);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (url, nameEvent, event) => {
    if (getArrayLength(error)) {
      return setError([...error]);
    }

    setLoadingPost(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData || {})) {
      formDataToSend.append(key, value);
    }
    try {
      const response = await axios.post(`${baseUrl}${url}`, formDataToSend);
      setLoadingPost(false);
      setError("");
      if (response?.data?.data?.token) {
        localStorage.setItem("token", response?.data?.data?.token);
      }

      switch (nameEvent) {
        case "route":
          return navigate(event);
        case "reload":
          return window.location.reload();
        default:
          return window.location.reload(false);
      }
    } catch (e) {
      for (const [key, value] of Object.entries(
        e?.response?.data?.errors || {}
      ))
        setError((preError) => [...preError, ...value]);
      setLoadingPost(false);
    }
  };

  return {
    formData,
    handleChange,
    loadingPost,
    error,
    handleSubmit,
    setFormData,
  };
};

export const logout = async (url) => {
  try {
    await axios.post(
      `${baseUrl}${url}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    localStorage.clear();
    window.location.reload(false);
  } catch (e) {}
};
