import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  ButMain,
  CheckBox,
  Col,
  Container,
  DeleteFiles,
  Input,
  MultiSelectApi,
  Row,
  Select,
  SelectApi,
  TextArea,
  UploadFiles,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";

const SellerEventProduct = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const {
    formData,
    viewFiles,
    setFormData,
    setViewFiles,
    handleSubmit,
    handleChangeFiles,
    handleChangeInput,
    handleChangeSelect,
    handleCheckBoxes,
    loadingPost,
  } = usePost(
    {
      section_id: "", //
      category_id: "", //
      name: "", //
      description: "", //
      price: "", //
      discount: "", //
      quantity: "", //
      delivery_time: "", //
      status: "", //
      is_available: 1, //
      "size[]": [],
      "color[]": [],
      "files[]": [],
    },
    {
      section_id: ["required"],
      category_id: ["required"],
      name: ["required"],
      description: [],
      price: ["required"],
      discount: [],
      quantity: ["required"],
      delivery_time: ["required"],
      status: ["required"],
      "size[]": [],
      "color[]": [],
      "files[]": [],
    }
  );

  const { data } = useFetch(!isAdd && "products/" + id);
  useEffect(() => {
    if (!isAdd) {
      setFormData({
        ...formData,
        section_id: data?.section_id,
        category_id: data?.category_id,
        name: data?.product?.name,
        description: data?.product?.description,
        price: data?.product?.price?.split(" ")?.[0],
        discount: data?.product?.discount?.split(" ")?.[0],
        quantity: data?.product?.quantity,
        delivery_time: data?.product?.delivery_time,
        status: data?.product?.status,
        is_available: data?.product?.is_available ? 1 : 0,
        "size[]": data?.size,
        "color[]": data?.color,
      });
    }
  }, [data]);

  return (
    <div>
      <Container>
        <div className=" text-3xl font-semibold my-4">
          {content["Products"]}
        </div>
        <Col md={11} className="mx-auto">
          <Row>
            <Col md={6}>
              <Row>
                <Input
                  value={formData?.name}
                  data={{
                    xs: 12,
                    title: content["Product Name"],
                    onChange: handleChangeInput,
                    name: "name",
                    type: "text",
                  }}
                />
                <Input
                  value={formData?.price}
                  data={{
                    xs: 6,
                    title: content["Price"],
                    onChange: handleChangeInput,
                    name: "price",
                    type: "number",
                  }}
                />
                <Input
                  value={formData?.discount}
                  data={{
                    xs: 6,
                    title: content["Price Before Discount (optional)"],
                    onChange: handleChangeInput,
                    name: "discount",
                    type: "number",
                  }}
                />
                <Input
                  value={formData?.quantity}
                  data={{
                    xs: 6,
                    title: content["Quantity"],
                    onChange: handleChangeInput,
                    name: "quantity",
                    type: "number",
                  }}
                />
                <Input
                  value={formData?.delivery_time}
                  data={{
                    xs: 6,
                    title: content["Delivery Time"],
                    onChange: handleChangeInput,
                    name: "delivery_time",
                    type: "number",
                  }}
                />
                <SelectApi
                  value={formData?.section_id}
                  data={{
                    xs: 6,
                    title: content["Sections"],
                    onChange: handleChangeSelect,
                    name: "section_id",
                    api: "sections",
                  }}
                />
                <SelectApi
                  value={formData?.category_id}
                  data={{
                    xs: 6,
                    title: content["Category"],
                    onChange: handleChangeSelect,
                    name: "category_id",
                    isParams: true,
                    api: formData?.section_id
                      ? "categories?section=" + formData?.section_id
                      : "",
                  }}
                />
                <Select
                  value={formData?.status}
                  data={{
                    xs: 6,
                    title: content["Status"],
                    onChange: handleChangeSelect,
                    name: "status",
                    options: [
                      { id: "publish", name: content["publish"] },
                      { id: "draft", name: content["Draft"] },
                    ],
                  }}
                />
                <CheckBox
                  value={formData?.is_available}
                  data={{
                    xs: 6,
                    title: content["Available"],
                    onChange: handleChangeInput,
                    name: "is_available",
                  }}
                />
                <MultiSelectApi
                  value={formData?.["color[]"]}
                  data={{
                    xs: 12,
                    title: content["Colors (optional)"],
                    onChange: handleChangeSelect,
                    name: "color[]",
                    api: "colors",
                  }}
                />
                <MultiSelectApi
                  value={formData?.["size[]"]}
                  data={{
                    xs: 12,
                    title: content["Sizes (optional)"],
                    onChange: handleChangeSelect,
                    name: "size[]",
                    api: "sizes",
                  }}
                />
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <TextArea
                  value={formData?.description}
                  data={{
                    xs: 12,
                    title: content["Description"],
                    onChange: handleChangeInput,
                    name: "description",
                    type: "text",
                  }}
                />
                <Col>
                  <div className="text-sm text-Third">
                    {content["Product Images (min 2)"]}
                  </div>
                </Col>
                <UploadFiles
                  data={{
                    md: 12,
                    name: "files[]",
                    title: content["Click to upload"],
                    onChange: handleChangeFiles,
                    setViewFiles: setViewFiles,
                    viewFiles: viewFiles,
                    setFormData: setFormData,
                    files: formData,
                  }}
                />
              </Row>
            </Col>
          </Row>
          <DeleteFiles
            show={!isAdd}
            data={data?.files}
            handleCheckBoxes={handleCheckBoxes}
            name="files_to_delete[]"
            keys="file"
          />
          <Row className="mt-20 mb-10 justify-end">
            <Col md={2} className=" ">
              <ButMain
                onClick={() => window.history.go(-1)}
                name={content["Back"]}
                className="bg-Red hover:bg-Red/80"
              />
            </Col>
            <Col md={4} className=" ">
              <ButMain
                onClick={() =>
                  handleSubmit(isAdd ? "products" : "products/" + id)
                }
                loadingPost={loadingPost}
                name={content["Save"]}
                className=" "
              />
            </Col>
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default SellerEventProduct;
