import React from "react";
import { FaCartPlus, FaEye } from "../../assets/icons";
import ButMain from "../Buttons/ButMain";
import CardStar from "./CardStar";
import { storageUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import { content } from "../../contexts/useLang";

const CardProducts = ({ data, isNotDiscount }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/products/" + data.id)}
      className="w-full rounded-xl overflow-hidden shadow relative"
    >
      {data.discount !== "0.00" &&
      !isNotDiscount &&
      data.discount !== data.price ? (
        <div className="text-lg font-bold text-Light absolute top-2 right-2 rounded-full w-[42px] h-[42px] z-20 bg-Red flex-center">
          {data.discount_percentage + "%"}
        </div>
      ) : (
        ""
      )}
      <div className="h-[200px] flex-center">
        <img
          src={storageUrl + data.file_main}
          alt=""
          className="object-contain h-full w-full"
        />
      </div>
      <div className="space-y-2 p-2">
        <div className="flex justify-between items-center">
          <div className="flex g text-yellow-300 items-center">
            <CardStar
              rating={{ rating: data?.average_rating || 0 }}
              isReadOnly={true}
              size={15}
            />
            <div>{data.reviews_count}</div>
          </div>
          <div className="flex items-center text-Third gap-1 ">
            <FaEye />
            <div>{data.views}</div>
          </div>
        </div>
        <div className="w-full text-2xl font-semibold ">{data.name}</div>
        <div className="flex items-center gap-2">
          <div>{data.price}</div>
          <div className="text-sm  text-Third relative line-through">
            {data.discount !== "0.00" &&
            !isNotDiscount &&
            data.discount !== data.price
              ? data.discount + ""
              : ""}
          </div>
        </div>
        <ButMain
          name={
            <div className="flex-center gap-4 text-lg">
              <FaCartPlus />
              <div>{content["Buy Now"]}</div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CardProducts;
