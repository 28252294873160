import React from "react";

const Col = ({ md, lg, sm, xl, xs, col, children, className }) => {
  return (
    <div
      className={`
        ${col ? `col-${col} ` : ""}
        ${lg ? `col-lg-${lg} ` : ""}
        ${md ? `col-md-${md} ` : ""}
        ${xs ? `col-xs-${xs} ` : ""}
        ${sm ? `col-sm-${sm} ` : ""}
        ${xl ? `col-xl-${xl} ` : ""}
        ${className ? `${className} ` : ""}
        `}
    >
      {children}
    </div>
  );
};

export default Col;
