import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GoTag } from "../../assets/icons";
import { ButMain, Col, Container, Row, TitlePage } from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { useFetchClick } from "../../contexts/useFetchClick";
import { useFilter } from "../../contexts/useFilter";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";

const CheckOut = () => {
  const { seller } = useParams();
  const [couponCodeState, setCouponCodeState] = useState();
  const { data } = useFetch("sellers/" + seller);
  const { searchParams } = useFilter();
  const {
    handleChangeInput,
    handleSubmit,
    formData,
    setFormData,
    loadingPost,
  } = usePost(
    {
      seller: seller,
      address_id: "",
      note: sessionStorage.getItem("note_order") || "",
      coupon_code: "",
    },
    {
      seller: ["required"],
      address_id: ["required"],
      note: [],
      coupon_code: [],
    }
  );
  const { data: couponCode, fetchData } = useFetchClick(
    "coupon-code/" + couponCodeState
  );
  useEffect(() => {
    if (couponCode.code) {
      setFormData({
        ...formData,
        coupon_code: couponCode.code,
      });
    }
  }, [couponCode]);

  const { data: addresses, deleteItem } = useFetch("addresses");
  const totalPrice = searchParams.get("total_price")?.split(" ")?.[0];
  return (
    <div>
      <Container>
        <TitlePage
          title={[
            content["Home"],
            content["Sellers"],
            content["Cart"],
            content["Checkout"],
          ]}
        />
        <Row>
          <Col md={8}>
            <Col md={10} className="mx-auto">
              <div className="text-xl px-3 py-2 font-semibold bg-Third/30 mb-4">
                {content["Address"]}
              </div>

              {addresses.map((address) => (
                <Row justify="between mb-4 select-none">
                  <Col md={9} className="flex items-center gap-5">
                    <div className="flex items-center gap-5">
                      <div>
                        <input
                          id={`address-${address.id}`}
                          type="radio"
                          name="address_id"
                          // checked={formData?.address_id === address.id}
                          value={address.id}
                          onChange={handleChangeInput}
                        />
                      </div>
                      <label htmlFor={`address-${address.id}`}>
                        <div className="flex items-center gap-4 ">
                          <div className="font-semibold">
                            {address.city}, {address?.post_code}
                          </div>
                          <div className="border border-Main text-Main text-sm rounded-sm px-1">
                            {address.full_name}
                          </div>
                        </div>
                        <div>
                          {address.street_name}, {address?.house_number}
                        </div>
                      </label>
                    </div>
                  </Col>
                  <Col md={2} className="flex-center font-semibold">
                    <Link
                      to={"/address/" + address?.id}
                      className="px-4 cursor-pointer border-r"
                    >
                      {content["Edit"]}
                    </Link>
                    <div
                      onClick={() => deleteItem(address?.id)}
                      className="px-4 cursor-pointer text-Red"
                    >
                      {content["Remove"]}
                    </div>
                  </Col>
                </Row>
              ))}

              <br />
              <hr />
              <br />
              <Link
                to="/address/add"
                className="block text-Main text-lg font-semibold "
              >
                + {content["Add New Address"]}
              </Link>
              <br />
            </Col>
          </Col>
          <Col md={4}>
            <div className="border rounded-md p-3 space-y-4">
              <div className="text-xl font-semibold mb-5">
                {content["Order Summary"]}
              </div>
              <div className="border rounded-md p-2 flex justify-between items-center">
                <GoTag size={30} className="text-Main" />
                <input
                  type="text"
                  name="coupon_code"
                  placeholder={content["Coupon Code"]}
                  onChange={(e) => setCouponCodeState(e.target.value)}
                />
                <ButMain
                  onClick={() => {
                    setFormData({
                      ...formData,
                      coupon_code: "",
                    });
                    if (couponCodeState) {
                      fetchData();
                    }
                  }}
                  name={content["Apply"]}
                  className="py-1 rounded-sm"
                />
              </div>
              <div className="flex justify-between items-center my-2">
                <div>{content["Subtotal"]}</div>
                <div>{searchParams.get("total_price")}</div>
              </div>
              <div className="flex justify-between items-center my-2">
                <div>{content["Coupon Applied"]}</div>
                <div>
                  {couponCode?.discount
                    ? totalPrice * (couponCode?.discount / 100) +
                      " " +
                      searchParams.get("total_price")?.split(" ")?.[1]
                    : 0}
                </div>
              </div>
              <div className="flex justify-between items-center my-2">
                <div>{content["Estimated Delivery by"]}</div>
                <div>
                  {searchParams.get("delivery_time")} {content["days"]}{" "}
                </div>
              </div>
              <div className="flex justify-between items-center my-2">
                <div>{content["Delivery price"]}</div>
                <div>
                  {data?.delivery_price === 0 ||
                  data?.maximum_price < totalPrice
                    ? "Free"
                    : data?.maximum_price > totalPrice
                    ? data?.delivery_price +
                      " " +
                      searchParams.get("total_price")?.split(" ")?.[1]
                    : ""}
                </div>
              </div>
              <hr />
              <div className="flex justify-between items-center my-2">
                <div>{content["TOTAL"]}</div>
                <div>
                  {totalPrice -
                    (couponCode?.discount
                      ? totalPrice * (couponCode?.discount / 100)
                      : 0) +
                    (data?.delivery_price === 0 ||
                    data?.maximum_price < totalPrice
                      ? 0
                      : data?.maximum_price > totalPrice
                      ? data?.delivery_price
                      : 0) +
                    " " +
                    searchParams.get("total_price")?.split(" ")?.[1]}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <ButMain
          onClick={() => handleSubmit("orders", "noRoute")}
          name={content["Continue to Payment"]}
          loadingPost={loadingPost}
          className="text-center text-xl py-3 mx-auto my-3 w-[250px]"
        />
      </Container>
    </div>
  );
};

export default CheckOut;
