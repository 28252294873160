import React from "react";
import { Col, Container, PageLoading, Pagination, Row } from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { FaRegStar } from "react-icons/fa";
import { content } from "../../contexts/useLang";

const SellerRating = () => {
  const { searchAll } = useFilter();
  const { data, loading } = useFetch("reviews" + searchAll);
  if (loading) {
    return <PageLoading />;
  }
  return (
    <div>
      <Container>
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">{content["Reviews"]}</h2>
          {data?.product?.reviews?.length ? (
            <Row>
              {data?.product?.reviews?.map((review, index) => (
                <Col
                  md={20}
                  key={index}
                  className="border p-4 rounded-md shadow-sm bg-gray-50 text-center"
                >
                  <div className="flex items-center justify-between">
                    <span className="text-yellow-500">{review.rating} ★</span>
                  </div>
                  <p className="text-gray-600 mt-2">{review.review}</p>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="flex flex-col items-center justify-center text-center py-6">
              <FaRegStar size={50} className="text-gray-400" />
              <p className="text-gray-500 mt-4 text-lg">
                {content["There are no reviews yet"]}
              </p>
            </div>
          )}
        </div>
        <Pagination data={data} />
      </Container>
    </div>
  );
};

export default SellerRating;
