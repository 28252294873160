import React, { useEffect, useState } from "react";
import { AiFillDelete } from "../../assets/icons";
import { ButMain } from "../../components";
import { useContextData } from "../../contexts/ContextData";
import { useContextState } from "../../contexts/ContextState";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";
const Profile = () => {
  const { profile } = useContextData();
  const { setOpenPopUpDeleteAccount } = useContextState();
  const [isEditing, setIsEditing] = useState(false);
  const { formData, handleChangeInput, handleSubmit, setFormData } = usePost(
    {
      name: "",
      phone: "",
    },
    {
      name: ["required"],
      phone: ["required"],
    }
  );
  useEffect(() => {
    setFormData({
      name: profile?.name,
      phone: profile?.phone,
    });
  }, [profile]);

  return (
    <div className="flex justify-center items-center  bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mt-5">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          {content["Profile"]}
        </h2>
        {!isEditing ? (
          <div>
            <div className="mb-4">
              <p className="text-gray-600 font-medium">{content["Name"]}</p>
              <p className="text-gray-800">{formData.name}</p>
            </div>
            <div className="mb-4">
              <p className="text-gray-600 font-medium">{content["Username"]}</p>
              <p className="text-gray-800">{profile?.username}</p>
            </div>
            <div className="mb-4">
              <p className="text-gray-600 font-medium">{content["Email"]}</p>
              <p className="text-gray-800">{profile?.email}</p>
            </div>
            <div className="mb-4">
              <p className="text-gray-600 font-medium">{content["Phone"]}</p>
              <p className="text-gray-800">{formData.phone}</p>
            </div>

            <button
              onClick={() => setIsEditing(true)}
              className="bg-Main text-white px-4 py-2 rounded hover:bg-Main/80"
            >
              {content["Edit Profile"]}
            </button>
          </div>
        ) : (
          <div>
            <div className="mb-4">
              <label className="block text-gray-600 font-medium mb-2">
                {content["Name"]}
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChangeInput}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 font-medium mb-2">
                {content["Phone"]}
              </label>
              <input
                type="tel"
                name={content["phone"]}
                value={formData.phone}
                onChange={handleChangeInput}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
            </div>
            <div className="flex justify-between">
              <button
                onClick={() => handleSubmit("update-profile")}
                type={content["submit"]}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                {content["Save"]}
              </button>
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                {content["Cancel"]}
              </button>
            </div>
          </div>
        )}
        <ButMain
          className="bg-Red hover:bg-Red/50 w-fit mt-5 ml-auto"
          name={
            <div className="flex-center gap-2">
              <div>
                <AiFillDelete size={23} />
              </div>
              <div>{content["Delete my account"]}</div>
            </div>
          }
          onClick={() => setOpenPopUpDeleteAccount(true)}
        />
      </div>
    </div>
  );
};

export default Profile;
