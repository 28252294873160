import { useEffect, useRef, useState } from "react";

export const useClose = () => {
  const [isOpen, setIsOpen] = useState(false);
  const mouseRef = useRef();
  const reverseFunction = (bool) => {
    if (bool === false || bool === true) setIsOpen(bool);
    else setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mouseRef.current && !mouseRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return { isOpen, setIsOpen, mouseRef, reverseFunction };
};
