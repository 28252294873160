import React from "react";
import AwesomeStarsRating from "react-awesome-stars-rating";

const CardStar = ({ rating, setRating, size = 40, isReadOnly = false }) => {
  const handleRatingChange = (newRating) => {
    if (!isReadOnly && setRating) {
      setRating({ ...rating, rating: newRating });
    }
  };

  return (
    <div
      style={{ textAlign: "center" }}
      className={` ${isReadOnly ? "pointer-events-none" : ""}`}
    >
      <AwesomeStarsRating
        value={rating?.rating}
        onChange={handleRatingChange}
        size={size}
        disabled={isReadOnly}
        className={`flex justify-center gap-1 ${isReadOnly ? "no-hover" : ""}`}
      />
    </div>
  );
};

export default CardStar;
