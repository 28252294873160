import React, { useState } from "react";
import { CiSearch } from "../../assets/icons";
import {
  ButMain,
  CardFilters,
  CardProducts,
  Col,
  Container,
  PageLoading,
  Pagination,
  PriceFilter,
  Row,
  TitlePage,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { useContextState } from "../../contexts/ContextState";
import { FaFilter } from "react-icons/fa";
import { content } from "../../contexts/useLang";
const Products = () => {
  const { searchAll } = useFilter();
  const { data, loading } = useFetch("products" + searchAll);
  const { setOpenSideBarFilter } = useContextState();
  return (
    <div>
      <Container>
        <TitlePage title={[content["Home"], content["Products"]]} />
        <Row justify="between">
          <Filter />
          <Col md={9}>
            {loading ? (
              <PageLoading />
            ) : (
              <Row className="mt-5 ">
                <Col className="md:hidden ">
                  <ButMain
                    className="w-fit px-5 mb-5"
                    name={
                      <div className="flex-center gap-2">
                        <div>{content["Filter"]}</div>
                        <FaFilter />
                      </div>
                    }
                    onClick={() => setOpenSideBarFilter(true)}
                  />
                </Col>
                {data?.data?.length
                  ? data?.data?.map((e, i) => (
                      <Col md={3} key={i} className="pb-3">
                        <CardProducts key={e.id} data={e} />
                      </Col>
                    ))
                  : ""}
              </Row>
            )}

            <Pagination data={data} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;
const Filter = () => {
  const [searchSection, setsearchSection] = useState();
  const {
    handleParamsAdd,
    handleParamsDeleteAll,
    handleParamsRemove,
    searchParams,
  } = useFilter();
  const { data: dataSections } = useFetch(
    searchSection ? "sections?search=" + searchSection : "sections"
  );
  const { data: dataSizes } = useFetch("sizes");
  const { data: dataColors } = useFetch("colors");
  return (
    <Col md={3} className={`sticky top-10 left-10 z-10 max-md:hidden`}>
      <div className="flex justify-between items-center py-2">
        <div className="font-semibold text-lg">{content["Filters"]}</div>
        <div className="text-Third" onClick={() => handleParamsDeleteAll()}>
          {content["Clear All"]}
        </div>
      </div>
      <div className="">
        <CardFilters title={content["Sort By"]}>
          <div className="">
            <div
              onClick={() => handleParamsRemove("sort_by")}
              className="py-1 hover:bg-Third/30 pl-10 rounded-md mx-2 "
            >
              {content["All"]}
            </div>
            {[
              { title: content["Best Selling"], name: "sales" }, // الأكثر مبيعًا
              { title: content["Highest Price"], name: "price_desc" }, // الأغلى سعرًا
              { title: content["Lowest Price"], name: "price_asc" }, // الأرخص سعرًا
              { title: content["Most Viewed"], name: "views" }, // الأكثر مشاهدة
              { title: content["Most Loved"], name: "loved" }, // الأكثر إعجابًا
              { title: content["Biggest Discount"], name: "discount" }, // أعلى خصم
              { title: content["Newest Arrivals"], name: "new" }, // الأحدث وصولاً
            ]?.map((e, i) => (
              <div
                key={i}
                onClick={() => handleParamsAdd("sort_by", e.name)}
                className={`${
                  e.name === searchParams.get("sort_by")
                    ? "bg-Third/30"
                    : "hover:bg-Third/30"
                } py-1  pl-10 rounded-md mx-2 `}
              >
                {e.title}
              </div>
            ))}
          </div>
        </CardFilters>
        <CardFilters title={content["Sections"]}>
          <div className="border border-Third/50 px-4 py-[4px] rounded-full flex items-center mx-2 my-3">
            <CiSearch size={20} className="text-Secondary/80" />
            <input
              type="search"
              placeholder="Search"
              onChange={(e) => setsearchSection(e.target.value)}
              className="bg-transparent px-2"
            />
          </div>
          <div className="h-[150px] overflow-y-scroll !scroll-mx-8">
            <div
              onClick={() => handleParamsRemove("section")}
              className="py-1 hover:bg-Third/30 pl-10 rounded-md mx-2 "
            >
              {content["All"]}
            </div>
            {dataSections?.data?.map((e, i) => (
              <div
                key={i}
                onClick={() => handleParamsAdd("section", e.id)}
                className={`${
                  e.id === +searchParams.get("section")
                    ? "bg-Third/30"
                    : "hover:bg-Third/30"
                } py-1  pl-10 rounded-md mx-2 `}
              >
                {e.name}
              </div>
            ))}
          </div>
        </CardFilters>

        <CardFilters title={content["Price"]}>
          <PriceFilter />
        </CardFilters>
        <CardFilters title={content["Size"]}>
          <div className="flex items-center flex-wrap gap-2 my-1 py-2">
            {dataSizes?.map((e, i) => (
              <div
                onClick={() => handleParamsAdd("size", e.id)}
                key={i}
                className={`${
                  +e.id === +searchParams.get("size")
                    ? "bg-black text-Light"
                    : "hover:bg-black hover:text-Light"
                } bg-Third/30 w-10 h-10 rounded-md flex-center`}
              >
                {e.name}
              </div>
            ))}
          </div>
        </CardFilters>
        <CardFilters title={content["Color"]}>
          <div className="flex flex-wrap gap-2  py-2">
            {dataColors?.map((e, i) => (
              <div
                onClick={() => handleParamsAdd("color", e.id)}
                key={i}
                style={{ borderColor: e.name }}
                className={`transition-all rounded-md w-[75px] h-7 overflow-hidden ${
                  +e.id === +searchParams.get("color")
                    ? "border"
                    : "hover:border"
                }`}
              >
                <div
                  style={{ backgroundColor: e.name }}
                  className={`${
                    +e.id === +searchParams.get("color")
                      ? "scale-90"
                      : "hover:scale-90"
                  } transition-all w-full h-full  rounded-md`}
                />
              </div>
            ))}
          </div>
        </CardFilters>
      </div>
    </Col>
  );
};
