import React from "react";
import {
  CardOrders,
  Col,
  Container,
  PageLoading,
  Pagination,
  Row,
  Select,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useFilter } from "../../contexts/useFilter";
import { content } from "../../contexts/useLang";

const Orders = () => {
  const { searchAll, searchParams, handleParamsAdd, handleParamsRemove } =
    useFilter();
  const { data, loading } = useFetch("orders" + searchAll);
  if (loading) {
    return <PageLoading />;
  }
  return (
    <div>
      <Container>
        <div className="text-center text-3xl font-semibold my-4">
          {content["My Order"]}
        </div>
        <br />
        <Select
          data={{
            options: [
              { id: "", name: content["All"] },
              { id: "pending", name: content["Awaiting Confirmation"] },
              { id: "shipped", name: content["Shipped"] },
              { id: "completed", name: content["Completed"] },
            ],
            onChange: (selected) =>
              selected.value
                ? handleParamsAdd("status", selected.value)
                : handleParamsRemove("status"),
          }}
          value={searchParams.get("status")}
        />
        {data?.data?.length ? (
          <>
            <br />
            <Row>
              {data.data.map((e, i) => (
                <Col key={i} md={4}>
                  <CardOrders data={e} />
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center p-5 text-center text-gray-600">
            <AiOutlineShoppingCart className="w-16 h-16 text-gray-400" />

            <p className="mt-4 text-lg font-semibold">
              {content["No Orders Found"]}
            </p>
            <p className="text-sm text-gray-500">
              {content["Please add new orders to see them here."]}
            </p>
          </div>
        )}
        <Pagination data={data} />
      </Container>
    </div>
  );
};

export default Orders;
