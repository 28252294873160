import React from "react";
import { FaLocationDot } from "../../assets/icons";
import ButMain from "../Buttons/ButMain";
import { content } from "../../contexts/useLang";
const CardAddress = ({ data, deleteItem }) => {
  return (
    <div className="border rounded-md flex items-start gap-2 p-4">
      <div>
        <FaLocationDot size={25} className="text-Main" />
      </div>
      <div className="flex-1">
        <div className="text-xl font-semibold">{data.full_name}</div>
        <div className=" font-semibold">
          {data.city}, {data.post_code}
        </div>
        <div className="text-Third my-3">
          {data.street_name}, {data.house_number}
        </div>
        <div className="flex justify-end  gap-2">
          <ButMain
            link={"/address/" + data?.id}
            className="text-center py-1 rounded-md"
            name={content["Edit Address"]}

          />
          <ButMain
            onClick={() => deleteItem(data?.id)}
            className="text-center py-1 rounded-md border border-Red text-Red  bg-white hover:bg-slate-50"
            name={content["Delete"]}

          />
        </div>
      </div>
    </div>
  );
};

export default CardAddress;
