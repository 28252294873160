import React from "react";
import {} from "react-icons/fa";
import { useFetch } from "../../contexts/useFetch";
import { FiBellOff } from "react-icons/fi";
import {
  FaDollarSign,
  FaPercentage,
  FaWallet,
  FaHourglassHalf,
  FaCheckCircle,
  FaShoppingCart,
} from "react-icons/fa";
import { content } from "../../contexts/useLang";

const Dashboard = () => {
  const { data } = useFetch("profile-seller");
  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6">{content["Dashboard"]}</h1>

      {/* Grid for statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* إجمالي المبيعات */}
        <div className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg">
          <div>
            <h4 className="text-sm font-medium text-gray-500">
              {content["Total Sales"]}
            </h4>
            <p className="text-xl font-semibold text-gray-800">
              {data?.total_sales}
            </p>
          </div>
          <div className="text-blue-500">
            <FaDollarSign className="text-3xl" /> {/* أيقونة من React Icons */}
          </div>
        </div>

        {/* إجمالي العمولة */}
        <div className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg">
          <div>
            <h4 className="text-sm font-medium text-gray-500">
              {content["Platform Commission"]}
            </h4>
            <p className="text-xl font-semibold text-gray-800">
              {data?.platform_commission}
            </p>
          </div>
          <div className="text-red-500">
            <FaPercentage className="text-3xl" /> {/* أيقونة من React Icons */}
          </div>
        </div>

        {/* الأرباح الصافية */}
        <div className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg">
          <div>
            <h4 className="text-sm font-medium text-gray-500">
              {content["Net Earnings"]}
            </h4>
            <p className="text-xl font-semibold text-gray-800">
              {data?.net_earnings}
            </p>
          </div>
          <div className="text-green-500">
            <FaWallet className="text-3xl" /> {/* أيقونة من React Icons */}
          </div>
        </div>

        {/* المبالغ المستحقة */}
        <div className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg">
          <div>
            <h4 className="text-sm font-medium text-gray-500">
              {content["Pending Payouts"]}
            </h4>
            <p className="text-xl font-semibold text-gray-800">
              {data?.pending_payouts}
            </p>
          </div>
          <div className="text-yellow-500">
            <FaHourglassHalf className="text-3xl" />{" "}
            {/* أيقونة من React Icons */}
          </div>
        </div>

        {/* إجمالي المدفوعات المستلمة */}
        <div className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg">
          <div>
            <h4 className="text-sm font-medium text-gray-500">
              {content["Total Payouts Received"]}
            </h4>
            <p className="text-xl font-semibold text-gray-800">
              {data?.total_payouts_received}
            </p>
          </div>
          <div className="text-indigo-500">
            <FaCheckCircle className="text-3xl" /> {/* أيقونة من React Icons */}
          </div>
        </div>

        {/* إجمالي عدد الطلبات المكتملة */}
        <div className="flex items-center justify-between p-4 bg-white shadow-md rounded-lg">
          <div>
            <h4 className="text-sm font-medium text-gray-500">
              {content["Number of Orders"]}
            </h4>
            <p className="text-xl font-semibold text-gray-800">
              {data?.number_of_orders}
            </p>
          </div>
          <div className="text-purple-500">
            <FaShoppingCart className="text-3xl" />
          </div>
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-semibold mb-4">
          {content["Notifications"]}
        </h2>

        {data?.notifications?.length ? (
          data?.notifications?.map((e, i) => (
            <div
              className={`${
                !e.is_read ? "bg-blue-100" : "bg-white"
              }  shadow-md rounded-lg p-2 my-1`}
            >
              <p className="flex justify-between items-center">
                <div className="text-lg font-semibold">{e.title}</div>
                <div>{e.created_at}</div>
              </p>
              <p className="text-gray-600">{e.message}</p>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center text-black/50 text-lg py-10">
            <FiBellOff size={50} className="mb-4 text-gray-400" />
            <span>{content["No notifications available"]}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
