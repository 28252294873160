import { Tooltip } from "react-tooltip";

function IconHover({ children, id, content, noHover, onClick }) {
  return (
    <>
      <div id={id || content} onClick={onClick}>
        <div
          className={`w-10 h-10 rounded-full flex-center cursor-pointer transition-all ${
            noHover ? "" : "hover:bg-gray-50"
          }`}
        >
          {children}
        </div>
        <Tooltip anchorSelect={`#${id || content}`} content={content} />
      </div>
    </>
  );
}
export default IconHover;
