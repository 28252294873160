import React from "react";
import { ButMain, CardAuth, Col, InputsPage, Row } from "../../components";
import { useLogin } from "../../contexts/useLogin";
import { content } from "../../contexts/useLang";

const PassResetSend = () => {
  const { handleChange, handleSubmit, loadingPost } = useLogin(
    { email: "" },
    { email: ["required", "email"] }
  );

  return (
    <CardAuth title={content["Reset Your Password"]}>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-Main">
          {content["First Step: Provide your email to receive a reset link."]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/3 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/3 h-[5px] bg-Third/30 rounded-full "></div>
          <div className="w-1/3 h-[5px] bg-Third/30 rounded-full"></div>
        </Col>
        {[
          {
            xs: 11,
            title: content["Enter your email address"],
            onChange: handleChange,
            inputType: "input",
            name: "email",
            type: "text",
          },
        ].map((e, i) => (
          <InputsPage key={i} data={e} />
        ))}
        <Col xs={11} className=" ">
          <ButMain
            loadingPost={loadingPost}
            onClick={() =>
              handleSubmit(
                "password-reset/send",
                "route",
                "/auth/p-reset/verify"
              )
            }
            name={content["Next"]}
            className="text-center py-3 text-xl"
          />
        </Col>
      </Row>
    </CardAuth>
  );
};

export default PassResetSend;
