import { AiOutlineCloseCircle, MdOutlineWarning } from "../../assets/icons";
import { useContextState } from "../../contexts/ContextState";
import { content } from "../../contexts/useLang";
import { Col } from "../GridSystem";

const ErrorMessage = () => {
  const { error, setError } = useContextState();
  return (
    <div>
      {error.length > 0 && (
        <>
          <div
            onClick={() => {
              setError([]);
            }}
            className="fixed top-0 left-0 w-full h-full z-[9999] bg-black/30"
          />
          <Col
            md={4}
            sm={11}
            className="z-[99999] bg-[#CC3824] rounded-lg fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div>
              <div className="flex justify-between items-center text-white py-3 px-2 border-b">
                <div>
                  <AiOutlineCloseCircle
                    className="cursor-pointer"
                    size={20}
                    onClick={() => {
                      setError([]);
                    }}
                  />
                </div>
                <div>{content["Warnings"]}</div>
                <div>
                  <MdOutlineWarning size={20} />
                </div>
              </div>
              <div>
                <ul className="text-white py-2 px-3">
                  {error?.map((e, i) => (
                    <li key={i} className="flex items-center py-1">
                      <div className="w-2 h-2 rounded-full bg-white mx-2"></div>
                      <div className="">{e}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Col>
        </>
      )}
    </div>
  );
};

export default ErrorMessage;
