import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl, token } from "../config";
import { useContextState } from "./ContextState";
import { getArrayLength } from "./functions";
import useValidate from "./useValidate";

export const usePost = (initialState, validate) => {
  const [formData, setFormData] = useState(initialState);
  const [viewFile, setViewFile] = useState({});
  const [viewFiles, setViewFiles] = useState({});
  const [successPost, setSuccessPost] = useState(0);
  const [loadingPost, setLoadingPost] = useState(false);
  //
  const navigate = useNavigate();
  //
  const { error } = useValidate(formData, validate);
  //
  const {
    setError,
    setMessages,
    refreshData,
    setRefreshData,
    setOpenPopUpNoAccount,
    setOpenPopUpRate,
    setOpenpayment,
    setOpenDelete,
    setOpenPopUpUpdateOrder,
    setPaymentMethodId,
  } = useContextState();
  //
  const handleChangeInput = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };
  //
  const handleChangeFile = (event) => {
    const { files, name } = event.target;
    if (files[0]) {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: files[0] }));

      setViewFile((prevViewFile) => ({
        ...prevViewFile,
        [name]: files[0] && URL.createObjectURL(files[0]),
      }));
    }
  };
  //
  const handleChangeFiles = (event) => {
    const { files, name } = event.target;
    if (files.length > 0) {
      setFormData((prevFiles) => ({
        ...prevFiles,
        [name]: [...(prevFiles[name] || []), ...files],
      }));

      setViewFiles((prevViewFiles) => ({
        ...prevViewFiles,
        [name]: [
          ...(prevViewFiles[name] || []), // دمج الصور السابقة
          ...Array.from(files).map((file) => URL.createObjectURL(file)), // إنشاء روابط جديدة
        ],
      }));
    }
  };

  //
  const handleCheckBoxes = (e) => {
    const { value, checked, name } = e.target;
    setFormData((prevCheckArray) => {
      if (checked) {
        return {
          ...prevCheckArray,
          [name]: [...(prevCheckArray[name] || []), value],
        };
      } else {
        return {
          ...prevCheckArray,
          [name]: prevCheckArray[name].filter((p) => p !== value),
        };
      }
    });
  };
  // isMulti or not multi
  const handleChangeSelect = (selected, active) => {
    setFormData({
      ...formData,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    });
  };
  //
  const handleSubmit = async (url, nameEvent, event) => {
    if (getArrayLength(error)) {
      return setError([...error]);
    }
    setLoadingPost(true);
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData || {})) {
      if (
        !key.includes("option_") &&
        value &&
        value !== undefined &&
        value !== null &&
        (typeof value === "string" ? value?.trim() !== "" : value !== "")
      ) {
        if (key === "choices") {
          value.forEach((choice, index) => {
            if (choice.id) {
              formDataToSend.append(`choices[${index}][id],`, choice.id);
            }
            formDataToSend.append(
              `choices[${index}][choice_text]`,
              choice.choice_text
            );
          });
        } else if (Array.isArray(value) && !key.includes("json_"))
          for (let i = 0; i < value.length; i++)
            formDataToSend.append(key, value[i]);
        else if (key.includes("json_"))
          formDataToSend.append(
            key.replace("json_", ""),
            JSON.stringify(value)
          );
        else formDataToSend.append(key, value);
      }
    }
    axios
      .post(`${baseUrl}${url}`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((e) => {
        setLoadingPost(false);
        setOpenPopUpRate(false);
        setOpenpayment(false);
        setOpenDelete(false);
        setOpenPopUpUpdateOrder(false);
        setPaymentMethodId();
        setMessages([e?.data.data]);

        if (e?.data?.data?.url) {
          window.location.href = e?.data?.data?.url;
        }
        if (e?.data?.result) {
          window.location.href = e?.data?.result;
        }
        switch (nameEvent) {
          case "noRoute":
            return console.log("done");
          case "route":
            return navigate(event);
          case "reload":
            return window.location.reload();
          case "refresh":
            return setSuccessPost(successPost + 1);
          case "refreshData":
            return setRefreshData(refreshData + 1);
          case "refreshData-route":
            setRefreshData(refreshData + 1);
            return navigate(event);
          case "message":
            setMessages([event]);
            return;
          default:
            return window.history.go(-1);
        }
      })
      .catch((err) => {
        setLoadingPost(false);
        if (err.response.status === 401) setOpenPopUpNoAccount(true);
        for (const [key, value] of Object.entries(
          err?.response?.data?.errors || {}
        ))
          setError((preError) => [...preError, ...value]);
      });
  };

  return {
    formData,
    setFormData,
    viewFile,
    setViewFile,
    viewFiles,
    setViewFiles,
    handleChangeInput,
    handleChangeSelect,
    handleChangeFiles,
    handleChangeFile,
    handleCheckBoxes,
    handleSubmit,
    successPost,
    error,
    loadingPost,
  };
};
