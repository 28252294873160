import React from "react";
import { useNavigate } from "react-router-dom";
import { storageUrl } from "../../config";

const CardSellers = ({ image, name, username }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/sellers/" + username)}
      className="w-full text-xl font-semibold text-center "
    >
      <div className="rounded-full overflow-hidden mb-4 mx-auto h-[150px] w-[150px] ">
        <img src={storageUrl + image} alt="" className="object-contain " />
      </div>
      <div>{name}</div>
    </div>
  );
};

export default CardSellers;
