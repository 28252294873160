import { Col } from "../GridSystem";

const Input = ({ data, value }) => {
  return (
    <Col lg={data.lg || data.md} md={data.xs || data.md || 4} xs={data.xs || 6}>
      <div className="text-sm text-Third">
        {" "}
        { data?.title }
      </div>
      <input
        type={data.type || "text"}
        name={data.name}
        value={value}
        readOnly={data.readOnly || false}
        onChange={data.onChange}
        placeholder={data.placeholder || (data?.isTitle ? "" : data?.title)}
        className={`${
          data.className ||
          " border bg-[#fff] text-black border-[#D2D2D2] m-1 py-[0.6rem] rounded-[4px]  focus:outline  !outline-Main !outline-[2px]"
        }`}
      />
    </Col>
  );
};

export default Input;
