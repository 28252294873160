import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaLocationDot,
  GoHeartFill,
  HiMiniUser,
  LuLogOut,
  LuUser,
  RiFileList2Fill,
  RiUserFollowFill,
} from "../../assets/icons";
import { useContextState } from "../../contexts/ContextState";
import { useClose } from "../../contexts/useClose";
import { content } from "../../contexts/useLang";

const User = () => {
  const { isOpen, mouseRef, reverseFunction } = useClose();
  const { setOpenLogOut } = useContextState();
  return (
    <div ref={mouseRef} className="relative inline-block text-left">
      <button
        onClick={reverseFunction}
        className="flex items-center space-x-2  p-2 rounded-full "
      >
        <LuUser size={26} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          <ul className="py-2">
            {[
              {
                onClick: "",
                link: "/profile",
                name: "My Profile",
                Icone: HiMiniUser,
              },
              {
                onClick: "",
                link: "/orders",
                name: "My Order",
                Icone: RiFileList2Fill,
              },
              {
                onClick: "",
                link: "/address",
                name: "My Address",
                Icone: FaLocationDot,
              },
              {
                onClick: "",
                link: "/favorites",
                name: "Favorite",
                Icone: GoHeartFill,
              },
              {
                onClick: "",
                link: "/following",
                name: "Following",
                Icone: RiUserFollowFill,
              },

              {
                onClick: () => setOpenLogOut(true),
                name: "Log Out",
                Icone: LuLogOut,
              },
            ].map((e, i) => (
              <Li
                key={i}
                Icone={e.Icone}
                link={e.link}
                name={content[e.name]}
                onClick={e.onClick}
              />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default User;
const Li = ({ onClick, link, name, Icone }) => {
  const navigate = useNavigate();
  return (
    <li
      onClick={() => {
        if (link) {
          navigate(link);
        } else if (onClick) {
          onClick();
        }
      }}
      className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
    >
      <Icone size={23} className="text-Main -scale-x-100" />
      <div>{name}</div>
    </li>
  );
};
