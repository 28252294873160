import { useEffect, useState } from "react";
import { AiFillDelete } from "../../assets/icons";
import { usePost } from "../../contexts/usePost";
import { Col } from "../GridSystem";
import ButMain from "../Buttons/ButMain";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";

const CardCart = ({ data, deleteItem }) => {
  const [isChanged, setIsChanged] = useState(false);

  const { formData, setFormData, handleSubmit, loadingPost } = usePost(
    { color: data?.color, size: data?.size, quantity: data.quantity },
    {}
  );

  // تحديث حالة التغيير
  useEffect(() => {
    setFormData({
      color: +data?.color,
      size: +data?.size,
      quantity: +data.quantity,
    });
  }, [data]);
  useEffect(() => {
    if (
      formData.color !== +data?.color ||
      formData.size !== +data?.size ||
      formData.quantity !== +data.quantity
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [formData]);

  // تقليل الكمية
  const decrementQuantity = () => {
    setFormData({
      ...formData,
      quantity: Math.max(1, formData.quantity - 1),
    });
  };
  // زيادة الكمية
  const incrementQuantity = () => {
    setFormData({
      ...formData,
      quantity: Math.min(
        data?.product?.quantity || Infinity,
        formData.quantity + 1
      ),
    });
  };
  // تحديث اللون
  const updateColor = (colorId) => {
    setFormData({
      ...formData,
      color: colorId,
    });
  };
  // تحديث الحجم
  const updateSize = (sizeId) => {
    setFormData({
      ...formData,
      size: sizeId,
    });
  };

  return (
    <>
      {/* صورة المنتج */}
      <Col md={20}>
        <div className="w-[100px] h-[100px] mx-auto">
          <img
            src={storageUrl + data?.product?.file_main}
            alt={data?.product?.name}
            width={150}
            className="mx-auto w-full h-full object-contain"
          />
        </div>
      </Col>

      {/* تفاصيل المنتج */}
      <Col md={20} className="flex justify-center items-center">
        <div className="text-start">
          <div className="font-semibold text-lg">{data?.product?.name}</div>

          {/* اختيار اللون */}
          <div className="flex flex-wrap gap-2">
            {data?.product?.color?.map((color) => (
              <div
                key={color.id}
                onClick={() => updateColor(color.id)}
                style={{ borderColor: color.name }}
                className={`transition-all ${
                  color.id === formData?.color || color.id === data?.color
                    ? "border"
                    : "hover:border"
                } rounded-full w-5 h-5 overflow-hidden`}
              >
                <div
                  style={{ backgroundColor: color.name }}
                  className={`transition-all w-full h-full rounded-full ${
                    color.id === formData?.color ? "scale-90" : "hover:scale-90"
                  }`}
                />
              </div>
            ))}
          </div>

          {/* اختيار الحجم */}
          <div className="flex items-center gap-2 my-1">
            {data?.product?.size?.map((size) => (
              <div
                key={size.id}
                onClick={() => updateSize(size.id)}
                className={`bg-Third/30 text-xs w-6 h-6 rounded-md flex-center ${
                  size.id === formData?.size
                    ? "text-Light bg-black"
                    : "hover:text-Light hover:bg-black"
                }`}
              >
                {size.name}
              </div>
            ))}
          </div>

          <div className="text-Third">{data?.product?.price}</div>
        </div>
      </Col>

      {/* التحكم في الكمية */}
      <Col md={20} className="flex-center gap-3">
        <div
          onClick={decrementQuantity}
          className="border border-Main text-Main text-2xl rounded-full flex-center w-8 h-8 cursor-pointer"
        >
          -
        </div>
        {formData.quantity}
        <div
          onClick={incrementQuantity}
          className="bg-Main text-Light text-2xl rounded-full flex-center w-8 h-8 cursor-pointer"
        >
          +
        </div>
      </Col>

      {/* إجمالي السعر */}
      <Col md={20}>{data?.total_price}</Col>

      {/* أزرار التحكم */}
      <Col md={20} className="flex items-center gap-3">
        {isChanged && (
          <ButMain
            loadingPost={loadingPost}
            name={content["Save"]}
            onClick={() => handleSubmit(`cart/${data?.id}`, "refreshData")}
          />
        )}
        <AiFillDelete
          size={20}
          className="text-Red mx-auto cursor-pointer"
          onClick={() => deleteItem(data.id)}
        />
      </Col>
    </>
  );
};

export default CardCart;
