import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { messaging } from "./firebase";

const Messaging = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <></>;
};

export default Messaging;
