import { MdOutlineMenuOpen } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { logo } from "../../assets/images";
import { useContextState } from "../../contexts/ContextState";
import { content } from "../../contexts/useLang";

function SideBar() {
  const { openSidebar, setOpenSidebar } = useContextState();
  const { pathname } = useLocation();
  const sidebar = [
    {
      link: "/dashboard/",
      name: "Dashboard",
    },
    {
      link: "/dashboard/products",
      name: "Products",
    },
    {
      link: "/dashboard/orders",
      name: "Order Lists",
    },
    {
      link: "/dashboard/rating",
      name: "Rating",
    },
    {
      link: "/dashboard/store-profile",
      name: "Store Profile",
    },
  ];
  return (
    <>
      <div
        className={`${
          openSidebar
            ? "!w-[250px] !max-lg:translate-x-10 !max-lg:w-3/4 -!right-0"
            : " w-[0px] "
        } !max-lg:translate-x-full bg-white border-r h-[95vh] left-0 scroll max-lg:h-[100%] max-lg:!mt-0  top-0 sticky  max-lg:fixed max-lg:right-0  transition-all py-4 z-50`}
      >
        <ul
          className={`font-semibold space-y-2 px-3 sidebar transition-all ${
            openSidebar ? "" : "hidden"
          } `}
        >
          <li className="md:hidden">
            <img src={logo} alt="" className="w-" />
          </li>
          {sidebar.map((e, i) => (
            <li key={i}>
              <div className={e.link === pathname ? "" : ""} />
              <NavLink
                to={e.link}
                className="block py-2 px-2 h-10 rounded-lg hover:bg-gray-200 transition-all pl-[50px]"
              >
                <div>{content[e.name]}</div>
              </NavLink>
            </li>
          ))}
        </ul>
        <div
          onClick={() => setOpenSidebar(!openSidebar)}
          className="absolute top-24 -right-7 z-50 md:hidden"
        >
          <MdOutlineMenuOpen size={30} />
        </div>
      </div>
      <div
        onClick={() => setOpenSidebar(!openSidebar)}
        style={{ transition: "0.4s" }}
        className={`${
          openSidebar ? "" : "hidden"
        }  max-lg:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-30`}
      />
    </>
  );
}

export default SideBar;
