import { Route, Routes } from "react-router-dom";
import * as component from "./components";
import { useContextData } from "./contexts/ContextData";
import * as require from "./contexts/Require";
import { P403, P404, P500 } from "./errors";
import Dashboard from "./layouts/Dashboard/Dashboard";
import Layout from "./layouts/Layout";
import Messaging from "./Messaging";
import * as pages from "./pages";

const App = () => {
  const { profile } = useContextData();

  return (
    <div className="">
      <Messaging />
      <component.SuccessMessage />
      <component.CancelPayment />
      <component.SideBarFilter />
      <component.PopUpLogOut />
      <component.PopUpDelete />
      <component.SuccessPayment />
      <component.PopUpNoAccount />
      <component.PopUpDeleteMyAccount />
      <component.PopUpNoAccount />
      <component.ErrorMessage />
      <Routes>
        <Route path="403" element={<P403 />} />
        <Route path="*" element={<P404 />} />
        <Route path="500" element={<P500 />} />
        {/* Guest */}
        <Route element={<require.RequireGuest />}>
          <Route path="auth">
            <Route path="login" element={<pages.Login />} />
            <Route path="sign-up" element={<pages.Create />} />
            <Route path="verification-code" element={<pages.Verification />} />
            <Route path="p-reset/send" element={<pages.PassResetSend />} />
            <Route path="p-reset/verify" element={<pages.PassResetVerify />} />
            <Route path="p-reset/reset" element={<pages.PassReset />} />
          </Route>
        </Route>
        {/* Authenticated */}
        <Route element={<require.RequireAuthenticated />}>
          <Route path="auth">
            <Route path="success" element={<pages.Success />} />
          </Route>
          <Route element={<Layout />}>
            <Route path="following" element={<pages.Following />} />
            <Route path="favorites" element={<pages.Favorites />} />
            <Route path="profile" element={<pages.Profile />} />
            <Route path="orders" element={<pages.Orders />} />
            <Route path="orders/:id" element={<pages.Order />} />
            <Route path="checkout/:seller" element={<pages.CheckOut />} />
            <Route path="cart/:username" element={<pages.Cart />} />
            <Route path="address" element={<pages.Address />} />
            <Route path="address/:id" element={<pages.AddAddress />} />
            <Route path="notifications" element={<pages.Notifications />} />
          </Route>
          {/* Non Seller */}
          <Route
            element={<require.RequireNonSeller isSeller={profile?.is_seller} />}
          >
            <Route path="seller">
              <Route path="create-p1" element={<pages.CreateSeller />} />
              <Route path="create-p2" element={<pages.CreateSellerP2 />} />
            </Route>
          </Route>
          {/* Seller */}
          <Route
            element={
              <require.RequireSellerRole isSeller={profile?.is_seller} />
            }
          >
            <Route element={<Dashboard />}>
              <Route path="/dashboard">
                <Route index element={<pages.Dashboard />} />
                <Route path="products" element={<pages.SellerProducts />} />
                <Route
                  path="products/view/:id"
                  element={<pages.SellerProduct />}
                />
                <Route
                  path="products/:id"
                  element={<pages.SellerEventProduct />}
                />
                <Route path="orders" element={<pages.SellerOrders />} />
                <Route path="orders/:id" element={<pages.SellerOrder />} />
                <Route path="rating" element={<pages.SellerRating />} />
                <Route path="store-profile" element={<pages.StoreProfile />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* public */}
        <Route element={<Layout />}>
          <Route path="/" element={<pages.Home />} />
          <Route path="/sellers" element={<pages.Sellers />} />
          <Route path="/sellers/:username" element={<pages.Seller />} />
          <Route path="products" element={<pages.Products />} />
          <Route path="products/:id" element={<pages.Product />} />
          <Route path="/contact-us" element={<pages.ContactUs />} />
          <Route path="/about-us" element={<pages.AboutUs />} />
          <Route path="/categories" element={<pages.Categories />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
