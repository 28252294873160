import { Navigate, Outlet } from "react-router-dom";
import { token } from "../config";

// حراسة للوصول للصفحات المصرح بها للمستخدمين الذين قاموا بتسجيل الدخول فقط
export const RequireAuthenticated = () => {
  if (!token) {
    return <Navigate to="/auth/login" />;
  }
  return <Outlet />;
};

// حراسة للوصول للصفحات المصرح بها للزوار فقط (الذين لم يسجلوا الدخول)
export const RequireGuest = () => {
  if (token) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

// حراسة للوصول للصفحات التي يُسمح بها للمستخدمين الذين ليسوا تجار
export const RequireNonSeller = ({ isSeller }) => {
  if (isSeller === 1) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

// حراسة للوصول للصفحات التي يُسمح بها للتجار فقط
export const RequireSellerRole = ({ isSeller }) => {
  if (isSeller === 0) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};
