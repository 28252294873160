import { useEffect, useState } from "react";
import { AiFillDelete } from "../../assets/icons";
import { usePost } from "../../contexts/usePost";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";

const MobileCardCart = ({ data, deleteItem }) => {
  const [isChanged, setIsChanged] = useState(false);

  const { formData, setFormData, handleSubmit } = usePost(
    { color: data?.color, size: data?.size, quantity: data.quantity },
    {}
  );

  // تحديث البيانات عند تغيير `data`
  useEffect(() => {
    setFormData({
      color: +data?.color,
      size: +data?.size,
      quantity: +data.quantity,
    });
  }, [data]);

  useEffect(() => {
    if (
      formData.color !== +data?.color ||
      formData.size !== +data?.size ||
      formData.quantity !== +data.quantity
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [formData]);

  // التحكم بالكمية
  const decrementQuantity = () => {
    setFormData({
      ...formData,
      quantity: Math.max(1, formData.quantity - 1),
    });
  };
  const incrementQuantity = () => {
    setFormData({
      ...formData,
      quantity: Math.min(
        data?.product?.quantity || Infinity,
        formData.quantity + 1
      ),
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4 mb-4 flex flex-col sm:flex-row items-center sm:items-start gap-4">
      {/* صورة المنتج */}
      <div className="w-24 h-24 flex-shrink-0">
        <img
          src={storageUrl + data?.product?.file_main}
          alt={data?.product?.name}
          className="w-full h-full object-contain rounded-md"
        />
      </div>

      {/* تفاصيل المنتج */}
      <div className="flex flex-col w-full">
        <div className="text-lg font-semibold text-gray-800">
          {data?.product?.name}
        </div>

        {/* خيارات اللون */}
        <div className="flex items-center gap-2 mt-2">
          {data?.product?.color?.map((color) => (
            <div
              key={color.id}
              onClick={() => setFormData({ ...formData, color: color.id })}
              className={`w-6 h-6 rounded-full cursor-pointer border ${
                formData.color === color.id ? "border-black" : "border-gray-300"
              }`}
              style={{ backgroundColor: color.name }}
            ></div>
          ))}
        </div>

        {/* خيارات الحجم */}
        <div className="flex gap-2 mt-2">
          {data?.product?.size?.map((size) => (
            <div
              key={size.id}
              onClick={() => setFormData({ ...formData, size: size.id })}
              className={`text-xs px-2 py-1 border rounded-md cursor-pointer ${
                formData.size === size.id
                  ? "bg-black text-white"
                  : "border-gray-300 text-gray-600"
              }`}
            >
              {size.name}
            </div>
          ))}
        </div>

        {/* التحكم بالكمية */}
        <div className="flex items-center gap-4 mt-3">
          <button
            onClick={decrementQuantity}
            className="w-8 h-8 flex justify-center items-center border rounded-full text-gray-800 hover:bg-gray-100"
          >
            -
          </button>
          <span className="text-lg font-semibold">{formData.quantity}</span>
          <button
            onClick={incrementQuantity}
            className="w-8 h-8 flex justify-center items-center bg-black text-white rounded-full hover:bg-gray-800"
          >
            +
          </button>
        </div>

        {/* السعر والإجراءات */}
        <div className="mt-4 flex items-center justify-between">
          <div className="text-lg font-semibold text-gray-800">
            ${data?.product?.price}
          </div>
          <div className="flex items-center gap-2">
            {isChanged && (
              <button
                onClick={() => handleSubmit(`cart/${data?.id}`, "refreshData")}
                className="px-4 py-1 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600"
              >
                {content["Save"]}
              </button>
            )}
            <AiFillDelete
              size={24}
              className="text-red-500 cursor-pointer hover:text-red-700"
              onClick={() => deleteItem(data.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileCardCart;
