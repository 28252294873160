import { Link } from "react-router-dom";

const P500 = () => {
  return (
    <h1
      style={{ direction: "ltr" }}
      className="grid place-content-center h-[80vh] text-Brown font-semibold text-lg"
    >
      500 | Internal Server Error
      <Link to="/" className="hover:text-Main">
        Go to Home
      </Link>
    </h1>
  );
};

export default P500;
