import React from "react";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";

const CardOrder = ({ data }) => {
  return (
    <div className="flex gap-2 p-2 border border-Main rounded-md">
      <div className="w-[150px] h-full flex-center rounded-md overflow-hidden">
        <img
          src={storageUrl + data.file_main}
          alt=""
          className="object-contain w-full h-full"
        />
      </div>
      <div className="flex-1">
        <div className="flex justify-between items-center">
          <div className="text-lg font-semibold">{data.price}</div>
          <div className="text-Main text-lg font-bold">
            {content["Quantity"]} x{data.quantity}
          </div>
        </div>
        <div className="text-xl font-semibold">{data.name}</div>
        <div className="text-Third">
          {data.color}, {data.size}
        </div>
        <div className="text-sm text-Third">
          {data?.description?.substring(0, 200) + " ..."}
        </div>
      </div>
    </div>
  );
};

export default CardOrder;
