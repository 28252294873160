import React, { useEffect, useState } from "react";
import { usePost } from "../../contexts/usePost";
import { storageUrl } from "../../config";
import { useContextData } from "../../contexts/ContextData";
import { SelectApi } from "../../components";
import { content } from "../../contexts/useLang";

const StoreProfile = () => {
  const { profile } = useContextData();
  const [isEditing, setIsEditing] = useState(false);
  const {
    formData,
    handleChangeInput,
    handleChangeSelect,
    handleChangeFile,
    handleSubmit,
    setFormData,
  } = usePost(
    {
      name: "",
      phone: "",
      country: "",
      city: "",
      post_code: "",
      street_name: "",
      house_number: "",
      tax_id: "",
      CR_id: "",
      description: "",
      store_name: "",
      store_image: "",
      store_bg: "",
    },
    {
      name: ["required"],
      phone: ["required"],
      country: ["required"],
      city: ["required"],
      post_code: ["required"],
      street_name: ["required"],
      house_number: ["required"],
      tax_id: ["required"],
      CR_id: ["required"],
      description: ["required"],
      store_name: ["required"],
    }
  );
  useEffect(() => {
    if (profile) {
      setFormData({
        name: profile?.name,
        phone: profile?.phone,
        country: profile?.country,
        city: profile?.city,
        post_code: profile?.post_code,
        street_name: profile?.street_name,
        house_number: profile?.house_number,
        maximum_price: profile?.maximum_price,
        delivery_price: profile?.delivery_price,
        tax_id: profile?.tax_id,
        CR_id: profile?.CR_id,
        description: profile?.description,
        store_name: profile?.store_name,
      });
    }
  }, [profile]);

  const toggleEditMode = () => setIsEditing(!isEditing);

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-xl font-bold">
          {formData.name} <br /> {profile?.email}
        </h1>
        <button
          onClick={toggleEditMode}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          {isEditing ? "Cancel" : "Edit Profile"}
        </button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="text-sm text-Third">{content["Name"]}</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["Store Name"]}</label>
          <input
            type="text"
            name="store_name"
            value={formData.store_name}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div className="col-span-2">
          <label className="text-sm text-Third">{content["Description"]}</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          ></textarea>
        </div>
        <div>
          <SelectApi
            data={{
              xs: 11,
              title: content["Country"],
              onChange: handleChangeSelect,
              inputType: "selectApi",
              name: "country",
              type: "text",
              api: "coins",
              disabled: !isEditing,
            }}
            value={formData?.country}
            onChange={handleChangeInput}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["City"]}</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["Post code"]}</label>
          <input
            type="text"
            name="post_code"
            value={formData.post_code}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["Street name"]}</label>
          <input
            type="text"
            name="street_name"
            value={formData.street_name}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">
            {content["House number"]}
          </label>
          <input
            type="text"
            name="house_number"
            value={formData.house_number}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["Phone"]}</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>

        <div>
          <label className="text-sm text-Third">
            {content["Maximum price"]}
          </label>
          <input
            type="text"
            name="maximum_price"
            value={formData.maximum_price}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">
            {content["Delivery price"]}
          </label>
          <input
            type="text"
            name="delivery_price"
            value={formData.delivery_price}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["Tax ID"]}</label>
          <input
            type="text"
            name="tax_id"
            value={formData.tax_id}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        <div>
          <label className="text-sm text-Third">{content["CR ID"]}</label>
          <input
            type="text"
            name="CR_id"
            value={formData.CR_id}
            onChange={handleChangeInput}
            disabled={!isEditing}
            className={`border p-2 w-full ${
              isEditing ? "bg-white" : "bg-gray-100"
            } rounded-md`}
          />
        </div>
        {/* Store Image */}
        <div>
          <label className="text-sm text-Third">
            {content["Business logo"]}
          </label>
          {profile?.store_image && (
            <img
              src={storageUrl + profile?.store_image}
              alt="Store"
              className="w-full h-32 object-contain rounded-md mb-2"
            />
          )}
          <input
            type="file"
            name="store_image"
            onChange={handleChangeFile}
            disabled={!isEditing}
            className="border p-2 w-full rounded-md"
          />
        </div>
        {/* Store Background */}
        <div>
          <label className="text-sm text-Third">{content["Cover photo"]}</label>
          {profile?.store_bg && (
            <img
              src={storageUrl + profile?.store_bg}
              alt="Background"
              className="w-full h-32 o object-contain rounded-md mb-2"
            />
          )}
          <input
            type="file"
            name="store_bg"
            onChange={handleChangeFile}
            disabled={!isEditing}
            className="border p-2 w-full rounded-md"
          />
        </div>
        {isEditing && (
          <div className="col-span-2 text-right">
            <button
              onClick={() => handleSubmit("update-profile-seller")}
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-md"
            >
              {content["Save Changes"]}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreProfile;
