import React from "react";

const Container = ({ children }) => {
  return (
    <section className="">
      <div className="w-[96%] max-lg:w-full max-sm:w-[98%] mx-auto">
        {children}
      </div>
    </section>
  );
};

export default Container;
