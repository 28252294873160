// import JoditEditor from "jodit-react";
import TitleInput from "../Titles/TitleInput";
import { Col } from "../GridSystem";

function HTMLEditor({ value, data }) {
  return (
    <Col lg={data.lg || 3} md={data.md || 4} xs={data.xs || 6}>
      <TitleInput title={data?.title} isOptional={data.isOptional} />
      {/* <JoditEditor value={value} onChange={data.onChange} /> */}
    </Col>
  );
}
export default HTMLEditor;
