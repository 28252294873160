import React from "react";

const Row = ({ children, className, gap, justify }) => {
  return (
    <div
      className={`row
           ${gap ? "gap-" + gap : ""}
           ${justify ? "justify-" + justify : ""}
           ${className ? `${className} ` : ""}`}
    >
      {children}
    </div>
  );
};
export default Row;
