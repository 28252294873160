import { default as ReactSelect } from "react-select";
import { findItem } from "../../contexts/functions";
import { Col } from "../GridSystem";

const Select = ({ data, value }) => {
  const optionsSelect = data.options;
  return (
    <Col md={data.xs || data.md || 4} xs={data.xs || 6}>
      <div className="text-sm text-Third">{data?.title}</div>
      <ReactSelect
        value={
          (value &&
            findItem(optionsSelect, "id", value)?.map((q) => {
              return { value: q.id, label: q.name };
            })[0]) ||
          null
        }
    
        isLoading={data?.isLoading}
        onChange={data.onChange}
        name={data.name}
        className="mt-2"
        options={optionsSelect?.map((e) => {
          return { value: e.id, label: e.name };
        })}
      />
    </Col>
  );
};

export default Select;
