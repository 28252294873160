import { Link } from "react-router-dom";
import { content } from "../../contexts/useLang";

function TitleSections({ title, link }) {
  return (
    <div className="flex justify-between items-center px-10 mb-5">
      <div className="text-2xl font-semibold">{title}</div>
      <Link to={link || ""} className="text-Third text-xl  font-semibold">
        {content["View All"]}
      </Link>
    </div>
  );
}
export default TitleSections;
