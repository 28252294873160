import React from "react";
import ButMain from "../Buttons/ButMain";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";

const CardOrders = ({ data }) => {
  return (
    <div className="border rounded-md p-3">
      <div className="flex items-start justify-between gap-3">
        <div className="flex items-center gap-3">
          <div>
            <img
              src={storageUrl + data.seller?.store_image}
              alt=""
              className="w-[70px] h-[70px] rounded-full"
            />
          </div>
          <div>
            <div className="font-semibold">{data.seller?.store_name}</div>
            <div className="text-Main text-sm">
              {" "}
              {data.items} {content["Items"]}
            </div>
          </div>
        </div>
        <div className="text-Main text-sm">#{data.id}</div>
      </div>
      <div className="flex justify-between my-4">
        <div>
          <div>{content["Order status"]}:</div>

          <div
            className={`font-semibold ${
              data.status === "pending"
                ? "text-yellow-500"
                : data.status === "shipped"
                ? "text-orange-500"
                : data.status === "completed"
                ? "text-green-800"
                : "text-gray-500"
            }`}
          >
            {data.status === "pending"
              ? content["Waiting for shipping"]
              : data.status === "shipped"
              ? content["Shipped"]
              : data.status === "completed"
              ? content["Completed"]
              : content["Unknown Status"]}
          </div>
        </div>
        <div>
          <div>{content["Date"]}:</div>

          <div>{data.date}</div>
        </div>
        <div>
          <div>{content["Total Price"]}:</div>
          <div>{data.total_price}</div>
        </div>
      </div>
      <ButMain
        link={"/orders/" + data.id}
        name={content["View Order Details"]}
        className="text-center"
      />
    </div>
  );
};

export default CardOrders;
