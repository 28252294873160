import React from "react";
import { ButMain, CardAuth, Col, InputsPage, Row } from "../../components";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";

const CreateSellerP2 = () => {
  const { handleChangeInput, handleSubmit, handleChangeFile, loadingPost } =
    usePost(
      {
        store_name: sessionStorage.getItem("store_name"),
        country: sessionStorage.getItem("country"),
        city: sessionStorage.getItem("city"),
        post_code: sessionStorage.getItem("post_code"),
        street_name: sessionStorage.getItem("street_name"),
        house_number: sessionStorage.getItem("house_number"),
        front_id_card: "",
        back_id_card: "",
        tax_id: "",
        CR_id: "",
        description: "",
      },
      {
        store_name: ["required"],
        country: ["required"],
        city: ["required"],
        post_code: ["required"],
        street_name: ["required"],
        house_number: ["required"],
        front_id_card: ["required"],
        back_id_card: ["required"],
        tax_id: ["required"],
        CR_id: ["required"],
        description: ["required"],
      }
    );

  return (
    <CardAuth title={content["Create Seller Account"]}>
      <Row justify="center" className="space-y-1">
        <Col xs={11} className="text-Main">
          {content["Second Step: Provide your basic company info"]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/2 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/2 h-[5px] bg-Main rounded-full"></div>
        </Col>
        {[
          {
            xs: 11,
            title: content["Upload a photo of the front of your ID card"],
            onChange: handleChangeFile,
            inputType: "input",
            name: "front_id_card",
            type: "file",
          },
          {
            xs: 11,
            title: content["Upload a photo of the back of your ID card"],
            onChange: handleChangeFile,
            inputType: "input",
            name: "back_id_card",
            type: "file",
          },
          {
            xs: 11,
            title: content["Enter TAX number "],
            onChange: handleChangeInput,
            inputType: "input",
            name: "tax_id",
            type: "text",
          },
          {
            xs: 11,
            title: content["Enter KVK number "],
            onChange: handleChangeInput,
            inputType: "input",
            name: "CR_id",
            type: "text",
          },
          {
            xs: 11,
            title: content["Describe your business"],
            onChange: handleChangeInput,
            inputType: "textarea",
            name: "description",
            type: "text",
          },
        ].map((e, i) => (
          <InputsPage key={i} data={e} />
        ))}

        <Col xs={11} className=" ">
          <ButMain
            onClick={() =>
              handleSubmit("sign-up-seller", "refreshData-route", "/")
            }
            loadingPost={loadingPost}
            name={content["Next"]}
            className="text-center py-3 text-xl"
          />
        </Col>
      </Row>
    </CardAuth>
  );
};

export default CreateSellerP2;
