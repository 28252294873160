import React from "react";
import { Col, Container, PageLoading, Row, TitlePage } from "../../components";
import { useFetch } from "../../contexts/useFetch";
import CardAddress from "../../components/Card/CardAddress";
import { Link } from "react-router-dom";
import { MdWrongLocation } from "react-icons/md";
import { content } from "../../contexts/useLang";
const Address = () => {
  const { data, deleteItem, loading } = useFetch("addresses");
  return (
    <div>
      {loading ? (
        <PageLoading />
      ) : (
        <Container>
          <TitlePage
            notPage={true}
            title={[content["Profile"], content["My Addresses"]]}
          />
          <Row className="flex justify-between items-center">
            <Col md={4} />
            <Col md={4} className="p-2">
              <div className="text-center text-3xl font-semibold my-4">
                {content["My Addresses"]}
              </div>
            </Col>
            <Col md={4} className="p-2">
              <Link
                to="/address/add"
                className="block text-Main text-lg font-semibold text-end"
              >
                + {content["Add New Address"]}
              </Link>
            </Col>
          </Row>
          <Row>
            {data && data.length > 0 ? (
              data.map((e) => (
                <Col key={e.id} md={4} className="p-2">
                  <CardAddress data={e} deleteItem={deleteItem} />
                </Col>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center p-5 text-center text-gray-600">
                <MdWrongLocation className="w-16 h-16 text-gray-400" />

                <p className="mt-4 text-lg font-semibold">
                  {content["There are no addresses currently"]}
                </p>
                <p className="text-sm text-gray-500">
                  {content["Please add a new address to continue."]}
                </p>
              </div>
            )}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default Address;
