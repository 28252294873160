import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { ButMain, CardAuth, Col, Row } from "../../components";
import { useLogin } from "../../contexts/useLogin";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";
const Verification = () => {
  const { handleSubmit, formData, setFormData, loadingPost } = useLogin(
    { email: sessionStorage.getItem("email") },
    { verification_code: ["required"] }
  );
  const { handleSubmit: handleSubmitResendCode } = usePost(
    {
      email: sessionStorage.getItem("email"),
    },
    {}
  );
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);
  return (
    <CardAuth title={content["Create Customer Account"]}>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-Main">
          {content["Second Step: Verification code sent to your email"]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/2 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/2 h-[5px] bg-Main rounded-full"></div>
        </Col>
        <Col xs={11} className="text-center !mb-10">
          {!canResend ? (
            <p>
              {content["Please wait"]} {timer}{" "}
              {content["seconds before trying again."]}
            </p>
          ) : (
            <p
              className="text-Main"
              onClick={() =>
                handleSubmitResendCode("sign-up/resend-code", "reload")
              }
            >
              {content["You can resend the code now."]}
            </p>
          )}
        </Col>
        <Col xs={11} className="text-center !mb-10 ">
          <OTPInput
            value={formData?.verification_code}
            onChange={(e) => setFormData({ ...formData, verification_code: e })}
            numInputs={6}
            inputStyle="!w-10 bg-Light border rounded-md h-14 !w-14 mx-2 px-3 drop-shadow-2xl"
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />
        </Col>

        <Col xs={11} className=" ">
          <ButMain
            onClick={() => handleSubmit("sign-up/verify", "reload")}
            name={content["Verify"]}
            loadingPost={loadingPost}
            className="text-center py-3 text-xl"
          />
        </Col>
      </Row>
    </CardAuth>
  );
};

export default Verification;
