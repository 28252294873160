import React from "react";
import {
  CardProducts,
  Col,
  Container,
  PageLoading,
  Pagination,
  Row,
  TitlePage,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { IoHeartDislikeOutline } from "react-icons/io5";
import { useFilter } from "../../contexts/useFilter";
import { content } from "../../contexts/useLang";

const Favorites = () => {
  const { searchAll } = useFilter();
  const { data, loading } = useFetch("favorites" + searchAll);
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <Container>
          <TitlePage
            title={[
              content["Home"],
              content["My Profile"],
              content["Favorites"],
            ]}
          />
          <Row className="mt-5">
            {data?.data?.length ? (
              data?.data?.map((e, i) => (
                <Col md={3} key={i} className="pb-3">
                  <CardProducts key={e.id} data={e?.product} />
                </Col>
              ))
            ) : (
              <div className="h-[50vh] flex-center text-center py-5 ">
                <div>
                  <IoHeartDislikeOutline
                    size={50}
                    className="text-gray-400 mb-3 mx-auto"
                  />
                  <p className=" text-gray-500">
                    {content["No favorite products available"]}
                  </p>
                </div>
              </div>
            )}
          </Row>
          <Pagination data={data} />
        </Container>
      )}
    </>
  );
};

export default Favorites;
