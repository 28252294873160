import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  FaCartPlus,
  FaEye,
  GoHeart,
  GoHeartFill,
  RiArrowGoBackFill,
} from "../../assets/icons";
import {
  ButMain,
  CardProducts,
  CardStar,
  Col,
  Container,
  PageLoading,
  PopUpRate,
  Row,
  TitlePage,
} from "../../components";
import { storageUrl } from "../../config";
import { useContextState } from "../../contexts/ContextState";
import { useFetch } from "../../contexts/useFetch";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";

const Product = () => {
  const { id } = useParams();
  const { data, loading } = useFetch("products/" + id);
  const [isFavorite, setIsFavorite] = useState(false);
  const [imageMain, setImageMain] = useState(0);
  const { setOpenPopUpRate } = useContextState();
  const {
    handleSubmit: handleSubmitToCart,
    setFormData,
    formData,
    successPost: successPostCart,
  } = usePost({ color: "", size: "", quantity: 1 }, {});
  const { handleSubmit, successPost } = usePost({}, {});
  useEffect(() => {
    setFormData({
      color: data?.product?.color?.[0]?.id,
      size: data?.product?.size?.[0]?.id,
      quantity: 1,
    });
    if (!successPostCart) {
      setIsFavorite(data?.is_favorite);
    }
  }, [data]);
  useEffect(() => {
    if (successPost) {
      setIsFavorite(!isFavorite);
    }
  }, [successPost]);
  // تقليل الكمية
  const decrementQuantity = () => {
    setFormData({
      ...formData,
      quantity: Math.max(1, formData.quantity - 1),
    });
  };

  // زيادة الكمية
  const incrementQuantity = () => {
    setFormData({
      ...formData,
      quantity: Math.min(
        data?.product?.quantity || Infinity,
        formData.quantity + 1
      ),
    });
  };
  // تحديث اللون
  const updateColor = (colorId) => {
    setFormData({
      ...formData,
      color: colorId,
    });
  };

  // تحديث الحجم
  const updateSize = (sizeId) => {
    setFormData({
      ...formData,
      size: sizeId,
    });
  };

  return (
    <>
      {!loading ? (
        <div>
          <PopUpRate type="product" id={id} />
          <Container>
            <TitlePage
              notPage={true}
              title={[
                content["Home"],
                content["All Sections"],
                data?.product?.section?.name,
                data?.product?.category?.name,
                content["Product Details"],
              ]}
            />
            <Row>
              <Col
                md={1}
                className="space-y-1 max-md:flex items-center gap-2 pb-2"
              >
                {data?.product?.files?.map((e, i) => (
                  <div
                    onClick={() => setImageMain(i)}
                    key={i}
                    className={`${
                      imageMain === i ? "border-2 border-Main box-border" : ""
                    } h-[70px] max-md:w-[70px] md:h-1/5 bg-[#E5E5E5] flex-center rounded-md overflow-hidden`}
                  >
                    <img
                      src={storageUrl + e.file}
                      alt=""
                      className="object-contain "
                    />
                  </div>
                ))}
              </Col>
              <Col md={5} className="">
                <div className="md:h-[500px] h-[250px] flex-center">
                  <img
                    src={storageUrl + data?.product?.files[imageMain]?.file}
                    alt=""
                    className="h-full w-full object-contain"
                  />
                </div>
              </Col>
              <Col md={6} className="">
                <div>
                  <div>
                    <div className="flex items-start justify-between ">
                      <div className="flex-1">
                        <div className="flex items-center text-Third gap-1 ">
                          <FaEye />
                          <div>{data?.product?.views}</div>
                        </div>
                        <div className="flex gap-3 items-center">
                          <div className="flex g text-yellow-300 items-center">
                            <CardStar
                              rating={{
                                rating: data?.product?.average_rating || 0,
                              }}
                              isReadOnly={true}
                              size={15}
                            />
                          </div>
                          <div className="text-Third">
                            {data?.product?.reviews_count} {content["reviews"]}{" "}
                          </div>
                          <div
                            onClick={() => setOpenPopUpRate(true)}
                            className="text-Main underline "
                          >
                            {content["Submit Your Rate"]} ?
                          </div>
                        </div>
                        <div className="w-full text-3xl font-semibold ">
                          {data?.product?.name}
                        </div>
                        <div className="flex items-center gap-2 text-2xl my-1">
                          <div>{data?.product?.price}</div>
                          {data?.product?.discount !== "0.00" ? (
                            <div className="text-xl  text-Third relative line-through">
                              {data?.product?.discount}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="my-2 flex items-center gap-5">
                      <div className="w-[70px] h-[70px] overflow-hidden rounded-full">
                        <img
                          src={storageUrl + data?.product?.user?.store_image}
                          alt=""
                          className=""
                        />
                      </div>
                      <div>
                        <div className="text-xl font-semibold">
                          {data?.product?.user?.store_name}
                        </div>

                        <Link
                          to={"/sellers/" + data?.product?.user?.username}
                          className="text-Main flex items-center gap-1"
                        >
                          <RiArrowGoBackFill
                            className="-scale-x-100"
                            size={18}
                          />
                          <div>{content["Go to the Store Page"]}</div>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="text-lg font-semibold ">
                        {content["Description"]}
                      </div>
                      <div className="text-sm min-h-[80px]">
                        {data?.product?.description}
                      </div>
                    </div>
                    <div>
                      <div className="text-lg font-semibold">
                        {content["Colors"]}
                      </div>
                      <div className="flex flex-wrap gap-2">
                        {data?.product?.color?.length ? (
                          data?.product?.color?.map((e, i) => (
                            <div
                              onClick={() => updateColor(e.id)}
                              key={i}
                              style={{ borderColor: e.name }}
                              className={`transition-all rounded-md w-[75px] h-7 overflow-hidden ${
                                +e.id === +formData?.color
                                  ? "border"
                                  : "hover:border"
                              }`}
                            >
                              <div
                                style={{ backgroundColor: e.name }}
                                className={`${
                                  +e.id === +formData?.color
                                    ? "scale-90"
                                    : "hover:scale-90"
                                } transition-all w-full h-full  rounded-md`}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="h-7 text-Third">
                            {content["There are no colors"]}
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-lg font-semibold ">
                        {content["Size"]}
                      </div>
                      <div className="flex items-center gap-5 my-1">
                        {data?.product?.size?.length ? (
                          data?.product?.size?.map((e, i) => (
                            <div
                              onClick={() => updateSize(e.id)}
                              key={i}
                              className={`${
                                +e.id === +formData?.size
                                  ? "bg-black text-Light"
                                  : "hover:bg-black hover:text-Light"
                              } bg-Third/30 w-9 h-9 rounded-md flex-center`}
                            >
                              {e.name}
                            </div>
                          ))
                        ) : (
                          <div className="h-7 text-Third">
                            {content["There are no sizes"]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Row className=" mt-5 items-center">
                  <Col className="p-1" md={3} sm={4}>
                    <div className="flex-center gap-3 border rounded-md select-none">
                      <div
                        onClick={decrementQuantity}
                        className="text-2xl flex-center w-8 h-8 cursor-pointer"
                      >
                        -
                      </div>
                      {formData.quantity || data.quantity}
                      <div
                        onClick={incrementQuantity}
                        className="text-2xl flex-center w-8 h-8 cursor-pointer"
                      >
                        +
                      </div>
                    </div>
                  </Col>
                  <Col className="p-1" md={8} sm={7}>
                    <ButMain
                      onClick={() =>
                        handleSubmitToCart(
                          "cart?product_id=" + id,
                          "refreshData-route",
                          "/cart/" + data?.product?.user?.username
                        )
                      }
                      name={
                        <div className="flex-center gap-4 text-lg">
                          <FaCartPlus />
                          <div>{content["Add To Cart"]}</div>
                        </div>
                      }
                    />
                  </Col>
                  <Col className="p-1" col={1}>
                    <div>
                      {isFavorite ? (
                        <div
                          onClick={() =>
                            handleSubmit(
                              "favorites/remove?product_id=" + id,
                              "refresh"
                            )
                          }
                          className="bg-Third/30 w-9 h-9 flex-center rounded-lg text-red-600 mx-auto"
                        >
                          <GoHeartFill size={25} />
                        </div>
                      ) : (
                        <div
                          onClick={() =>
                            handleSubmit(
                              "favorites/add?product_id=" + id,
                              "refresh"
                            )
                          }
                          className="bg-Third/30 w-9 h-9 flex-center rounded-lg text-black/90 mx-auto"
                        >
                          <GoHeart size={25} />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr className="md:hidden" />
            <br />
            <SimilarProducts data={data?.similar_products?.data} />
          </Container>
        </div>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default Product;
const SimilarProducts = ({ data }) => {
  return (
    <>
      <div className="my-5 text-2xl font-semibold">
        {content["Similar products"]}
      </div>
      <Row>
        {data?.map((e, i) => (
          <Col key={i} md={20} className="pb-3">
            <CardProducts key={e.id} data={e} />
          </Col>
        ))}
      </Row>
    </>
  );
};
