import React from "react";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CardCategories,
  CardProducts,
  CardSellers,
  CardSellersPage,
  Col,
  Container,
  PageLoading,
  Row,
  TitleSections,
} from "../../components";
import { storageUrl, token } from "../../config";
import { useFetch } from "../../contexts/useFetch";
import { content } from "../../contexts/useLang";

const Home = () => {
  const { data, loading } = useFetch("data-home");
  const { data: following } = useFetch(token ? "following" : "");
  return (
    <div>
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <Swiper
            pagination={true}
            modules={[Pagination, Autoplay]}
            loop={true}
            speed={1000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className="mySwiper max-h-[75vh] max-sm:max-h-[40vh]"
            style={{ direction: "ltr" }}
          >
            {data?.images?.map((e, i) => (
              <SwiperSlide key={i} className="w-full h-[50vh] bg-slate-50">
                {e?.link ? (
                  <img
                    onClick={() => (window.location.href = e?.link)}
                    src={storageUrl + e.image}
                    alt=""
                    className="object-contain w-full h-full"
                  />
                ) : (
                  <img
                    src={storageUrl + e.image}
                    alt=""
                    className="object-contain w-full h-full"
                  />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <br />
          <Container>
            {following?.data?.length ? (
              <>
                <TitleSections link="/following" title={content["Following"]} />
                <Row>
                  {following?.data?.map((e, i) => (
                    <Col key={i} md={3} className="p-2">
                      <CardSellersPage data={e} />
                    </Col>
                  ))}
                </Row>
                <br />
              </>
            ) : (
              ""
            )}

            <TitleSections link="/categories" title={content["Sections"]} />
            <Row>
              {data?.sections?.data.map((e, i) => (
                <Col key={i} md={20} sm={6} className="pb-3">
                  <CardCategories
                    key={e.id}
                    id={e.id}
                    image={e.image}
                    name={e.name}
                  />
                </Col>
              ))}
            </Row>
            <br />
            <TitleSections link="/sellers" title={content["Sellers"]} />
            <Swiper
              className=""
              style={{ direction: "ltr" }}
              slidesPerView={7.5}
              breakpoints={{
                1200: {
                  slidesPerView: 7.5,
                },
                1024: {
                  slidesPerView: 6.5,
                },
                768: {
                  slidesPerView: 4.5,
                },
                480: {
                  slidesPerView: 3.5,
                },
                320: {
                  slidesPerView: 2.5,
                },
                0: {
                  slidesPerView: 1.5,
                },
              }}
            >
              {data?.sellers?.data?.map((e, i) => (
                <SwiperSlide key={i}>
                  <CardSellers
                    key={e.id}
                    username={e.username}
                    image={e.store_image}
                    name={e.store_name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <br />
            <TitleSections
              link="/products?sort_by=new"
              title={content["Latest products"]}
            />
            <Row>
              {data?.latest_products?.data?.map((e, i) => (
                <Col key={i} md={20} className="pb-3">
                  <CardProducts key={e.id} data={e} isNotDiscount={true} />
                </Col>
              ))}
            </Row>

            <br />
            <TitleSections
              link="/products?sort_by=discount"
              title={content["Offers"]}
            />
            <Row>
              {data?.offers_products?.data?.map((e, i) => (
                <Col key={i} md={20} className="pb-3">
                  <CardProducts key={e.id} data={e} />
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default Home;
