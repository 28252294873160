import React from "react";
import OTPInput from "react-otp-input";
import { ButMain, CardAuth, Col, Row } from "../../components";
import { useLogin } from "../../contexts/useLogin";
import { content } from "../../contexts/useLang";

const PassResetVerify = () => {
  const { handleSubmit, formData, setFormData, loadingPost } = useLogin(
    {
      email: "",
      otp: "",
    },
    {
      email: ["required", "email"],
      otp: ["required", "length:5"],
    }
  );

  return (
    <CardAuth title={content["Reset Your Password"]}>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-Main">
          {content["Second Step: Enter the code sent to your email."]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/3 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/3 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/3 h-[5px] bg-Third/30 rounded-full"></div>
        </Col>

        <Col xs={11} className="text-center !mb-10 ">
          <OTPInput
            value={formData?.token}
            onChange={(e) => setFormData({ ...formData, token: e })}
            numInputs={6}
            inputStyle="!w-10 bg-Light border rounded-md h-14 !w-14 mx-2 px-3 drop-shadow-2xl"
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />
        </Col>

        <Col xs={11} className=" ">
          <ButMain
            onClick={() =>
              handleSubmit(
                "password-reset/verify",
                "route",
                "/auth/p-reset/reset"
              )
            }
            loadingPost={loadingPost}
            name={content["Verify"]}
            className="text-center py-3 text-xl"
          />
        </Col>
      </Row>
    </CardAuth>
  );
};

export default PassResetVerify;
