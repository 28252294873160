import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Container, PageLoading, Pagination, Select } from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { content } from "../../contexts/useLang";

const SellerOrders = () => {
  const { searchAll, searchParams, handleParamsAdd, handleParamsRemove } =
    useFilter();
  const { data, loading } = useFetch("orders" + searchAll);
  const navigate = useNavigate();
  if (loading) {
    return <PageLoading />;
  }
  return (
    <div>
      <Container>
        <div className=" text-3xl font-semibold my-4">
          {content["My Orders"]}
        </div>
        <br />
        {data?.data?.length ? (
          <>
            {/* Filters */}
            <div className="mb-4">
              <Select
                data={{
                  options: [
                    { id: "", name: content["All"] },
                    { id: "pending", name: content["Waiting for shipping"] },
                    { id: "shipped", name: content["Shipped"] },
                    { id: "completed", name: content["Completed"] },
                  ],
                  onChange: (selected) =>
                    selected.value
                      ? handleParamsAdd("status", selected.value)
                      : handleParamsRemove("status"),
                }}
                value={searchParams.get("status")}
              />
            </div>

            {/* Table */}
            <div className="overflow-x-scroll ">
              <table className="max-md:min-w-[750px] w-full bg-white border border-gray-200">
                <thead>
                  <tr className="bg-gray-100 text-left text-sm font-medium text-gray-700">
                    <th className="py-2 px-4 border-b">{content["ID"]}</th>
                    <th className="py-2 px-4 border-b">
                      {content["Customer"]}
                    </th>
                    <th className="py-2 px-4 border-b">
                      {content["Delivery Time"]}{" "}
                    </th>
                    <th className="py-2 px-4 border-b">{content["Address"]}</th>
                    <th className="py-2 px-4 border-b">{content["Total"]}</th>
                    <th className="py-2 px-4 border-b">{content["Date"]}</th>
                    <th className="py-2 px-4 border-b">{content["Status"]}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((order, index) => (
                    <tr
                      onClick={() => navigate("/dashboard/orders/" + order.id)}
                      key={index}
                      className="text-sm text-gray-700"
                    >
                      <td className="py-3 px-4 border-b">{order.id}</td>
                      <td className="py-3 px-4 border-b">
                        {order?.user?.name || "N/A"}
                      </td>
                      <td className="py-3 px-4 border-b">
                        {order.delivery_time + " Days"}
                      </td>
                      <td className="py-3 px-4 border-b">
                        {order.address?.country}, {order.address?.street_number}
                        ,{order.address?.building_number},
                        {order.address?.additional_details}
                      </td>
                      <td className="py-3 px-4 border-b">
                        {order.final_total || "0.00"}
                      </td>
                      <td className="py-3 px-4 border-b">{order.date}</td>

                      <td
                        className={`py-3 px-4 border-b font-semibold ${
                          order.status === "pending"
                            ? "text-yellow-500"
                            : order.status === "shipped"
                            ? "text-orange-500"
                            : order.status === "completed"
                            ? "text-green-800"
                            : "text-gray-500"
                        }`}
                      >
                        {order.status === "pending"
                          ? "Waiting for shipping"
                          : order.status === "shipped"
                          ? "Shipped"
                          : order.status === "completed"
                          ? "Completed"
                          : "Unknown Status"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center p-5 text-center text-gray-600">
            <AiOutlineShoppingCart className="w-16 h-16 text-gray-400" />
            <p className="mt-4 text-lg font-semibold">
              {content["No Orders Found"]}
            </p>
            <p className="text-sm text-gray-500">
              {content["Please add new orders to see them here."]}
            </p>
          </div>
        )}

        <Pagination data={data} />
      </Container>
    </div>
  );
};

export default SellerOrders;
