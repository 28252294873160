import React from "react";
import { useNavigate } from "react-router-dom";
import { AiFillDelete, FaEye } from "../../assets/icons";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";
import ButMain from "../Buttons/ButMain";
import CardStar from "./CardStar";

const CardSellProducts = ({ data, deleteItem }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full rounded-xl overflow-hidden shadow relative">
      <div onClick={() => navigate("/dashboard/products/view/" + data.id)}>
        {data.discount !== "0.00" && data.discount !== data.price ? (
          <div className="text-lg font-bold text-Light absolute top-2 right-2 rounded-full w-[42px] h-[42px] z-20 bg-Red flex-center">
            {data.discount_percentage + "%"}
          </div>
        ) : (
          ""
        )}
        <div className="h-[200px] flex-center">
          <img
            src={storageUrl + data.file_main}
            alt=""
            className="object-contain h-full w-full"
          />
        </div>
        <div className="space-y-2 p-2">
          <div className="flex justify-between items-center">
            <div className="flex g text-yellow-300 items-center">
              <CardStar
                rating={{ rating: data?.average_rating || 0 }}
                isReadOnly={true}
                size={15}
              />
              <div>{data.reviews_count}</div>
            </div>
            <div className="flex items-center text-Third gap-1 ">
              <FaEye />
              <div>{data.views}</div>
            </div>
          </div>
          <div className="w-full text-2xl font-semibold ">{data.name}</div>
          <div className="flex items-center gap-2">
            <div>{data.price}</div>
            <div className="text-sm  text-Third relative line-through">
              {data.discount !== "0.00" && data.discount !== data.price
                ? data.discount + ""
                : ""}
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 p-1">
        <ButMain
          name={content["Edit Product"]}
          link={"/dashboard/products/" + data.id}
          className="text-sm py-1.5 flex-1 text-center"
        />
        <ButMain
          onClick={() => deleteItem(data?.id, "products/" + data?.id)}
          name={<AiFillDelete size={25} />}
          className="text-white bg-Red !px-1 hover:bg-red-500"
        />
      </div>
    </div>
  );
};

export default CardSellProducts;
