import React from "react";
import { Col } from "../../components";
import { CiSearch } from "../../assets/icons";
import { useTimeout } from "../../contexts/useTimeout";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { storageUrl } from "../../config";
import { Link } from "react-router-dom";
import { content } from "../../contexts/useLang";
const Search = () => {
  const { searchParams } = useFilter();
  const { handelChangeTimeoutSearch } = useTimeout();
  const { data } = useFetch(
    searchParams.get("search")
      ? "search?query=" + searchParams.get("search")
      : ""
  );
  return (
    <Col md={2} className="relative">
      <div className="bg-Secondary/10 px-4 py-[7px] rounded-full flex items-center border b">
        <CiSearch size={20} className="text-Secondary/80" />
        <input
          onChange={handelChangeTimeoutSearch}
          type="search"
          placeholder={content["Search"]}
          className="bg-transparent px-2"
        />
      </div>
      {searchParams.get("search") && data.length ? (
        <div className="w-full max-h-[250px] h-fit absolute top-12 left-0 bg-Light z-50 border rounded-lg overflow-y-auto p-3">
          {data?.map((e, i) => (
            <>
              {e.file_main ? (
                <Link
                  to={"/products/" + e.id}
                  className="flex items-center gap-3 border-b py-1"
                >
                  <div className="w-14 h-14 rounded-full overflow-hidden">
                    <img
                      src={storageUrl + e.file_main}
                      alt=""
                      className="object-contain w-full h-full"
                    />
                  </div>
                  <div>
                    <div>{e.name}</div>
                    <div className="text-xs text-Third">
                      {e.description?.substring(0, 40) + "..."}
                    </div>
                  </div>
                </Link>
              ) : (
                <Link
                  to={"/sellers/" + e.username}
                  className="flex items-center gap-3 border-b py-1"
                >
                  <div className="w-14 h-14 rounded-full overflow-hidden">
                    <img
                      src={storageUrl + e.store_image}
                      alt=""
                      className="object-contain w-full h-full"
                    />
                  </div>
                  <div>
                    <div>{e.name}</div>
                    <div className="text-xs text-Third">
                      {content["Seller"]}
                    </div>
                  </div>
                </Link>
              )}
            </>
          ))}
        </div>
      ) : (
        ""
      )}
    </Col>
  );
};

export default Search;
