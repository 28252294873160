import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowRight } from "../../assets/icons";
import { ButMain } from "../../components";
import { storageUrl } from "../../config";
import { useFetch } from "../../contexts/useFetch";
import { content } from "../../contexts/useLang";

const Categories = () => {
  const navigate = useNavigate();
  const [section, setSection] = useState("");
  const [category, setCategory] = useState("");
  const [productDiscount, setProductDiscount] = useState({});
  const { data: dataSections } = useFetch("sections");
  const { data: dataCategories } = useFetch(
    section && "categories?section=" + section
  );
  const { data: dataProducts } = useFetch(
    section &&
      category &&
      "products?sort_by=new&category=" + category + "&section=" + section
  );
  const { data: dataProductsDiscount } = useFetch(
    section &&
      category &&
      "products?sort_by=discount&category=" + category + "&section=" + section
  );
  useEffect(() => {
    setSection(dataSections?.data?.[0]?.id);
  }, [dataSections]);

  useEffect(() => {
    setCategory(dataCategories?.[0]?.id);
  }, [dataCategories]);

  useEffect(() => {
    setProductDiscount(dataProductsDiscount?.data?.[0]);
  }, [dataProductsDiscount]);

  return (
    <div className=" relative top-5 z-40">
      {/* القائمة */}
      <div className="flex gap-4">
        {/* الأقسام */}
        <div className=" !bg-white w-[300px] z-40 border shadow-md rounded-lg py-5">
          {dataSections.data?.slice(0, 11).map((e) => (
            <div
              key={e.id}
              onClick={() => setSection(e.id)}
              className={`cursor-pointer py-2 px-3 text-Secondary 
                       ${section === e.id ? "bg-blue-50 font-semibold" : ""} 
                      hover:bg-blue-100 transition-colors duration-200 ease-in-out`}
            >
              {e.name}
            </div>
          ))}
        </div>
        <div className=" !bg-white w-[800px] z-40 border shadow-md rounded-lg py-5 flex px-5">
          <div className="w-1/3 space-y-2 mr-3">
            {dataCategories?.map((e) => (
              <div
                key={e.id}
                onClick={() => setCategory(e.id)}
                className={`cursor-pointer py-1 px-3 text-Secondary rounded-md ${
                  category === e.id ? "bg-blue-50 font-semibold" : ""
                } hover:bg-blue-50`}
              >
                {e.name}
              </div>
            ))}
          </div>
          <div className="space-y-5 mr-3">
            {dataProducts?.data?.slice(0, 4)?.map((e) => (
              <div
                key={e.id}
                onClick={() => navigate("/products/" + e.id)}
                className="cursor-pointer border p-2 flex gap-4 rounded-md hover:bg-yellow-50 min-w-[250px]"
              >
                <div className="w-[60px] flex-center">
                  <img
                    src={storageUrl + e.file_main}
                    alt=""
                    className="object-contain w-full h-full"
                  />
                </div>
                <div>
                  <div className="pb-2">{e.name}</div>
                  <div className="text-Main">{e.price}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-Main/50 p-4">
            <div className="cursor-pointer  text-center rounded-md space-y-2">
              <div className="h-[200px] flex-center">
                <img
                  src={storageUrl + productDiscount?.file_main}
                  alt=""
                  className="object-contain h-full w-full"
                />
              </div>
              <div className="text-2xl font-bold">
                {productDiscount?.discount_percentage}
                {content["% Discount"]}
              </div>
              <div>{productDiscount?.name}</div>
              <div className=" flex-center gap-1  ">
                <div className="">{content["Starting price"]}:</div>
                <div className="bg-white p-1">{productDiscount?.price}</div>
              </div>
              <div className="text-Main pt-3">
                <ButMain
                  onClick={() => navigate("/products/" + productDiscount?.id)}
                  name={
                    <div className="flex-center gap-1">
                      <div>{content["Shop now"]} </div>
                      <FiArrowRight size={20} />
                    </div>
                  }
                  className="py-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
