import React from "react";
import { Col, Container, Row } from "../GridSystem";
import CardLanguage from "./CardLanguage";
import { IoMdClose } from "../../assets/icons";
import { Link } from "react-router-dom";
import { logo } from "../../assets/images";

const CardAuth = ({ title, children }) => {
  return (
    <div className="py-2 bg-slate-50">
      <Container>
        <Row>
          <Col md={4}>
            <img src={logo} alt="" className="w-[200px] mb-2" />
            <CardLanguage />
          </Col>
          <Col md={5} className="min-h-[87vh] mt-10">
            <div className="bg-Light rounded-xl p-3 border h-full">
              <div>
                <Link to="/">
                  <IoMdClose />
                </Link>
              </div>
              <div className="text-center text-lg font-semibold mb-5">
                {title}
              </div>
              <div className="">{children}</div>
            </div>
          </Col>
          <Col md={3}></Col>
        </Row>
      </Container>
    </div>
  );
};

export default CardAuth;
