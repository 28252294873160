import { useEffect, useState } from "react";
import { useFilter } from "./useFilter";

export const useTimeout = () => {
  const { handleParamsAdd, handleParamsRemove, searchParams } = useFilter();
  const [typingTimeout, setTypingTimeout] = useState("");
  useEffect(() => {
    if (searchParams.get("search")) {
      handleParamsRemove("search");
    }
  }, []);

  const handelChangeTimeoutSearch = (e) => {
    const { value } = e.target;
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      if (value) handleParamsAdd("search", value);
      else handleParamsRemove("search");
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };

  return { handelChangeTimeoutSearch };
};
