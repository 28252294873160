import { language } from "../config";
import lang from "./lang.json";

export let content = {};
if (language === "ar") content = lang.validate.ar;
else if (language === "ru") content = lang.validate.ru;
else if (language === "sw") content = lang.validate.sw;
else if (language === "fr") content = lang.validate.fr;
else if (language === "ch") content = lang.validate.ch;
else if (language === "ge") content = lang.validate.ge;
else content = lang.validate.en;
