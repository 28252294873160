import axios from "axios";
import { useState } from "react";
import { baseUrl, language, token } from "../config";
import { useContextState } from "./ContextState";

export const useFetchClick = (url) => {
  const [data, setData] = useState([]);
  const { setError } = useContextState();

  const fetchData = async () => {
    axios
      .get(`${baseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          language: language,
        },
      })
      .then((response) => {
        if (response) {
          setData(response.data.data);
        }
      })
      .catch((e) => {
        for (const [key, value] of Object.entries(
          e?.response?.data?.errors || {}
        ))
          setError((preError) => [...preError, ...value]);
      });
  };

  return { data, fetchData };
};
