import React, { useEffect } from "react";
import { FaCaretDown, MdOutlineDone } from "../../assets/icons";
import { ar, ch, en, fr, ge, ru, sw } from "../../assets/images";
import { Col, Row } from "../GridSystem";
import { useClose } from "../../contexts/useClose";

const CardLanguage = () => {
  const { isOpen, mouseRef, reverseFunction } = useClose();
  const languages = [
    { image: en, title: "English", name: "en", translation: "English" },
    { image: ar, title: "Arabic", name: "ar", translation: "العربية" },
    // { image: ru, title: "Russian", name: "ru", translation: "Русский" },
    // { image: sw, title: "Swedish", name: "sw", translation: "Svenska" },
    // { image: fr, title: "French", name: "fr", translation: "Français" },
    // { image: ch, title: "Chinese", name: "ch", translation: "中文" },
    // { image: ge, title: "German", name: "ge", translation: "Deutsch" },
  ];
  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "en");
      window.location.reload(false);
    }
  }, []);
  return (
    <div ref={mouseRef} className="relative flex items-center gap-2 w-fit">
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={reverseFunction}
      >
        <div className="w-5 h-4">
          <img
            src={
              languages.find((e) => e.name === localStorage.getItem("language"))
                ?.image
            }
            alt="flag"
            className="object-center w-full h-full "
          />
        </div>
        <div>
          {
            languages.find((e) => e.name === localStorage.getItem("language"))
              ?.translation
          }
        </div>
        <FaCaretDown />
      </div>

      {/* القائمة المنسدلة */}
      {isOpen && (
        <div className="absolute top-10 left-0 mt-2 w-[150px] bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          {languages.map((e, i) => (
            <div
              onClick={() => {
                window.localStorage.setItem("language", e.name);
                window.location.reload(false);
              }}
            >
              <Row
                key={i}
                className="flex justify-between items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                <Col col={2} className="!p-0">
                  <img
                    src={e.image}
                    alt="English flag"
                    className="w-5 h-5 rounded-full object-cover  mr-2 "
                  />
                </Col>
                <Col col={6} className="!p-0">
                  <div>{e.title}</div>
                </Col>
                <Col col={1}>
                  <div>
                    {e.name === localStorage.getItem("language") && (
                      <MdOutlineDone />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardLanguage;
