import { Col } from "../GridSystem";
const CheckBox = ({ data, value }) => {
  return (
    <Col md={data.xs || data.md || 4} xs={data.xs || 6} className="flex-center">
      <div className="flex items-center gap-2 m-1 !mt-4">
        <input
          type="checkbox"
          name={data.name}
          checked={value}
          onChange={data.onChange}
          className="w-4 h-4 mt-2"
        />
        <h3 className={`mt-2 text-Third font-semibold `}>{data.title}</h3>
      </div>
    </Col>
  );
};

export default CheckBox;
