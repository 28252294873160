import React from "react";
import { content } from "../../contexts/useLang";

const PageLoading = () => {
  return (
    <div className="flex items-center justify-center h-[80vh] bg-gray-100">
      <div className="text-center">
        <div className="spinner-border animate-spin inline-block w-16 h-16 border-4 border-solid border-primary rounded-full border-t-transparent"></div>
        <p className="mt-4 text-xl font-semibold text-gray-600">
          {content["Loading..."]}
        </p>
      </div>
    </div>
  );
};

export default PageLoading;
