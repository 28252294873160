import React, { useEffect } from "react";
import {
  ButMain,
  Col,
  Container,
  InputsPage,
  TitlePage,
} from "../../components";
import { usePost } from "../../contexts/usePost";
import { useFetch } from "../../contexts/useFetch";
import { useParams } from "react-router-dom";
import { content } from "../../contexts/useLang";

const AddAddress = () => {
  const { id } = useParams();
  const isAdd = id === "add";
  const {
    formData,
    handleChangeInput,
    handleSubmit,
    setFormData,
    loadingPost,
  } = usePost(
    {
      full_name: "",
      city: "",
      post_code: "",
      street_name: "",
      house_number: "",
    },
    {
      full_name: ["required"],
      city: ["required"],
      post_code: ["required"],
      street_name: ["required"],
      house_number: ["required"],
    }
  );

  const { data } = useFetch(!isAdd && "addresses/" + id);
  useEffect(() => {
    if (!isAdd) {
      setFormData({
        ...formData,
        full_name: data?.full_name,
        city: data?.city,
        post_code: data?.post_code,
        street_name: data?.street_name,
        house_number: data?.house_number,
      });
    }
  }, [data]);

  return (
    <div>
      <Container>
        <TitlePage
          title={[
            content["Profile"],
            content["My Addresses"],
            (isAdd ? content["Add New"] : content["Update"]) +
              " " +
              content["Address"],
          ]}
        />
        <Col md={6} className="mx-auto">
          {[
            {
              xs: 11,
              title: content["Full name"],
              onChange: handleChangeInput,
              inputType: "input",
              name: "full_name",
              isTitle: true,
              type: "text",
            },
            {
              xs: 11,
              title: content["City"],
              onChange: handleChangeInput,
              inputType: "input",
              name: "city",
              isTitle: true,
              type: "text",
            },
            {
              xs: 11,
              title: content["Post code"],
              onChange: handleChangeInput,
              inputType: "input",
              name: "post_code",
              isTitle: true,
              type: "text",
            },
            {
              xs: 11,
              title: content["Street name"],
              onChange: handleChangeInput,
              inputType: "input",
              name: "street_name",
              isTitle: true,
              type: "text",
            },
            {
              xs: 11,
              title: content["House number"],
              onChange: handleChangeInput,
              inputType: "input",
              name: "house_number",
              isTitle: true,
              type: "text",
            },
          ].map((e, i) => (
            <InputsPage key={i} data={e} value={formData?.[e.name]} />
          ))}
          <Col xs={11} className=" ">
            <ButMain
              onClick={() =>
                handleSubmit(isAdd ? "addresses" : "addresses/" + id)
              }
              loadingPost={loadingPost}
              name={content["Save"]}
              className="text-center py-3 text-xl"
            />
          </Col>
        </Col>
      </Container>
    </div>
  );
};

export default AddAddress;
