import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const SliderMUI = ({ name, max, min, value, onChange }) => {
  return (
    <Box className="flex items-center gap-3 mt-6">
      <div className="mt-3 flex-1">
        <Slider
          valueLabelDisplay="on"
          valueLabelFormat={(value) => value}
          max={max}
          min={min}
          sx={{ color: "#F1CC34" }}
          value={value ? value : null}
          name={name}
          onChange={onChange}
        />
      </div>
    </Box>
  );
};

export default SliderMUI;
