import React, { useEffect } from "react";
import { FaPhoneAlt, MdLocationOn, MdOutlineMail } from "../../assets/icons";
import { ButMain, Col, Container, InputsPage, Row } from "../../components";
import { storageUrl } from "../../config";
import { useContextData } from "../../contexts/ContextData";
import { content } from "../../contexts/useLang";
import { usePost } from "../../contexts/usePost";
const ContactUs = () => {
  const { info } = useContextData();

  const {
    handleChangeInput,
    handleSubmit,
    formData,
    successPost,
    setFormData,
    loadingPost,
  } = usePost(
    {
      name: "",
      email: "",
      phone_number: "",
      message: "",
    },
    {
      name: ["required"],
      email: ["required", "email"],
      phone_number: ["required"],
      message: ["required"],
    }
  );
  useEffect(() => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
    });
  }, [successPost]);

  return (
    <div className="py-3">
      <Container>
        <Row>
          <Col md="1"></Col>
          <Col md="6" className="space-y-3 ">
            <div className="mb-10">
              <div className="text-4xl font-bold my-2">
                {content["Get in"]}{" "}
                <span className="text-Main ">{content["Touch"]}</span>
              </div>
              <div>
                {content["Any question or remarks? Just write us a message!"]}
              </div>
            </div>
            {[
              {
                xs: 11,
                title: content["Name"],
                onChange: handleChangeInput,
                inputType: "input",
                name: "name",
                type: "text",
              },
              {
                xs: 11,
                title: content["Email"],
                onChange: handleChangeInput,
                inputType: "input",
                name: "email",
                type: "email",
              },
              {
                xs: 11,
                title: content["Phone number"],
                onChange: handleChangeInput,
                inputType: "input",
                name: "phone_number",
                type: "text",
              },
              {
                xs: 11,
                title: content["Type a message .."],
                onChange: handleChangeInput,
                inputType: "textarea",
                name: "message",
                type: "text",
              },
            ].map((e, i) => (
              <InputsPage key={i} data={e} value={formData?.[e.name]} />
            ))}

            <Col xs={11} className=" ">
              <ButMain
                onClick={() => handleSubmit("messages", "refresh")}
                name={content["Next"]}
                loadingPost={loadingPost}
                className="text-center py-3 text-xl"
              />
            </Col>
          </Col>
          <Col md="4">
            <div className="w-[350px] mx-auto max-md:hidden">
              <img
                src={storageUrl + info?.image_location}
                alt=""
                className="w-full h-full object-contain"
              />
            </div>

            <div className="flex-center">
              <ul className="space-y-2 mt-5">
                <li>
                  <div className="flex items-center gap-2">
                    <FaPhoneAlt className="text-Main" />
                    {info?.phone}
                  </div>
                </li>
                <li>
                  <div className="flex items-center gap-2">
                    <MdOutlineMail className="text-Main" />
                    {info?.email}
                  </div>
                </li>
                <li>
                  <div className="flex items-center gap-2">
                    <MdLocationOn className="text-Main" />
                    {info?.address}
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
