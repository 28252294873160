import React from "react";
import { useNavigate } from "react-router-dom";
import { storageUrl } from "../../config";

const CardCategories = ({ image, name, id }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/products?section=" + id)}
      className="w-full text-xl font-semibold text-center "
    >
      <div className="bg-Secondary/10 rounded-2xl flex-center h-[150px] mb-4">
        <img
          src={storageUrl + image}
          alt=""
          className="object-contain w-[140px]"
        />
      </div>
      <div>{name}</div>
    </div>
  );
};

export default CardCategories;
