import React from "react";
import { useParams } from "react-router-dom";
import {
  ButMain,
  CardOrder,
  Col,
  Container,
  PageLoading,
  PopUpRate,
  Row,
  TitlePage,
} from "../../components";
import { useContextState } from "../../contexts/ContextState";
import { useFetch } from "../../contexts/useFetch";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";

const Order = () => {
  const { id } = useParams();
  const { data, loading } = useFetch("orders/" + id);
  const { setOpenPopUpRate } = useContextState();
  const { handleSubmit, loadingPost } = usePost({}, {});
  if (loading) {
    return <PageLoading />;
  }
  return (
    <div>
      <PopUpRate type="user" id={id} />

      <Container>
        <TitlePage
          title={[content["Profile"], content["My Order"], "#" + id]}
        />
        <div className="text-xl font-semibold my-3">
          {content["Information"]}
        </div>
        <Row>
          <Col md={4}>
            <div className="text-center font-semibold">
              {" "}
              {content["Seller"]}
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Seller"]} :{" "}
              </div>
              <div>{data?.seller?.store_name}</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">{content["Kvk"]}: </div>
              <div>{data?.seller?.CR_id}</div>
            </div>
            <div
              onClick={() => setOpenPopUpRate(true)}
              className="text-Main underline "
            >
              {content["Submit Your Rate?"]}
            </div>
          </Col>
          <Col md={4}>
            <div className="text-center font-semibold">
              {" "}
              {content["Status"]}
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Total Price"]}:{" "}
              </div>
              <div>{data?.total_price}</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Final Total"]}:{" "}
              </div>
              <div>{data?.final_total}</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Delivery Time"]}:{" "}
              </div>
              <div>
                {data?.delivery_time} {content["Days"]}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Delivery Price"]}:{" "}
              </div>
              <div>{data?.delivery_price}</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Status"]}:{" "}
              </div>
              <div
                className={`font-semibold ${
                  data.status === "pending"
                    ? "text-yellow-500"
                    : data.status === "shipped"
                    ? "text-orange-500"
                    : data.status === "completed"
                    ? "text-green-800"
                    : "text-gray-500"
                }`}
              >
                {data.status === "pending"
                  ? content["Waiting for shipping"]
                  : data.status === "shipped"
                  ? content["Shipped"]
                  : data.status === "completed"
                  ? content["Completed"]
                  : content["Unknown Status"]}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Date"]}:{" "}
              </div>
              <div>{data?.date}</div>
            </div>
            {data?.coupon_code ? (
              <div className="flex items-center gap-2">
                <div className="text-Third font-semibold">
                  {content["Coupon Code"]}:{" "}
                </div>
                <div>{data?.coupon_code}</div>
              </div>
            ) : (
              ""
            )}
            {data?.cancellation_reason ? (
              <div className="flex items-center gap-2">
                <div className="text-Third font-semibold">
                  {content["Cancellation Reason"]} :{" "}
                </div>
                <div>{data?.cancellation_reason}</div>
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col md={4}>
            <div className="text-center font-semibold">
              {" "}
              {content["Customer"]}
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Name"]} :{" "}
              </div>
              <div> {data?.user?.name}</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="text-Third font-semibold">
                {content["Address"]} :{" "}
              </div>
              <div>
                <div className=" font-semibold">
                  {data?.address?.city}, {data?.address?.post_code}
                </div>
                <div className="text-Third my-3">
                  {data?.address?.street_name}, {data?.address?.house_number}
                </div>
              </div>
            </div>
            {data?.note ? (
              <div className="flex items-center gap-2">
                <div className="text-Third font-semibold">
                  {content["Note"]}:{" "}
                </div>
                <div>{data?.note}</div>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <div className=" my-3 flex justify-between items-center">
          <div className="text-xl font-semibold">
            {content["Order Details"]}
          </div>
          <div>
            {data?.status === "shipped" ? (
              <ButMain
                name={content["Mark as Delivered"]}
                loadingPost={loadingPost}
                onClick={() =>
                  handleSubmit(
                    "orders/update-status/" + id + "?status=completed"
                  )
                }
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Row>
          {data?.order_items?.map((e, i) => (
            <Col key={i} md={6} className="p-2">
              <CardOrder data={e} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Order;
