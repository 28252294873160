import React, { useState } from "react";

const CardFilters = ({ title, children }) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="border-2 my-3 p-2">
      <div
        onClick={() => setOpen(!open)}
        className="flex justify-between items-center  font-semibold"
      >
        <div>{title}</div>
        <div className="rotate-90">{">"}</div>
      </div>
      <div
        // style={{ transitionDuration: 50000 }}
        className={`${open ? "h-fit" : "h-0 "} transition-all`}
      >
        <div className={open ? "" : "hidden "}>{children}</div>
      </div>
    </div>
  );
};

export default CardFilters;
