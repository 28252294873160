import React from "react";
import { ButMain, CardAuth, Col, InputsPage, Row } from "../../components";
import { useLogin } from "../../contexts/useLogin";
import { content } from "../../contexts/useLang";

const PassReset = () => {
  const { handleChange, handleSubmit, loadingPost } = useLogin(
    { password: "", c_password: "" },
    {
      password: ["required", "min:8", "equal:c_password"],
      c_password: ["required", "min:8", "equal:password"],
    }
  );

  return (
    <CardAuth title={content["Reset Your Password"]}>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-Main">
          {content["Third Step: Enter your new password."]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/3 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/3 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/3 h-[5px] bg-Main rounded-full"></div>
        </Col>
        {[
          {
            xs: 11,
            title: content["Enter your password"],
            onChange: handleChange,
            inputType: "password",
            name: "password",
            type: "password",
          },
          {
            xs: 11,
            title: content["Confirm your password"],
            onChange: handleChange,
            inputType: "password",
            name: "c_password",
            type: "password",
          },
        ].map((e, i) => (
          <InputsPage key={i} data={e} />
        ))}
        <Col xs={11} className=" ">
          <ButMain
            loadingPost={loadingPost}
            onClick={() => handleSubmit("password-reset/reset", "route", "/")}
            name={content["Next"]}
            className="text-center py-3 text-xl"
          />
        </Col>
      </Row>
    </CardAuth>
  );
};

export default PassReset;
