import { getArrayLength } from "../../contexts/functions";
import { content } from "../../contexts/useLang";

const TitlePage = ({ title, notPage }) => {
  return (
    <>
      <div className="flex gap-1 items-center  font-semibold text-Third mb-4 text-lg max-md:hidden">
        {title &&
          title?.map((e, i) => (
            <div
              key={i}
              onClick={() => window.history.go(i + 1 - getArrayLength(title))}
            >
              {i === 0 ? content[e] || e : " » " + (content[e] || e)}
            </div>
          ))}
      </div>
      {!notPage && (
        <div className="text-center text-3xl font-semibold my-4">
          {title[title?.length - 1]}
        </div>
      )}
    </>
  );
};

export default TitlePage;
