import React from "react";
import {
  CardCategories,
  Col,
  Container,
  PageLoading,
  Pagination,
  Row,
  TitlePage,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { content } from "../../contexts/useLang";

const Categories = () => {
  const { searchAll } = useFilter();
  const { data, loading } = useFetch("sections" + searchAll);
  return (
    <div>
      {!loading ? (
        <Container>
          <TitlePage title={[content["Home"], content["All Sections"]]} />
          <Row>
            {data?.data?.map((e, i) => (
              <Col key={i} md={20} sm={6}>
                <CardCategories id={e.id} image={e.image} name={e.name} />
              </Col>
            ))}
          </Row>
          <Pagination data={data} />
        </Container>
      ) : (
        <PageLoading />
      )}
    </div>
  );
};

export default Categories;
