import React, { useEffect, useState } from "react";
import SliderMUI from "../Inputs/SliderMUI";
import { useFilter } from "../../contexts/useFilter";

const PriceFilter = () => {
  const [price, setPrice] = useState([0, 1000]);
  const { handleParamsAdd, handleParamsRemove, searchParams } = useFilter();
  const handleChangeRange = (event, newValue) => {
    setPrice(newValue);
    handleParamsAdd("price_min", newValue[0]);
    handleParamsAdd("price_max", newValue[1]);
    if (newValue[0] === 0 && newValue[1] === 1000) {
      handleParamsRemove("price_min");
      handleParamsRemove("price_max");
    }
  };
  useEffect(() => {
    setPrice([searchParams.get("price_min"), searchParams.get("price_max")]);
  }, [searchParams.get("price_min"), searchParams.get("price_max")]);

  return (
    <div>
      <SliderMUI
        max={1000}
        min={0}
        value={price}
        onChange={handleChangeRange}
        name="price"
      />
    </div>
  );
};

export default PriceFilter;
