import { AiOutlineClose, AiOutlineCloudUpload } from "../../assets/icons";
import { zoomImage } from "../../contexts/functions";
import { storageUrl } from "../../config";
import { Col, Row } from "../GridSystem";
import { content } from "../../contexts/useLang";

const UploadFile = ({ data }) => {
  return (
    <Col md={data.xs || data.md || 4} xs={data.xs || 6}>
      <input
        id={data.id || "file"}
        type="file"
        name={data.name}
        onChange={data.onChange}
        className="hidden"
        accept="image/*"
      />
      <div
        onClick={() => document.getElementById(data.id || "file").click()}
        className="flex justify-between items-center px-2 text-gray-400 border bg-[#fff] border-[#D2D2D2] m-1 py-[0.4rem] rounded-sm"
      >
        <p>{data?.title || content.addImage}</p>
        <div className="border-r-2 pr-2">
          <AiOutlineCloudUpload size={25} />
        </div>
      </div>
      <Row className="!mt-4">
        {data.viewFile[data.name] && (
          <Col col={6}>
            <div className="relative h-[250px] rounded-xl">
              <span
                onClick={() => {
                  data.setFormData((prev) => ({
                    ...prev,
                    [data.name]: "",
                  }));
                  data.setViewFile((prev) => ({
                    ...prev,
                    [data.name]: "",
                  }));
                }}
                className="bg-red-600 w-6 h-6 flex-center text-white cursor-pointer z-10 -top-2 rounded-full absolute hover:opacity-80 transition-all"
              >
                <AiOutlineClose />
              </span>
              {data.formData?.[data.name].type?.includes("image") ? (
                <img
                  src={data.viewFile[data.name]}
                  alt=""
                  className="w-[150px] h-[150px] rounded-2xl cursor-pointer object-contain group-hover:blur-sm bg-slate-100"
                  onClick={zoomImage}
                />
              ) : data.formData?.[data.name].type?.includes("video") ? (
                ""
              ) : data.formData?.[data.name].type?.includes("audio") ? (
                ""
              ) : (
                <div className="flex justify-center p-1 items-center bg-slate-200 max-w-[200px] max-h-[150px] rounded-2xl">
                  {data.formData?.[data.name].name}
                </div>
              )}
            </div>
          </Col>
        )}
        {data.oldFile && (
          <Col col={6}>
            <img
              src={storageUrl + data.oldFile}
              alt=""
              className="w-[150px] h-[150px] rounded-2xl cursor-pointer object-contain bg-slate-100"
              onClick={zoomImage}
            />
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default UploadFile;
