import React from "react";
import { useFetch } from "../../contexts/useFetch";
import {
  PageLoading,
  Pagination,
  Container,
  TitlePage,
} from "../../components";
import { FiBellOff } from "react-icons/fi";
import { useFilter } from "../../contexts/useFilter";
import { content } from "../../contexts/useLang";

const Notifications = () => {
  const { searchAll } = useFilter();
  const { data, loading } = useFetch("notifications" + searchAll);
  if (loading) {
    return <PageLoading />;
  }
  return (
    <div>
      <Container>
        <TitlePage
          title={[
            content["Home"],
            content["My Profile"],
            content["Notifications"],
          ]}
        />
        {data?.data?.length ? (
          data?.data?.map((e, i) => (
            <div
              className={`${
                !e.is_read ? "bg-blue-100" : "bg-white"
              } col-md-8 mx-auto shadow-md rounded-lg p-2 my-1`}
            >
              <p className="flex justify-between items-center">
                <div className="text-lg font-semibold">{e.title}</div>
                <div>{e.created_at}</div>
              </p>
              <p className="text-gray-600">{e.message}</p>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center text-black/50 text-lg py-10">
            <FiBellOff size={50} className="mb-4 text-gray-400" />
            <span>{content["No notifications available"]}</span>
          </div>
        )}
      </Container>
      <Pagination data={data} />
    </div>
  );
};

export default Notifications;
