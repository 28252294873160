import React from "react";
import { CardAuth, Col, Row } from "../../components";
import { SuccessImg } from "../../assets/images";
import { content } from "../../contexts/useLang";

const Success = () => {
  return (
    <CardAuth>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-center">
          <img src={SuccessImg} alt="" />
        </Col>
        <Col xs={11} className="text-center text-xl !mt-10">
          {content["Your account has been created !"]}
        </Col>
      </Row>
    </CardAuth>
  );
};

export default Success;
