import { content } from "../../contexts/useLang";
const TitleInput = ({ title, isOptional, loading }) => {
  return (
    <div className="flex items-center gap-2">
      <div className={`mt-2 text-Third font-semibold `}>
        {title}
        <span className="text-Main">{isOptional ? content.optional : "*"}</span>
      </div>
    </div>
  );
};

export default TitleInput;
