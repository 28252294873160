import React from "react";
import { Link } from "react-router-dom";
import { ButMain, CardAuth, Col, InputsPage, Row } from "../../components";
import { useLogin } from "../../contexts/useLogin";
import { content } from "../../contexts/useLang";

const Login = () => {
  const { handleChange, handleSubmit, loadingPost } = useLogin(
    { email: "", password: "" },
    {
      email: ["required", "email"],
      password: ["required", "min:8"],
    }
  );

  return (
    <CardAuth title={content["Login to Customer Account"]}>
      <Row justify="center" className="space-y-3">
        {[
          {
            xs: 11,
            title: content["Enter your email address"],
            onChange: handleChange,
            inputType: "input",
            name: "email",
            type: "text",
          },
          {
            xs: 11,
            title: content["Enter your password"],
            onChange: handleChange,
            inputType: "input",
            name: "password",
            type: "password",
          },
        ].map((e) => (
          <InputsPage data={e} />
        ))}
        <Col xs={11} className="text-Main text-end">
          <Link to="/auth/p-reset/send">
            {content["Forget your password?"]}
          </Link>
        </Col>
        <Col xs={11} className=" ">
          <ButMain
            loadingPost={loadingPost}
            onClick={() => handleSubmit("login")}
            name={content["Login"]}
            className="text-center py-3 text-xl"
          />
        </Col>
        <Col xs={11} className="text-center">
          {content["Don’t have an ccount?"]}{" "}
          <Link to="/auth/sign-up" className="text-Main">
            {content["Sign up"]}
          </Link>
        </Col>
      </Row>
    </CardAuth>
  );
};

export default Login;
