import { useLocation, useNavigate } from "react-router-dom";

export const useFilter = () => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const navigate = useNavigate();

  const searchAll = search;
  const subSearch = search ? "&" + search.substring(1) : "";

  const handleParamsAdd = (name, value) => {
    searchParams.set(name, value);
    const newUrl = `${pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };

  const handleParamsRemove = (name) => {
    searchParams.delete(name);
    const newUrl = `${pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };

  const handleParamsDeleteAll = () => {
    const newUrl = `${pathname}`;
    navigate(newUrl);
  };

  const handlePageClick = (event) => {
    handleParamsAdd("page", event.selected + 1);
  };

  return {
    searchParams,
    searchAll,
    subSearch,
    handlePageClick,
    handleParamsAdd,
    handleParamsRemove,
    handleParamsDeleteAll,
  };
};
