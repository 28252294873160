import React from "react";
import {
  ButMain,
  CardSellProducts,
  Col,
  Container,
  PageLoading,
  Pagination,
  Row,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { Link } from "react-router-dom";
import { useFilter } from "../../contexts/useFilter";
import { useContextState } from "../../contexts/ContextState";
import { FaFilter } from "react-icons/fa";
import { content } from "../../contexts/useLang";

const SellerProducts = () => {
  const { searchAll } = useFilter();
  const { data, loading, deleteItem } = useFetch("seller/products" + searchAll);
  const { setOpenSideBarFilter } = useContextState();
  const { handleParamsAdd, searchParams } = useFilter();
  return (
    <div className="py-4">
      {loading ? (
        <PageLoading />
      ) : (
        <Container>
          <div className="flex justify-between items-center">
            <div className=" text-3xl font-semibold my-4">
              {content["Products"]}
            </div>
            <Link to="/dashboard/products/add" className="text-Main text-lg">
              + {content["Add New Product"]}
            </Link>
          </div>
          <div className="my-2 flex items-center gap-2 ">
            <ButMain
              className="w-fit px-5 mb-5"
              name={
                <div className="flex-center gap-2">
                  <div>{content["Filter"]}</div>
                  <FaFilter />
                </div>
              }
              onClick={() => setOpenSideBarFilter(true)}
            />

            {[
              { title: "Pending", name: "pending" },
              { title: "Rejected", name: "rejected" },
              { title: "Draft", name: "draft" },
              { title: "Active", name: "active" },
              { title: "Archived", name: "archived" },
            ]?.map((e, i) => (
              <ButMain
                key={i}
                className={`${
                  e.name === searchParams.get("status")
                    ? "bg-Third/30"
                    : "bg-white"
                } w-fit px-5 mb-5 text-black hover:bg-Third/30`}
                name={e.title}
                onClick={() => handleParamsAdd("status", e.name)}
              />
            ))}
          </div>
          <Row className="mt-5">
            {data?.data?.map((e, i) => (
              <Col md={3} key={i} className="pb-3">
                <CardSellProducts key={e.id} data={e} deleteItem={deleteItem} />
              </Col>
            ))}
          </Row>
          <Pagination data={data} />
        </Container>
      )}
    </div>
  );
};

export default SellerProducts;
