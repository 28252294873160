import { createContext, useContext, useState } from "react";
const contextState = createContext({});

const ContextStateProvider = ({ children }) => {
  const [loadingPost, setLoadingPost] = useState(false);
  const [error, setError] = useState([]);
  const [messages, setMessages] = useState([]);
  const [openPopUpRate, setOpenPopUpRate] = useState(false);
  const [openPopUpDeleteAccount, setOpenPopUpDeleteAccount] = useState(false);
  const [openPopUpNoAccount, setOpenPopUpNoAccount] = useState(false);
  const [openpayment, setOpenpayment] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openNavbar, setOpenNavbar] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [openSideBarFilter, setOpenSideBarFilter] = useState(false);
  const [openPopUpUpdateOrder, setOpenPopUpUpdateOrder] = useState(false);
  const [refreshData, setRefreshData] = useState(0);
  const [paymentMethodId, setPaymentMethodId] = useState();
  return (
    <contextState.Provider
      value={{
        error,
        setError,
        messages,
        setMessages,
        openPopUpRate,
        setOpenPopUpRate,
        openPopUpDeleteAccount,
        setOpenPopUpDeleteAccount,
        openPopUpNoAccount,
        setOpenPopUpNoAccount,
        openSidebar,
        setOpenSidebar,
        openNavbar,
        setOpenNavbar,
        openDelete,
        setOpenDelete,
        refreshData,
        setRefreshData,
        openLogOut,
        setOpenLogOut,
        openSideBarFilter,
        setOpenSideBarFilter,
        openPopUpUpdateOrder,
        setOpenPopUpUpdateOrder,
        openpayment,
        setOpenpayment,
        paymentMethodId,
        setPaymentMethodId,
        loadingPost,
        setLoadingPost,
      }}
    >
      {children}
    </contextState.Provider>
  );
};

export default ContextStateProvider;

export const useContextState = () => {
  return useContext(contextState);
};
