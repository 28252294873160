import { Link, useNavigate } from "react-router-dom";
import { CiSearch } from "../../assets/icons";
import { logo } from "../../assets/images";
import { ButMain, CardLanguage, Col, Container, Row } from "../../components";

import { useState } from "react";
import { RiMenuFoldFill } from "react-icons/ri";
import { useContextData } from "../../contexts/ContextData";
import { useContextState } from "../../contexts/ContextState";
import { token } from "../../config";
import Cart from "./Cart";
import Notifications from "./Notifications";
import { usePost } from "../../contexts/usePost";
import { content } from "../../contexts/useLang";
const NavbarMobile = () => {
  const [sidebar, setSidebar] = useState(false);
  const { profile } = useContextData();
  return (
    <div className="py-3 borderb">
      <Container>
        <Row className="items-center justify-between">
          <Col sm={6} className="p-0">
            <Link to="/">
              <img src={logo} alt="" className="w-" />
            </Link>
          </Col>

          <Col sm={3} className="p-0">
            <CardLanguage />
          </Col>
          <Col sm={1} className="p-0 font-bold">
            <RiMenuFoldFill size={25} onClick={() => setSidebar(!sidebar)} />
          </Col>

          <Col sm={9}>
            <div className="bg-Secondary/10 px-4 py-[7px] rounded-full flex items-center border b">
              <CiSearch size={20} className="text-Secondary/80" />
              <input
                type="search"
                placeholder="Search"
                className="bg-transparent px-2"
              />
            </div>
          </Col>
          <Col sm={1}>
            <Cart data={profile?.sellers_cart} />
          </Col>
          <Col sm={2}>
            <Notifications
              notifications={profile?.notifications}
              notificationsCount={profile?.notifications_count}
            />
          </Col>
        </Row>
        <Sidebar
          profile={profile}
          openSidebar={sidebar}
          setOpenSidebar={setSidebar}
        />
      </Container>
    </div>
  );
};

export default NavbarMobile;

const Sidebar = ({ openSidebar, setOpenSidebar, profile }) => {
  const { setOpenPopUpDeleteAccount, setOpenLogOut } = useContextState();
  const { handleSubmit, loadingPost } = usePost({}, {});
  const sidebar = [
    {
      link: "/",
      name: "Home",
    },
    {
      link: "/categories",
      name: "Categories",
    },
    {
      link: "/sellers",
      name: "Sellers",
    },
    {
      link: "/about-us",
      name: "About Us",
    },
    {
      link: "/contact-us",
      name: "Contact Us",
    },
    {
      link: "/profile",
      name: "My Profile",
      showPageToken: true,
    },
    {
      link: "/notifications",
      name: "Notifications",
      showPageToken: true,
    },
    {
      link: "/orders",
      name: "My Order",
      showPageToken: true,
    },
    {
      link: "/address",
      name: "My Address",
      showPageToken: true,
    },
    {
      onClick: "",
      link: "/following",
      name: "Following",
      showPageToken: true,
    },
    {
      link: "/favorites",
      name: "Favorite",
      showPageToken: true,
    },
    {
      onClick: () => setOpenPopUpDeleteAccount(true),
      name: "Delete my account",
      showPageToken: true,
    },
    {
      onClick: () => setOpenLogOut(true),
      name: "Log Out",
      showPageToken: true,
    },
  ];
  return (
    <>
      <div
        className={`${
          openSidebar
            ? "!w-[250px] !max-lg:translate-x-10 !max-lg:w-3/4 -!right-0"
            : " w-[0px] "
        } !max-lg:translate-x-full bg-white border-r h-[95vh] right-0 scroll max-lg:h-[100%] max-lg:!mt-0  top-0 sticky  max-lg:fixed max-lg:right-0  transition-all py-4 z-50`}
      >
        <ul
          className={`font-semibold space-y-2 px-3 sidebar transition-all ${
            openSidebar ? "" : "hidden"
          } `}
        >
          <li className="md:hidden">
            <img src={logo} alt="" className="w-" />
          </li>
          <li className="md:hidden">
            {profile?.status === "pending" ? (
              <ButMain
                className="w-fit mx-auto text-center"
                onClick={() => console.log("")}
                name={content["Pending Approval"]}
              />
            ) : profile?.status === "suspended" ? (
              <ButMain
                onClick={() =>
                  handleSubmit(
                    "seller-express-account?seller_id=" + profile?.id,
                    "noRoute"
                  )
                }
                loadingPost={loadingPost}
                className="w-fit mx-auto text-center"
                name={content["Complete Your Account"]}
              />
            ) : profile?.is_seller && profile?.status === "active" ? (
              <ButMain
                className="w-fit mx-auto text-center"
                link="/Dashboard/"
                name={content["Dashboard"]}
              />
            ) : +profile?.is_seller === 0 ? (
              <ButMain
                className="w-fit mx-auto text-center"
                link="/seller/create-p1"
                name={content["Become a Seller"]}
              />
            ) : (
              ""
            )}
          </li>

          {sidebar.map((e, i) => (
            <>
              {e.showPageToken && !token ? (
                ""
              ) : (
                <Li
                  key={i}
                  link={e.link}
                  name={content[e.name]}
                  onClick={e.onClick}
                />
              )}
            </>
          ))}
          <li>
            {!token ? (
              <div className="flex-center  w-full">
                <div>
                  <Link
                    to="/auth/login"
                    className=" text-Main hover:text-Main/90 cursor-pointer py-1 px-7 font-semibold"
                  >
                    {content["Login"]}
                  </Link>
                </div>
                <div>
                  <ButMain link="/auth/sign-up" name={content["Sign up"]} />
                </div>
              </div>
            ) : (
              ""
            )}
          </li>
        </ul>
      </div>
      <div
        onClick={() => setOpenSidebar(!openSidebar)}
        style={{ transition: "0.4s" }}
        className={`${
          openSidebar ? "" : "hidden"
        }  max-lg:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-30`}
      />
    </>
  );
};
const Li = ({ onClick, link, name }) => {
  const navigate = useNavigate();
  return (
    <li
      onClick={() => {
        if (link) {
          navigate(link);
        } else if (onClick) {
          onClick();
        }
      }}
      className="px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer flex items-center gap-2"
    >
      <div>{name}</div>
    </li>
  );
};
