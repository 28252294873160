import React from "react";
import { IoNotificationsOutline } from "../../assets/icons"; // تأكد من أن لديك الأيقونة هنا
import { useClose } from "../../contexts/useClose";
import { IoNotificationsOffOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { content } from "../../contexts/useLang";

const Notifications = ({ notifications, notificationsCount }) => {
  const { isOpen, mouseRef, reverseFunction } = useClose();

  return (
    <div ref={mouseRef} className="relative inline-block">
      <div onClick={reverseFunction} className="relative cursor-pointer">
        <IoNotificationsOutline size={26} />
        {notificationsCount !== 0 && notificationsCount && (
          <div className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 text-xs flex items-center justify-center">
            {notifications.length}
          </div>
        )}
      </div>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
          <div className="max-h-60 overflow-y-auto">
            {notifications?.length ? (
              notifications?.map((notification) => (
                <div
                  key={notification.id}
                  className="px-4  py-3 border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                >
                  <div className="flex items-center space-x-3">
                    <IoNotificationsOutline size={20} />
                    <div className="flex-1">
                      <div className="font-semibold text-sm">
                        {notification.title}
                      </div>
                      <div className="text-xs text-gray-500">
                        {notification.date}
                      </div>
                      <div className="text-xs text-gray-700">
                        {notification.message}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center py-8">
                <IoNotificationsOffOutline
                  size={50}
                  className="text-gray-400 mb-3"
                />
                <p className="text-sm text-gray-500">
                  {content["No notifications available"]}
                </p>
              </div>
            )}
            <Link
              to="/notifications"
              className="block text-center py-2 border-t "
            >
              <p className="text-sm text-gray-500">{content["View All"]}</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
