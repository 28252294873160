import React from "react";
import {
  BsTwitterX,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaYoutube,
  MdLocationOn,
  MdOutlineMail,
} from "../../assets/icons";
import { logoLight } from "../../assets/images";
import { Col, Container, Row } from "../../components";
import { useContextData } from "../../contexts/ContextData";

const Footer = () => {
  const { info } = useContextData();
  return (
    <div className="bg-Secondary text-Light py-10 mt-5">
      <Container>
        <Row className="px-10 ">
          <Col md={3} className="space-y-5">
            <div>
              <img src={logoLight} alt="" className="mx-auto" />
            </div>
            <div>
              <div>Copyright © 2025 Nexcent ltd.</div>
              <div>All rights reserved</div>
            </div>
            <div className="flex-center gap-2">
              {info?.instagram !== "non" && (
                <a
                  target="_blank"
                  href={info?.instagram || ""}
                  className="bg-black/50 rounded-full w-7  h-7 flex-center"
                >
                  <FaInstagram />
                </a>
              )}
              {info?.facebook !== "non" && (
                <a
                  target="_blank"
                  href={info?.facebook || ""}
                  className="bg-black/50 rounded-full w-7  h-7 flex-center"
                >
                  <FaFacebookF />
                </a>
              )}
              {info?.x !== "non" && (
                <a
                  target="_blank"
                  href={info?.x || ""}
                  className="bg-black/50 rounded-full w-7  h-7 flex-center"
                >
                  <BsTwitterX />
                </a>
              )}
              {info?.youtube !== "non" && (
                <a
                  target="_blank"
                  href={info?.youtube || ""}
                  className="bg-black/50 rounded-full w-7  h-7 flex-center"
                >
                  <FaYoutube />
                </a>
              )}
            </div>
          </Col>
          {[
            { name: "Company", info: ["About", "Features", "Works", "Career"] },
            {
              name: "Supporf",
              info: [
                "Customer Support",
                "Delivery Details",
                "Terms & Conditions",
                "Privacy Policy",
              ],
            },
            {
              name: "Contact Info",
              info: [
                <div className="flex items-center gap-2">
                  <FaPhoneAlt />
                  {info?.phone}
                </div>,
                <div className="flex items-center gap-2">
                  <MdOutlineMail />
                  {info?.email}
                </div>,
                <div className="flex items-center gap-2">
                  <MdLocationOn />
                  {info?.address}
                </div>,
              ],
            },
          ].map((e, i) => (
            <Col
              key={i}
              md={3}
              className="flex flex-col items-center justify-start "
            >
              <div className="space-y-2">
                <div className="text-xl uppercase">{e.name}</div>
                <ul className="space-y-2">
                  {e.info.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
