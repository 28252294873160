import { Link, NavLink } from "react-router-dom";
import { logo } from "../../assets/images";
import { ButMain, CardLanguage, Col, Container, Row } from "../../components";
import { token } from "../../config";
import { useContextData } from "../../contexts/ContextData";
import { usePost } from "../../contexts/usePost";
import Cart from "./Cart";
import Categories from "./Categories";
import Notifications from "./Notifications";
import Search from "./Search";
import User from "./User";
import { content } from "../../contexts/useLang";

const Navbar = () => {
  const { profile } = useContextData();
  const { handleSubmit, loadingPost } = usePost({}, {});
  return (
    <div className="py-3 borderb">
      <Container>
        <Row className="items-center">
          <Col md={2}>
            <Link to="/">
              <img src={logo} alt="" className="w-" />
            </Link>
          </Col>
          <Col md={4}>
            <ul className="flex items-center gap-6">
              {["Home", "Categories", "Sellers", "About Us", "Contact Us"].map(
                (e, i) => (
                  <li key={e}>
                    {e === "Categories" ? (
                      <div className="group relative hover:font-bold ">
                        <div>{content[e]}</div>
                        <div className="absolute font-normal -left-40 group-hover:block hidden transition-all">
                          <Categories />
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={
                          i !== 0 ? e.toLowerCase().replace(/\s+/g, "-") : "/"
                        }
                      >
                        {content[e]}
                      </NavLink>
                    )}
                  </li>
                )
              )}
            </ul>
          </Col>
          <Search />
          <Col md={1} className="p-0">
            <CardLanguage />
          </Col>
          <Col md={3} className="p-0">
            {token ? (
              <div className="flex items-center gap-1 w-full">
                <Cart data={profile?.sellers_cart} />
                <Notifications
                  notifications={profile?.notifications_new}
                  notificationsCount={profile?.notifications_count}
                />
                <User />
                {profile?.status === "pending" ? (
                  <ButMain
                    onClick={() => console.log("")}
                    name={content["Pending Approval"]}
                  />
                ) : profile?.status === "suspended" ? (
                  <ButMain
                    loadingPost={loadingPost}
                    onClick={() =>
                      handleSubmit(
                        "seller-express-account?seller_id=" + profile?.id,
                        "noRoute"
                      )
                    }
                    className="w-fit text-start"
                    name={content["Complete Your Account"]}
                  />
                ) : profile?.is_seller && profile?.status === "active" ? (
                  <ButMain link="/Dashboard/" name={content["Dashboard"]} />
                ) : (
                  <ButMain
                    link="/seller/create-p1"
                    name={content["Become a Seller"]}
                  />
                )}
              </div>
            ) : (
              <div className="flex-center  w-full">
                <div>
                  <Link
                    to="/auth/login"
                    className=" text-Main hover:text-Main/90 cursor-pointer py-1 px-7 font-semibold"
                  >
                    {content["Login"]}
                  </Link>
                </div>
                <div>
                  <ButMain link="/auth/sign-up" name={content["Sign up"]} />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navbar;
