function RadioBtn({ data }) {
  return (
    <div className="flex items-center gap-2 mx-5 my-1">
      <div
        className="bg-Secondary w-fit rounded-full p-[1px]"
        onClick={data.onClick}
      >
        <div
          className={`${
            data.value ? "bg-Secondary " : "bg-white"
          }  w-[16px] h-[16px] rounded-full border-[3px] border-white`}
        />
      </div>
      {data.bg && <div className={`scale-50 -mr-2 ${data.bg}`} />}
      {data?.title && <h2 className={`-mr-2 `}>{data?.title}</h2>}
      <div className="flex items-center gap-1">
        {data.colors &&
          data.colors?.map((e, i) => (
            <div key={i} className={`w-7 h-7 ${e} rounded-sm`} />
          ))}
      </div>
    </div>
  );
}
export default RadioBtn;
