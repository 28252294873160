import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import ContextDataProvider from "./contexts/ContextData";
import ContextStateProvider from "./contexts/ContextState";
import "./css/GridSystem.css";
import "./css/swiper.css";
import "./css/Pagination.css";
import "./css/index.css";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextStateProvider>
    <ContextDataProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </ContextDataProvider>
  </ContextStateProvider>
);
