import React, { useState } from "react";
import { storageUrl } from "../../config";
import { Col, Row } from "../GridSystem";
import { content } from "../../contexts/useLang";

const DeleteFiles = ({ show, data, handleCheckBoxes, name, keys }) => {
  const [deleteImgs, setDeleteImgs] = useState(false);
  return (
    <>
      {show && (
        <>
          <div>
            <div
              onClick={() => setDeleteImgs(!deleteImgs)}
              className="cursor-pointer px-5 py-2 border border-Green w-fit rounded-lg text-lg  font-semibold text-white bg-Main my-2 ml-auto "
            >
              {content["Delete Image"]}
            </div>
            <Row>
              {data?.map((e, i) => (
                <Col key={i} md={20} sm={6} className="relative">
                  {console.log(e)}
                  {deleteImgs && (
                    <input
                      type="checkbox"
                      value={e.id}
                      name={name}
                      onChange={handleCheckBoxes}
                      className="absolute -top-2 left-1 h-5 w-5"
                    />
                  )}
                  <div className="w-full h-[200px] flex-center bg-slate-100 my-1 rounded-md p-2">
                    <img
                      src={storageUrl + e[keys]}
                      alt=""
                      className="w-full h-full object-contain rounded-2xl"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default DeleteFiles;
