import Select from "react-select";
import { areEqual, checkIsSearchSelect } from "../../contexts/functions";
import { Col } from "../GridSystem";
import TitleInput from "../Titles/TitleInput";
import { content } from "../../contexts/useLang";

const MultiSelect = ({ data, value }) => {
  const optionsSelect = data.options;
  return (
    <Col md={data.xs || data.md || 4} xs={data.xs || 6}>
      <TitleInput title={data?.title} isOptional={data.isOptional} />
      <Select
        value={
          value?.length
            ? optionsSelect
                ?.filter((item) => {
                  return value?.find((val) => areEqual(val, item.id));
                })
                .map((e) => {
                  return { value: e.id, label: e.name };
                })
            : null
        }
        isMulti
        isSearchable={checkIsSearchSelect(data.name)}
        className="mt-2"
        onChange={data.onChange}
        name={data.name}
        placeholder={content.chose + data?.title}
        options={optionsSelect?.map((e) => {
          return { value: e.id, label: e.name };
        })}
      />
    </Col>
  );
};

export default MultiSelect;
