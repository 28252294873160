import React from "react";
import { logo } from "../../assets/images";
import { Container } from "../../components";
import { useContextData } from "../../contexts/ContextData";
import parse from "html-react-parser";
import { content } from "../../contexts/useLang";

const AboutUs = () => {
  const { info } = useContextData();
  return (
    <div className=" min-h-screen bg-gray-100 p-6">
      <Container>
        <div className="flex justify-center items-center ">
          <div className="bg-white p-8 rounded-lg shadow-lg  w-full ">
            <img src={logo} alt="Logo" className="mx-auto mb-6 w-40 " />
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              {content["About Us"]}
            </h1>
            <p className="text-lg text-gray-600">
              {info?.about_us && parse(info?.about_us)}
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
