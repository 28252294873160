import React from "react";
import { FaShoppingCart, IoIosStar, TbTruckDelivery } from "../../assets/icons";
import { storageUrl } from "../../config";
import ButMain from "../Buttons/ButMain";
import { useNavigate } from "react-router-dom";
import { content } from "../../contexts/useLang";
const CardSellersPage = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="text-center bg-seller p-2">
      <div>
        <div className="rounded-full overflow-hidden mb-4 mx-auto h-[130px] w-[130px] ">
          <img
            src={storageUrl + data.store_image}
            alt=""
            className="object-contain "
          />
        </div>
        <div className="text-xl font-semibold">{data.store_name}</div>
        <div className="">{data.name}</div>
      </div>
      <ul className="flex-center gap-5 mt-3 my-2">
        <li
          onClick={() => navigate("/cart/" + data.username)}
          className="text-sm cursor-pointer"
        >
          <div className="w-6 h-6 mx-auto bg-[#1A78F1] rounded-full flex-center text-Light">
            <FaShoppingCart className="" />
          </div>
          <div>{content["Cart"]}</div>
        </li>
        <li className="text-sm ">
          <div className="w-6 h-6 mx-auto bg-[#F1CC34] rounded-full flex-center text-Light">
            <TbTruckDelivery />
          </div>
          <div>
            {data.delivery_time} {content["Days"]}
          </div>
        </li>
        <li className="text-sm">
          <div className="w-6 h-6 mx-auto bg-[#FFA138] rounded-full flex-center text-Light">
            <IoIosStar />
          </div>
          <div>
            {data.average_rating}{" "}
            <span className="text-xs text-Third">
              ({data.reviews_count >= 25 ? "25+" : data.reviews_count})
            </span>
          </div>
        </li>
      </ul>
      <ButMain
        onClick={() => navigate("/sellers/" + data.username)}
        name={content["View Profile"]}
        className="text-center"
      />
    </div>
  );
};

export default CardSellersPage;
