import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "../../contexts/useFetch";
import { Col, PageLoading, Row } from "../../components";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";

const SellerProduct = () => {
  const { id } = useParams();
  const { data, loading } = useFetch("products/" + id);
  const [imageMain, setImageMain] = useState(0);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="container mx-auto p-6">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className=" text-3xl font-semibold my-4">{data?.product?.name}</h1>
        <Link
          to={"/dashboard/products/" + id}
          className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600"
        >
          {content["Edit Product"]}
        </Link>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Section - Images */}
        <div>
          <div className="w-full mb-4">
            <div className=" h-[250px] flex-center">
              <img
                src={storageUrl + data?.product?.files[imageMain]?.file}
                alt=""
                className="h-full w-full object-contain"
              />
            </div>
          </div>
          <div className="flex gap-2 overflow-x-auto">
            {data?.product?.files?.map((file, index) => (
              <div
                onClick={() => setImageMain(index)}
                key={index}
                className={`${
                  imageMain === index ? "border-2 border-Main box-border" : ""
                } w-[70px]  bg-[#E5E5E5] flex-center rounded-md overflow-hidden`}
              >
                <img
                  src={storageUrl + file.file}
                  alt=""
                  className="object-contain "
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Section - Details */}
        <div>
          <p className="text-gray-700">{data?.product?.description}</p>
          <div className="mt-2">
            <div className="flex items-center gap-4">
              <span className="text-2xl font-bold text-green-500">
                {data?.product?.price}
              </span>
              {data?.product?.discount > 0 && (
                <span className="text-sm text-red-500 line-through">
                  {data?.product?.discount}
                </span>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {content["Delivery in"]} : {data?.product?.delivery_time}{" "}
              {content["Days"]}
            </div>
          </div>

          {/* Sizes */}
          <div className="mt-2">
            <h3 className="font-semibold mb-2">{content["Sizes"]}</h3>
            <div className="flex gap-2">
              {data?.product?.size?.map((size, index) => (
                <span
                  key={index}
                  className="px-3 py-1 border rounded-md bg-gray-100 text-sm shadow-sm"
                >
                  {size.name}
                </span>
              ))}
            </div>
          </div>

          {/* Colors */}
          <div className="mt-2">
            <h3 className="font-semibold mb-2">{content["Colors"]}</h3>
            <div className="flex gap-2">
              {data?.product?.color?.map((color, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: color.name,
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    border: "2px solid #ddd",
                  }}
                ></div>
              ))}
            </div>
          </div>

          {/* Status and Availability */}
          <div className="mt-2">
            <div>
              <span className="font-semibold">{content["Status"]}: </span>
              <span className="text-gray-700">{data?.product?.status}</span>
            </div>
            <div>
              <span className="font-semibold">{content["Available"]}: </span>
              <span
                className={
                  data?.product?.is_available
                    ? "text-green-500"
                    : "text-red-500"
                }
              >
                {data?.product?.is_available ? content["Yes"] : content["No"]}
              </span>
            </div>
            <div>
              <span className="font-semibold">{content["Section"]}: </span>
              {data?.product?.section?.name}
            </div>
            <div>
              <span className="font-semibold">{content["Category"]}: </span>
              {data?.product?.category?.name}
            </div>
            <div>
              <span className="font-semibold">{content["Quantity"]}: </span>
              {data?.product?.quantity}
            </div>
            <div>
              <span className="font-semibold">{content["Views"]}: </span>
              {data?.product?.views}
            </div>
            <div>
              <span className="font-semibold">{content["Sales"]}: </span>
              {data?.product?.sales}
            </div>
            <div>
              <span className="font-semibold">{content["Loved"]}: </span>
              {data?.product?.loved}
            </div>
          </div>
        </div>
      </div>

      {/* Reviews Section */}
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">{content["Reviews"]}</h2>
        {data?.product?.reviews?.length ? (
          <Row>
            {data?.product?.reviews?.map((review, index) => (
              <Col
                md={20}
                key={index}
                className="border p-4 rounded-md shadow-sm bg-gray-50 text-center"
              >
                <div className="flex items-center justify-between">
                  <span className="text-yellow-500">{review.rating} ★</span>
                </div>
                <p className="text-gray-600 mt-2">{review.review}</p>
              </Col>
            ))}
          </Row>
        ) : (
          <p className="text-gray-500">{content["No reviews yet."]}</p>
        )}
      </div>
    </div>
  );
};

export default SellerProduct;
