import React from "react";
import { MdOutlineShoppingCart, FaStoreAlt } from "../../assets/icons"; // تأكد من أنك تستخدم الأيقونة الصحيحة
import { useClose } from "../../contexts/useClose";
import { Link } from "react-router-dom";
import { storageUrl } from "../../config";
import { content } from "../../contexts/useLang";

const Cart = ({ data }) => {
  const { isOpen, mouseRef, reverseFunction } = useClose();
  return (
    <div ref={mouseRef} className="relative inline-block">
      <button onClick={reverseFunction} className=" ">
        <MdOutlineShoppingCart size={26} />
        {data?.itemCountCart !== 0 && data?.itemCountCart && (
          <div className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-4 h-4 text-xs flex items-center justify-center">
            {data?.itemCountCart}
          </div>
        )}
      </button>

      <div
        className={`absolute right-0 mt-2 w-64 bg-white border border-gray-300 rounded-lg shadow-lg transition-all duration-300 ease-in-out z-10 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="w-64 bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden">
          {data?.stores?.length ? (
            data?.stores?.map((store) => (
              <Link
                to={"/cart/" + store.username}
                key={store.id}
                className="flex items-center  px-4 py-3 border-b border-gray-200 hover:bg-gray-50 cursor-pointer rounded-none transition-all duration-300 ease-in-out"
              >
                <div className="w-10 h-10 overflow-hidden rounded-full mr-3 shadow-md transition-all duration-300 ease-in-out">
                  <img
                    src={storageUrl + store.image}
                    alt={store.name}
                    className=" w-full h-full  object-contain"
                  />
                </div>
                {/* اسم المتجر وعدد العناصر */}
                <div className="flex-1">
                  <div className="font-semibold text-sm text-gray-800">
                    {store.name}
                  </div>
                  <div className="text-xs text-gray-500">
                    {content["Items"]} : {store.itemCount}
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center p-6 text-center">
              <FaStoreAlt className="text-4xl text-gray-400 mb-3" />
              <h2 className="text-sm font-semibold text-gray-600">
                {content["No stores available"]}
              </h2>
              <p className="text-xs text-gray-500">
                {content["You currently have no stores to display."]}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
