import React from "react";
import { IoMdClose } from "react-icons/io";
import { useFilter } from "../../contexts/useFilter";
import { Col } from "../GridSystem";
import { content } from "../../contexts/useLang";

const SuccessPayment = () => {
  const { handleParamsRemove, searchParams } = useFilter();
  return (
    <div>
      {searchParams.get("success_payment") ? (
        <>
          <div
            onClick={() => handleParamsRemove("success_payment")}
            className="w-full h-full bg-black/20 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40"
          />
          <Col
            md={4}
            sm={11}
            className="md:h-[40vh] mt-10 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[999]"
          >
            <div className="bg-Light rounded-xl p-3 border h-full">
              <div>
                <div onClick={() => handleParamsRemove("success_payment")}>
                  <IoMdClose />
                </div>
              </div>
              <Col md={11} className="mx-auto text-center h-full">
                <div className="font-semibold text-xl mb-10">
                  {content["Payment Successful!"]} 🎉
                </div>
                <div className="text-Third">
                  {
                    content[
                      "Thank you for your payment. Your transaction has been successfully processed via Stripe."
                    ]
                  }
                </div>
                <div className="flex-center gap-3 mt-16"></div>
              </Col>
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
export default SuccessPayment;
