import { content } from "./useLang";

const zoomImage = (event) => event.target.requestFullscreen();

const getArrayLength = (arr) => arr?.length;

const findItem = (arr, key, target) =>
  arr?.filter((e) => areEqual(e[key], target));

const removeItem = (arr, target) => {
  return arr.filter((item) => item !== target);
};

const areEqual = (value1, value2) => `${value1}` === `${value2}`;

const addEdit = (isAdd, name) => {
  return isAdd
    ? content.add + " " + content[name]
    : content.editThe + content[name];
};

const checkIsSearchSelect = (name) => {
  return name !== "status" ? true : false;
};
const formatCheckDel = (data) => {
  return data?.deleted_at ? data?.name + " ( محذوف ) " : data?.name;
};

const pullNumber = (text) => {
  if (text) {
    const match = text.match(/\d[\d,]*/);
    if (match) {
      return parseInt(match[0].replace(/,/g, ""));
    }
  }
};

const formatKey = (key) =>
  key.replace("_id", "").replaceAll("[]", "").replaceAll("_", " ");

const formatTypeUser = (type) => {
  return content[type];
};

const formatTypeAttendee = (type) => {
  return content[type];
};

const formatNumber = (number) => {
  if (number >= 0) {
    return parseInt(+number).toLocaleString();
  }
};

const formatStatus = (status) => {
  return status === "waiting" ? content.waiting : content.completed;
};

const formatRole = (role) => {
  switch (role) {
    case "all":
      return content.allRole;
    case "read":
      return content.read;
    case "add":
      return content.justAdd;
    case "add_and_edit":
      return content.add_and_edit;
    case "customize":
      return content.customize;
    default:
      return "";
  }
};

const formatType = (type) => {
  switch (type) {
    case "worksheet":
      return content.worksheet;
    case "quiz":
      return content.quiz;
    case "behavior":
      return content.behavior;
    case "final":
      return content.final;
    default:
      return "";
  }
};

const formatTripType = (type) => {
  switch (type) {
    case "going_to":
      return content.going_to;
    case "going_Back":
      return content.going_Back;
    case "all":
      return content.toBack;
    default:
      return "";
  }
};
const formatYears = (year) => {
  if (year) {
    return year <= 10 && year > 2 ? year + content.years : year + content.year;
  }
};

const formatGender = (gender) => {
  return gender === "male" ? content.male : content.female;
};

const formatLevel = (level) => {
  switch (level) {
    case "one":
      return "الأول";
    case "two":
      return "الثاني";
    case "three":
      return "الثالث";
    case "four":
      return "الرابع";
    default:
      return level;
  }
};
const formatSubText = (text, num) => {
  return text?.substring(num)
    ? text?.substring(0, num) + " ..."
    : text?.substring(0, num);
};
const formatArray = (arr, name, name2) => {
  if (arr?.length) {
    return arr?.map(
      (e, i) =>
        (name2 ? e?.[name][name2] : e?.[name]) +
        (arr.length - 1 === i ? "" : " , ")
    );
  }
};
function uniqueArray(arr) {
  let uniqueArray = [...new Set(arr)];
  return uniqueArray;
}
const formatTime = (time) => {
  if (time) {
    function getTimeIn12HourFormat(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let period = hours >= 12 ? content.pm : content.am;
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      return hours + ":" + minutes + " " + period;
    }
    const timeComponents = time.split(":");
    const date = new Date();
    date.setHours(timeComponents[0]);
    date.setMinutes(timeComponents[1]);
    date.setSeconds(timeComponents[2]);
    const formattedTime = getTimeIn12HourFormat(date);
    return formattedTime;
  }
};

function formatDocuments(file) {
  const slashIndex = file.indexOf("/");
  const atIndex = file.indexOf("@");

  if (slashIndex !== -1 && atIndex !== -1) {
    return file.slice(slashIndex + 1, atIndex);
  } else {
    return file;
  }
}

export {
  formatSubText,
  zoomImage,
  getArrayLength,
  findItem,
  areEqual,
  checkIsSearchSelect,
  pullNumber,
  formatNumber,
  formatKey,
  formatYears,
  formatArray,
  formatTime,
  formatLevel,
  formatGender,
  formatStatus,
  formatRole,
  formatType,
  formatTripType,
  formatTypeAttendee,
  formatTypeUser,
  formatDocuments,
  addEdit,
  removeItem,
  formatCheckDel,
  uniqueArray,
};
