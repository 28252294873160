import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaShoppingCart } from "../../assets/icons";
import {
  ButMain,
  CardCart,
  Col,
  Container,
  MobileCardCart,
  PageLoading,
  Row,
  TitlePage,
} from "../../components";
import { useFetch } from "../../contexts/useFetch";
import { content } from "../../contexts/useLang";
const Cart = () => {
  const { username } = useParams();
  const { data, loading, deleteItem } = useFetch("cart?seller=" + username);
  const [noteOrder, setNoteOrder] = useState(
    sessionStorage.getItem("note_order") || ""
  );
  useEffect(() => {
    sessionStorage.setItem("note_order", noteOrder);
  }, [noteOrder]);

  return (
    <div>
      {loading ? (
        <PageLoading />
      ) : (
        <Container>
          <TitlePage
            title={[
              content["Home"],
              content["Sellers"],
              data?.seller?.name,
              content["Cart"],
            ]}
          />
          <Row className="text-center mt-5 text-lg font-semibold max-md:hidden">
            <Col md={20}>{content["Product"]}</Col>
            <Col md={20}>{content["Details"]}</Col>
            <Col md={20}>{content["Quantity"]}</Col>
            <Col md={20}>{content["Price"]}</Col>
            <Col md={20}>{content["Event"]} </Col>
          </Row>
          {data?.cart?.length ? (
            data?.cart?.map((e, i) => (
              <div key={i}>
                <Row className="text-center items-center  max-md:hidden">
                  <CardCart data={e} deleteItem={deleteItem} />
                </Row>
                <div className="boeder md:hidden">
                  <MobileCardCart data={e} deleteItem={deleteItem} />
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center h-64 shadow-md">
              <FaShoppingCart className="text-6xl text-gray-400 mb-4" />
              <h2 className="text-xl font-semibold text-gray-600">
                {content["Your cart is empty!"]}
              </h2>
              <p className="text-gray-500 mt-2 text-sm">
                {content["Add items to your cart to start shopping."]}
              </p>
              <ButMain
                name={content["Continue Shopping"]}
                link={"/sellers/" + username}
                className="mt-6 px-6 py-2 bg-Main "
              />
            </div>
          )}
          <hr />
          <div className="flex justify-between items-center">
            <Link
              to={"/sellers/" + username}
              className="text-Main text-lg block my-3"
            >
              + {content["Add More Items"]}
            </Link>
            <div>
              <ButMain
                onClick={() => deleteItem("", "cart-clear?seller=" + username)}
                name={content["Clear All"]}
                className="text-center text-sm py-1 mx-auto my-3 "
              />
            </div>
          </div>
          <div className="text-center text-Main text-lg font-bold">
            <span className="text-black">{content["Subtotal"]}: </span>
            {data?.total_price}
          </div>
          <div className="mt-10 text-lg">
            {content["Notes"]}{" "}
            <span className="text-sm">({content["Optional"]})</span>
          </div>
          <div>
            <textarea
              onChange={(e) => setNoteOrder(e.target.value)}
              value={noteOrder}
              id=""
              className="outline-none bg-Third/20 rounded-xl w-full h-[150px] my-2 p-3 "
              placeholder="write your note here .."
            ></textarea>
          </div>
          <ButMain
            link={
              "/checkout/" +
              username +
              "?total_price=" +
              data?.total_price +
              "&delivery_time=" +
              data?.delivery_time
            }
            name={content["CheckOut"]}
            className="text-center text-xl py-3 mx-auto my-3 w-[250px]"
          />
        </Container>
      )}
    </div>
  );
};

export default Cart;
