import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContextState } from "../../contexts/ContextState";

const SuccessMessage = () => {
  const { messages, setMessages } = useContextState();
  useEffect(() => {
    if (messages && messages.length > 0) {
      const toastId = toast.loading(<p className="text-end">Loading...</p>, {
        position: "top-center",
        className: "!text-end",
        closeOnClick: true,
        draggable: true,
      });

      // تحديث الرسالة بعد ثانية واحدة
      setTimeout(() => {
        toast.update(toastId, {
          render: messages[0], // عرض أول رسالة من المصفوفة
          type: "success",
          isLoading: false, // إخفاء حالة التحميل
          autoClose: 1500,
          onClose: () => setMessages(""), // إعادة تعيين الرسالة بعد الإغلاق
        });
      }, 1000);
    }
  }, [messages, setMessages]);

  return <ToastContainer />;
};

export default SuccessMessage;
