import { useEffect, useState } from "react";
import { formatKey } from "./functions";

const useValidate = (dataPage, validate) => {
  const [error, setError] = useState([]);
  useEffect(() => {
    setError([]);
    const validateField = (field, value) => {
      const rules = validate[field];
      const fieldErrors = [];
      if (rules && Array.isArray(rules)) {
        rules.forEach((rule) => {
          const [ruleName, ...params] = rule.split(":");
          let errorMessage = "";
          switch (ruleName) {
            case "required":
              if (!value || value === "") {
                errorMessage = `${formatKey(field)} field is required`;
                // errorMessage = `حقل ${content[formatKey(field)]} مطلوب`;
              }
              break;
            case "requiredArray":
              if (value.length !== 5 || !value) {
                errorMessage = `${formatKey(field)} 5 field is required`;
                // errorMessage = `حقل ${content[formatKey(field)]} مطلوب`;
              }
              break;
            case "notExceed":
              const exceeding = parseInt(params[0]);
              if (value > exceeding || value < 0) {
                errorMessage = ``;
                // errorMessage = `يجب ${
                //   content[formatKey(field)]
                // } ان لا يتجاوز قيمة ${formatNumber(exceeding)}`;
              }
              break;
            case "email":
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(value)) {
                errorMessage = `${formatKey(field)} field is not a valid email`;
                // errorMessage = ` يجب  ان يكون حقل  ${
                //   content[formatKey(field)]
                // } على شكل example@gmail.com`;
              }
              break;
            case "min":
              const minLength = parseInt(params[0]);
              if (value.length < minLength && value) {
                errorMessage = `${formatKey(
                  field
                )} field must be at least ${minLength} characters long`;
                // errorMessage = `يجب ان يكون حقل ${
                //   content[formatKey(field)]
                // } على الأقل ${minLength} أحرف`;
              }
              break;
            case "max":
              const maxLength = parseInt(params[0]);
              if (value.length > maxLength && value) {
                errorMessage = `${formatKey(
                  field
                )} field must not exceed ${maxLength} characters`;
                // errorMessage = `يجب ان يكون حقل ${
                //   content[formatKey(field)]
                // } على الأكثر ${maxLength} أحرف`;
              }
              break;
            case "length":
              const equalLength = parseInt(params[0]);
              if (value.length !== equalLength && value) {
                errorMessage = `${formatKey(
                  field
                )} field must not exceed ${equalLength} characters`;
                // errorMessage = `يجب ان يكون حقل ${
                //   content[formatKey(field)]
                // } يساوي ${equalLength} ${params[1] || "ارقام"}`;
              }
              break;
            case "equal":
              const otherField = params[0];
              const otherValue = dataPage[otherField];
              if (value !== otherValue && value) {
                errorMessage = `${formatKey(
                  field
                )} field must be equal to ${formatKey(otherField)}`;
                // errorMessage = `يجب ان يكون حقل ${
                //   content[formatKey(field)]
                // } يساوي ${content[formatKey(otherField)]} أحرف`;
              }
              break;
            default:
              break;
          }

          if (errorMessage !== "") {
            fieldErrors.push(errorMessage);
          }
        });
      }

      return fieldErrors;
    };
    for (const [key, value] of Object.entries(dataPage)) {
      const fieldErrors = validateField(key, value);
      if (fieldErrors.length > 0) {
        setError((prevErrors) => [...prevErrors, ...fieldErrors]);
      }
    }
  }, [dataPage]);

  return { error };
};

export default useValidate;
