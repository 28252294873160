import { Col } from "../GridSystem";
import CheckBox from "../Inputs/CheckBox";
import HTMLEditor from "../Inputs/HTMLEditor";
import Input from "../Inputs/Input";
import MultiSelect from "../Inputs/MultiSelect";
import MultiSelectApi from "../Inputs/MultiSelectApi";
import PasswordInput from "../Inputs/PasswordInput";
import RadioBtn from "../Inputs/RadioBtn";
import Select from "../Inputs/Select";
import SelectApi from "../Inputs/SelectApi";
import TextArea from "../Inputs/TextArea";
import UploadFile from "../Inputs/UploadFile";
import UploadFiles from "../Inputs/UploadFiles";

const InputsPage = ({ data, value }) => {
  if (data.show !== false ) {
    const inputType = data.inputType;
    if (inputType === "input") return <Input data={data} value={value} />;
    else if (inputType === "select") return <Select data={data} value={value} />;
    else if (inputType === "selectApi") return <SelectApi data={data} value={value} />;
    else if (inputType === "multiSelect") return <MultiSelect data={data} value={value} />;
    else if (inputType === "multiSelectApi") return <MultiSelectApi data={data} value={value} />;
    else if (inputType === "checkBox") return <CheckBox data={data} value={value} />;
    else if (inputType === "uploadFile") return <UploadFile data={data} value={value} />;
    else if (inputType === "uploadFiles") return <UploadFiles data={data} value={value} />;
    else if (inputType === "textarea") return <TextArea data={data} value={value} />;
    else if (inputType === "radio") return <RadioBtn data={data} value={value} />;
    else if (inputType === "password") return <PasswordInput data={data} value={value} />;
    else if (inputType === "html") return <HTMLEditor data={data} value={value} />;
  } else return  <Col lg={data.lg || data.md} md={data.xs || data.md || 4} xs={data.xs || 6} />;
};

export default InputsPage;
