import { Link } from "react-router-dom";

const P404 = () => {
  return (
    <div>
      <h1
        style={{ direction: "ltr" }}
        className="grid place-content-center h-[80vh] text-Brown font-semibold text-lg"
      >
        404 | Not Found
        <Link to="/" className="hover:text-Main">
          Go to Home
        </Link>
      </h1>
    </div>
  );
};

export default P404;
