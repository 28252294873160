import React, { useEffect } from "react";
import { IoMdClose } from "../../assets/icons";
import { useContextState } from "../../contexts/ContextState";
import { usePost } from "../../contexts/usePost";
import ButMain from "../Buttons/ButMain";
import CardStar from "../Card/CardStar";
import { Col } from "../GridSystem";
import { content } from "../../contexts/useLang";
const PopUpRate = ({ id, type }) => {
  const { openPopUpRate, setOpenPopUpRate } = useContextState();
  const {
    formData,
    setFormData,
    handleSubmit,
    handleChangeInput,
    successPost,
    loadingPost,
  } = usePost(
    {
      type: type,
      id: id,
      rating: 0,
      review: "",
    },
    {}
  );
  useEffect(() => {
    if (successPost) {
      setFormData({
        type: type,
        id: id,
        rating: 0,
        review: "",
      });
      setOpenPopUpRate(false);
    }
  }, [successPost]);

  return (
    <div>
      {openPopUpRate ? (
        <>
          <div
            onClick={() => setOpenPopUpRate(false)}
            className="w-full h-full bg-black/20 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40"
          />
          <Col
            md={4}
            sm={11}
            className="md:h-[87vh] mt-10 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[999]"
          >
            <div className="bg-Light rounded-xl p-3 border h-full">
              <div>
                <div onClick={() => setOpenPopUpRate(false)}>
                  <IoMdClose />
                </div>
              </div>
              <Col md={11} className="mx-auto">
                <div className="text-center text-lg font-semibold mb-5">
                  {content["Rate the product"]}
                </div>
                <div className="mt-8">
                  <CardStar rating={formData} setRating={setFormData} />
                  <div className="mt-14 text-lg">
                    {content["Your Review"]}{" "}
                    <span className="text-sm">({content["Optional"]})</span>
                  </div>
                  <textarea
                    onChange={handleChangeInput}
                    name={content["review"]}
                    id=""
                    className="outline-none bg-Third/20 rounded-xl w-full h-[200px] p-3 "
                    placeholder="type here ..."
                  ></textarea>
                  <ButMain
                    loadingPost={loadingPost}
                    name={content["Supmit"]}
                    onClick={() => handleSubmit("reviews", "noRoute")}
                    className="text-center py-3 text-xl mt-4"
                  />
                </div>
              </Col>
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PopUpRate;
