import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButMain, CardAuth, Col, InputsPage, Row } from "../../components";
import { usePost } from "../../contexts/usePost";
import { useContextState } from "../../contexts/ContextState";
import { getArrayLength } from "../../contexts/functions";
import { content } from "../../contexts/useLang";

const CreateSeller = () => {
  const { setError } = useContextState();
  const {
    handleChangeInput,
    formData,
    setFormData,
    error,
    handleChangeSelect,
  } = usePost(
    {
      store_name: "",
      country: "",
      city: "",
      post_code: "",
      street_name: "",
      house_number: "",
    },
    {
      store_name: ["required"],
      country: ["required"],
      city: ["required"],
      post_code: ["required"],
      street_name: ["required"],
      house_number: ["required"],
    }
  );
  useEffect(() => {
    setFormData({
      store_name: sessionStorage.getItem("store_name"),
      country: sessionStorage.getItem("country"),
      city: sessionStorage.getItem("city"),
      post_code: sessionStorage.getItem("post_code"),
      street_name: sessionStorage.getItem("street_name"),
      house_number: sessionStorage.getItem("house_number"),
    });
  }, []);
  const navigate = useNavigate();
  return (
    <CardAuth title={content["Create Seller Account"]}>
      <Row justify="center" className="space-y-3">
        <Col xs={11} className="text-Main">
          {content["First Step: Provide your basic company info"]}
        </Col>
        <Col xs={11} className="text-Main flex gap-1">
          <div className="w-1/2 h-[5px] bg-Main rounded-full "></div>
          <div className="w-1/2 h-[5px] bg-Third/30 rounded-full"></div>
        </Col>
        {[
          {
            xs: 11,
            title: content["Enter your full Store name"],
            onChange: handleChangeInput,
            inputType: "input",
            name: "store_name",
            type: "text",
          },
          {
            xs: 11,
            title: content["Country"],
            onChange: handleChangeSelect,
            inputType: "selectApi",
            name: "country",
            type: "text",
            api: "coins",
          },
          {
            xs: 11,
            title: content["City"],
            onChange: handleChangeInput,
            inputType: "input",
            name: "city",
            type: "text",
          },
          {
            xs: 11,
            title: content["Post code"],
            onChange: handleChangeInput,
            inputType: "input",
            name: "post_code",
            type: "text",
          },
          {
            xs: 11,
            title: content["Street name"],
            onChange: handleChangeInput,
            inputType: "input",
            name: "street_name",
            type: "text",
          },
          {
            xs: 11,
            title: content["House number"],
            onChange: handleChangeInput,
            inputType: "input",
            name: "house_number",
            type: "text",
          },
        ].map((e, i) => (
          <InputsPage key={i} data={e} value={formData?.[e.name]} />
        ))}

        <Col xs={11} className=" ">
          <ButMain
            onClick={() => {
              if (getArrayLength(error)) {
                return setError([...error]);
              }
              for (const [key, value] of Object.entries(formData || {})) {
                sessionStorage.setItem(key, value);
              }
              navigate("/seller/create-p2");
            }}
            name={content["Next"]}
            className="text-center py-3 text-xl"
          />
        </Col>
      </Row>
    </CardAuth>
  );
};

export default CreateSeller;
