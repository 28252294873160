import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useContextState } from "../../contexts/ContextState";
import { useFetch } from "../../contexts/useFetch";
import { useFilter } from "../../contexts/useFilter";
import { content } from "../../contexts/useLang";
import CardFilters from "../Filters/CardFilters";
import PriceFilter from "../Filters/PriceFilter";

const SideBarFilter = () => {
  const { openSideBarFilter, setOpenSideBarFilter } = useContextState();
  const [searchSection, setsearchSection] = useState();
  const {
    handleParamsAdd,
    handleParamsDeleteAll,
    handleParamsRemove,
    searchParams,
  } = useFilter();
  const { data: dataSections } = useFetch(
    searchSection ? "sections?search=" + searchSection : "sections"
  );
  const { data: dataSizes } = useFetch("sizes");
  const { data: dataColors } = useFetch("colors");

  return (
    <>
      <div
        className={`${
          openSideBarFilter
            ? "md:w-[250px] !max-lg:translate-x-10 w-5/6 -!right-0 overflow-y-auto px-2"
            : " w-[0px] "
        }  !max-lg:translate-x-full  bg-white border-r   left-0 scroll h-[100%] max-lg:!mt-0  top-0 fixed max-lg:right-0  transition-all py-4 z-[99]`}
      >
        <div className={openSideBarFilter ? "" : "hidden"}>
          <div className="flex justify-between items-center py-2">
            <div className="font-semibold text-lg">{content["Filters"]}</div>
            <div className="text-Third" onClick={() => handleParamsDeleteAll()}>
              {content["Clear All"]}
            </div>
          </div>
          <div className="">
            <CardFilters title={content["Sort By"]}>
              <div className="">
                <div
                  onClick={() => handleParamsRemove("sort_by")}
                  className="py-1 hover:bg-Third/30 pl-10 rounded-md mx-2 "
                >
                  {content["All"]}
                </div>
                {[
                  { title: content["Best Selling"], name: "sales" }, // الأكثر مبيعًا
                  { title: content["Highest Price"], name: "price_desc" }, // الأغلى سعرًا
                  { title: content["Lowest Price"], name: "price_asc" }, // الأرخص سعرًا
                  { title: content["Most Viewed"], name: "views" }, // الأكثر مشاهدة
                  { title: content["Most Loved"], name: "loved" }, // الأكثر إعجابًا
                  { title: content["Biggest Discount"], name: "discount" }, // أعلى خصم
                  { title: content["Newest Arrivals"], name: "new" }, // الأحدث وصولاً
                ]?.map((e, i) => (
                  <div
                    key={i}
                    onClick={() => handleParamsAdd("sort_by", e.name)}
                    className={`${
                      e.name === searchParams.get("sort_by")
                        ? "bg-Third/30"
                        : "hover:bg-Third/30"
                    } py-1  pl-10 rounded-md mx-2 `}
                  >
                    {e.title}
                  </div>
                ))}
              </div>
            </CardFilters>
            <CardFilters title={content["Sections"]}>
              <div className="border border-Third/50 px-4 py-[4px] rounded-full flex items-center mx-2 my-3">
                <CiSearch size={20} className="text-Secondary/80" />
                <input
                  type="search"
                  placeholder={content["Search"]}
                  onChange={(e) => setsearchSection(e.target.value)}
                  className="bg-transparent px-2"
                />
              </div>
              <div className="h-[150px] overflow-y-scroll !scroll-mx-8">
                <div
                  onClick={() => handleParamsRemove("section")}
                  className="py-1 hover:bg-Third/30 pl-10 rounded-md mx-2 "
                >
                  {content["All"]}
                </div>
                {dataSections?.data?.map((e, i) => (
                  <div
                    key={i}
                    onClick={() => handleParamsAdd("section", e.id)}
                    className={`${
                      e.id === +searchParams.get("section")
                        ? "bg-Third/30"
                        : "hover:bg-Third/30"
                    } py-1  pl-10 rounded-md mx-2 `}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            </CardFilters>

            <CardFilters title={content["Price"]}>
              <PriceFilter />
            </CardFilters>
            <CardFilters title={content["Size"]}>
              <div className="flex items-center flex-wrap gap-2 my-1 py-2">
                {dataSizes?.map((e, i) => (
                  <div
                    onClick={() => handleParamsAdd("size", e.id)}
                    key={i}
                    className={`${
                      +e.id === +searchParams.get("size")
                        ? "bg-black text-Light"
                        : "hover:bg-black hover:text-Light"
                    } bg-Third/30 w-10 h-10 rounded-md flex-center`}
                  >
                    {e.name}
                  </div>
                ))}
              </div>
            </CardFilters>
            <CardFilters title={content["Color"]}>
              <div className="flex flex-wrap gap-2  py-2">
                {dataColors?.map((e, i) => (
                  <div
                    onClick={() => handleParamsAdd("color", e.id)}
                    key={i}
                    style={{ borderColor: e.name }}
                    className={`transition-all rounded-md w-[75px] h-7 overflow-hidden ${
                      +e.id === +searchParams.get("color")
                        ? "border"
                        : "hover:border"
                    }`}
                  >
                    <div
                      style={{ backgroundColor: e.name }}
                      className={`${
                        +e.id === +searchParams.get("color")
                          ? "scale-90"
                          : "hover:scale-90"
                      } transition-all w-full h-full  rounded-md`}
                    />
                  </div>
                ))}
              </div>
            </CardFilters>
          </div>
        </div>
      </div>
      <div
        onClick={() => setOpenSideBarFilter(!openSideBarFilter)}
        style={{ transition: "0.4s" }}
        className={`${
          openSideBarFilter ? "" : "hidden"
        }  w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-30`}
      />
    </>
  );
};

export default SideBarFilter;
