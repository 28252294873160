import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useFilter } from "../../contexts/useFilter";

const Pagination = ({ data }) => {
  const [pageCount, setPageCount] = useState(0);
  const { handlePageClick, searchParams } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = searchParams.get("page")
        ? searchParams.get("page") - 1
        : 0;
    }
  }, [searchParams.get("page")]);
  useEffect(() => {
    setPageCount(Math.ceil(data?.total / data?.per_page));
  }, [data]);
  return (
    <>
      {!isNaN(pageCount) && pageCount !== 0 && (
        <div className="flex justify-center mx-5 my-10">
          <ReactPaginate
            className="pagination flex"
            previousLabel={
              <div className="text-Main px-2 border-[1px] hover:bg-Main hover:text-white">
                «
              </div>
            }
            nextLabel={
              <div className="text-Main px-2 border-[1px] hover:bg-Main hover:text-white">
                »
              </div>
            }
            breakLabel="..."
            ref={a}
            pageCount={pageCount || 0}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            pageClassName="page-item"
            pageLinkClassName="page-link"
          />
        </div>
      )}
    </>
  );
};

export default Pagination;
