import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import NavbarMobile from "../Navbar/NavbarMobile";

const Dashboard = () => {
  return (
    <>
      <div className="max-md:hidden">
        <Navbar />
      </div>
      <div className="md:hidden">
        <NavbarMobile />
      </div>
      <div className="relative flex ">
        <Sidebar />
        <div className="flex-1 b bg-slate-200 overflow-x-hidden min-h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
