import { default as ReactSelect } from "react-select";
import { findItem } from "../../contexts/functions";
import { useFetch } from "../../contexts/useFetch";
import { useTimeout } from "../../contexts/useTimeout";
import { Col } from "../GridSystem";

const SelectApi = ({ data, value }) => {
  const { handelChangeTimeoutSelect, search } = useTimeout();
  const { data: dataApi, loading } = useFetch(
    data?.api
      ? data?.api +
          (search ? (data?.isParams ? "&search=" : "?search=") + search : "")
      : ""
  );
  return (
    <Col md={data.xs || data.md || 4} xs={data.xs || 6}>
      <div className="text-sm text-Third">{data?.title}</div>
      <ReactSelect
        value={
          (value &&
            findItem(
              dataApi?.length ? dataApi : dataApi?.data,
              "id",
              value
            )?.map((q) => {
              return {
                value: q.id,
                label: q?.name ? q?.name : q?.country,
              };
            })[0]) ||
          null
        }
        isLoading={loading}
        isDisabled={data?.disabled}
        onInputChange={handelChangeTimeoutSelect}
        loadingMessage={() => "loading..."}
        classNamePrefix="react-select"
        onChange={data.onChange}
        className="mt-2 !outline-none"
        name={data.name}
        isOptionDisabled={data?.hideOptions}
        options={(dataApi?.length ? dataApi : dataApi?.data)?.map((e) => {
          return {
            value: e.id,
            label: e?.name ? e?.name : e?.country,
          };
        })}
      />
    </Col>
  );
};

export default SelectApi;
