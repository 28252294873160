import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { baseUrl, token } from "../config";
import { useContextState } from "./ContextState";
const contextData = createContext({});

const ContextDataProvider = ({ children }) => {
  const [profile, setProfile] = useState();
  const [countryCode, setCountryCode] = useState();
  const { refreshData } = useContextState();
  useEffect(() => {
    if (token) {
      axios
        .get(`${baseUrl}profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => setProfile(response?.data.data))
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.clear();
            window.location.reload(false);
          }
        });
    }
  }, [refreshData]);
  const [info, setInfo] = useState();
  useEffect(() => {
    if (token) {
      axios
        .get(`${baseUrl}info`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => setInfo(response?.data.data))
        .catch();
    }
    //
    fetch("https://ipapi.co/json/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCountryCode(data);
      })
      .catch((err) => {
        console.error("خطأ أثناء استدعاء API:", err.message);
        // setPhoneNumber("+971504681550");
      });
  }, []);

  return (
    <contextData.Provider value={{ profile, info, countryCode }}>
      {children}
    </contextData.Provider>
  );
};

export default ContextDataProvider;

export const useContextData = () => {
  return useContext(contextData);
};
