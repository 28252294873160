import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import NavbarMobile from "./Navbar/NavbarMobile";

const Layout = ({ children }) => {
  return (
    <div className="relative overflow-hidden">
      <div className="max-md:hidden">
        <Navbar />
      </div>
      <div className="md:hidden">
        <NavbarMobile />
      </div>
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
